import React from 'react';
import { i18n } from "../../../i18n.js";

import C02P01 from './C02P01';
import C02P02 from './C02P02';
import C02P03 from './C02P03';
import C02P04 from './C02P04';
import C02P05 from './C02P05';
import C02P06 from './C02P06';
import C02P07 from './C02P07';
import C02P08 from './C02P08';
import C02P09 from './C02P09';
import C02P10 from './C02P10';
import C02P11 from './C02P11';
import C02P12 from './C02P12';
import C02P13 from './C02P13';
import C02P14 from './C02P14';
import C02P15 from './C02P15';
import C02P16 from './C02P16';
import C02P17 from './C02P17';
import C02P18 from './C02P18';
import C02P19 from './C02P19';

const chapterName = "Jewish Museum in Trondheim";

const c02_routes = [
	{
		path: '/chapter/2/page/1',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 1',
		name: 'Source 1',
		element: <C02P01 chapterName={chapterName} name="Source 1" language="en" year="1935" />
	},
	{
		path: '/chapter/2/page/2',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 2',
		name: 'Source 2',
		element: <C02P02 chapterName={chapterName} name="Source 2" language="en" year="1934" />
	},
	{
		path: '/chapter/2/page/3',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 3',
		name: 'Source 3',
		element: <C02P03 chapterName={chapterName} name="Source 3" language="en" year="1941" />
	},
	{
		path: '/chapter/2/page/4',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 4',
		name: 'Source 4',
		element: <C02P04 chapterName={chapterName} name="Source 4" language="en" year="1928" />
	},
	{
		path: '/chapter/2/page/5',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 5',
		name: 'Source 5',
		element: <C02P05 chapterName={chapterName} name="Source 5" language="en" year="1941" />
	},
	{
		path: '/chapter/2/page/6',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 6',
		name: 'Source 6',
		element: <C02P06 chapterName={chapterName} name="Source 6" language="en" year="1941" />
	},
	{
		path: '/chapter/2/page/7',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 7',
		name: 'Source 7',
		element: <C02P07 chapterName={chapterName} name="Source 7" language="en" year="1941" />
	},
	{
		path: '/chapter/2/page/8',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 8',
		name: 'Source 8',
		element: <C02P08 chapterName={chapterName} name="Source 8" language="en" year="1941" />
	},
	{
		path: '/chapter/2/page/9',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 9',
		name: 'Source 9',
		element: <C02P09 chapterName={chapterName} name="Source 9" language="en" year="1939" />
	},
	{
		path: '/chapter/2/page/10',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 10',
		name: 'Source 10',
		element: <C02P10 chapterName={chapterName} name="Source 10" language="en" year="1939" />
	},
	{
		path: '/chapter/2/page/11',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 11',
		name: 'Source 11',
		element: <C02P11 chapterName={chapterName} name="Source 11" language="en" year="1938" />
	},
	{
		path: '/chapter/2/page/12',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 12',
		name: 'Source 12',
		element: <C02P12 chapterName={chapterName} name="Source 12" language="en" year="1939" />
	},
	{
		path: '/chapter/2/page/13',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 13',
		name: 'Source 13',
		element: <C02P13 chapterName={chapterName} name="Source 13" language="en" year="1939" />
	},
	{
		path: '/chapter/2/page/14',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 14',
		name: 'Source 14',
		element: <C02P14 chapterName={chapterName} name="Source 14" language="en" year="1939" />
	},
	{
		path: '/chapter/2/page/15',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 15',
		name: 'Source 15',
		element: <C02P15 chapterName={chapterName} name="Source 15" language="en" year="1939" />
	},
	{
		path: '/chapter/2/page/16',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 16',
		name: 'Source 16',
		element: <C02P16 chapterName={chapterName} name="Source 16" language="en" year="1941" />
	},
	{
		path: '/chapter/2/page/17',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 17',
		name: 'Source 17',
		element: <C02P17 chapterName={chapterName} name="Source 17" language="en" year="" />
	},
	{
		path: '/chapter/2/page/18',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 18',
		name: 'Source 18',
		element: <C02P18 chapterName={chapterName} name="Source 18" language="en" year="1947" />
	},
	{
		path: '/chapter/2/page/19',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 19',
		name: 'Source 19',
		element: <C02P19 chapterName={chapterName} name="Source 19" language="en" year="1940" />
	},
	{
		path: '/chapter/2/',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 1',
		name: 'Source 1',
		ignoreInPagelist: true,
		element: <C02P01 chapterName={chapterName} name="Source 1" language="en" year="1935" />
	},
];

export default c02_routes;
import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../../i18n';
import { Trans } from "react-i18next";
import { Page, TransparentPage, SheetOfPaper } from "../../../components/Page/Page";
import Chapter from "../../../components/Chapter/Chapter";
import SourceHeader from "../../../components/SourceHeader/SourceHeader";

import routes from './c03_routes.js';
import ENTranscriptPDF from './Transcripts/en/en_c3_p21.pdf';
import NOTranscriptPDF from './Transcripts/no/no_c3_p21.pdf';
import DocumentIMG from './Media/c3_p21.jpg';

const C03P21 = (props) => {
	const { t } = useTranslation();
	const translation_namespace = 'c03_p21';

	return (
		<Chapter pages={routes} chapterName={props.chapterName} currentPageYear={props.year}>
			<Page className="C03P21">
				<TransparentPage>
					<p><img src={ DocumentIMG } alt={ t('Image of the original document transcribed next') } /></p>
					<ul className="download-links">
						<li><a href={ i18n.language === 'nb-NO' ? NOTranscriptPDF : ENTranscriptPDF } target="_blank"><Trans>Download</Trans> pdf <Trans>file</Trans></a></li>
						<li><a href={DocumentIMG} target="_blank"><Trans>Download</Trans> <Trans>image file</Trans></a></li>
					</ul>
				</TransparentPage>
				<SheetOfPaper>
					<SourceHeader name={ props.name } chapterName={ props.chapterName } />
					<p>
						<strong><Trans ns={ translation_namespace }>Concert review, Adresseavisen April 3rd, 1940. The Regional State Archives in Trondheim.</Trans></strong>
					</p>
					<h2><Trans>Transcript</Trans>:</h2>
					<div className="transcript">
						<p>
							<Trans ns={ translation_namespace }>The Symphony Orchestra</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Conductor: Haakon Hoem.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Contributors: Nidaros Mandssangforening</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Soloists: Egil Nordsjø and J. Grabowski</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Last night, the Symphony Orchestra performed Beethoven’s symphony no. 1. It has probably not been played here since Olav Kielland performed it with Musikerforeningen, except by the Kristiansunds Orchestra which can barely be remembered to have played it under the conductor Edv. Bræin.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Much of last night’s performance was good. On the whole, the tempos were swift, but perhaps the first movement in particular seemed at times to be slightly rushed. Some of the transitions and connective parts would probably sound better in a somewhat smoother tempo. Dynamically, the performance was a success, even though we might wish the span from pianissimo to fortissimo had been much greater. The andante was beautifully delivered even though the voices in counter movement at times were too loud. The minuet was lively and sparkling, and the cheerful Finale was delivered pithily and confidently. The thematic scales should have been more distinctive, particularly towards the end. This may seem as a lot of objections, but they are minor compared to all the positive aspects of the performance: Vividness, natural presentation, temper and humour, and everything was well composed – the sound of the orchestra was excellent.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Fridthjov Andersen’s Utferdsdråpa for male voices, soloists and orchestra was the second main piece. It was written to a poem by Per Sivle, and the poem has given the composer several impulses. The music also shifts between beautiful impressions, dramatic outbursts and smooth parts in a narrative folk’s music style. One is almost tempted to believe that several later Norwegian composers have borrowed ideas from this piece of work. The choral has a splendid sound, the instrumentation of the orchestra is rich, here we may have an important predecessor of later musical work on St. Olav’s saga. – The first impression of Utferdsdråpa was mighty, often moving. The composer must have gone into his work with a glowing enthusiasm. Even the first shanty – Ut, ja ut – carried us away, and the atmosphere of nature in Bles yver bugti or the barcarolle – um Ferderøy, blankt og blått – was lovely. The work otherwise changed with the content of the poem, widely martial (Vanke dei vide) gloomy or flaming with colours and life as in the part Reiste sig Ran; here, the instrumentation was also totally sumptuous: Wasn’t it like the mythical animal. The Midgar snake – twisting and turning in the storm.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>The work ends with a hymn sung by a large choir. In the final rerun the whole thing rises to enthusiasm, where the orchestra is characterized by drums and everything culminates in a wonderful finale.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Last night, Haakon Hoem once again confirmed his position as a skilful musician. He also created a great cooperation with the choir. Nidaros Mandssangforening was last night in line with their best traditions. They sung lively. The baryton Egil Nordsjø was a splendid soloist. He sung his demanding part with inspiration and musical warmth.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Mr. J. Grabowski was the tenor soloist. He was partly lucky and sung with great force, but the accuracy failed him not a little. – The applause was tremendous and the soloists were given lovely flowers.</Trans>
						</p>
						<p>
							E.W.
						</p>
					</div>
				</SheetOfPaper>
			</Page>
		</Chapter>
	);
};

export default C03P21;
import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../../i18n';
import { Trans } from "react-i18next";
import { Page, TransparentPage, SheetOfPaper } from "../../../components/Page/Page";
import Chapter from "../../../components/Chapter/Chapter";
import SourceHeader from "../../../components/SourceHeader/SourceHeader";

import routes from './c05_routes.js';
import ENTranscriptPDF from './Transcripts/en/en_c5_p14.pdf';
import NOTranscriptPDF from './Transcripts/no/no_c5_p14.pdf';
import DocumentIMG from './Media/c5_p14.jpg';

const C05P14 = (props) => {
	const { t } = useTranslation();
	const translation_namespace = 'c05_p14';

	return (
		<Chapter pages={routes} chapterName={props.chapterName} currentPageYear={props.year}>
			<Page className="C05P14">
				<TransparentPage>
					<p><img src={ DocumentIMG } alt={ t('Image of the original document transcribed next') } /></p>
					<ul className="download-links">
						<li><a href={ i18n.language === 'nb-NO' ? NOTranscriptPDF : ENTranscriptPDF } target="_blank"><Trans>Download</Trans> pdf <Trans>file</Trans></a></li>
						<li><a href={DocumentIMG} target="_blank"><Trans>Download</Trans> <Trans>image file</Trans></a></li>
					</ul>
				</TransparentPage>
				<SheetOfPaper>
					<SourceHeader name={ props.name } chapterName={ props.chapterName } />
					<p>
						<strong><Trans ns={ translation_namespace }>Work permit for Josef Grabowski, dated February 3rd, 1940. Journal no. 1941/254 in the Central Passport Office’s archives. The National Archives in Oslo.</Trans></strong>
					</p>
					<h2><Trans>Transcript</Trans>:</h2>
					<div className="transcript">
						<p>
							<Trans ns={ translation_namespace }>The Central Passport Office.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>WORK PERMIT.</Trans>
						</p>
						<p>
							Josef Grabowski<br />
							<Trans ns={ translation_namespace }>born May 11th, 1911 in Posen</Trans><br />
							<Trans ns={ translation_namespace }>at present living in ...</Trans><br />
							<Trans ns={ translation_namespace }>and with a German citizenship</Trans><br />
							<Trans ns={ translation_namespace }>is hereby granted the permission to take over the position</Trans><br />
							<Trans ns={ translation_namespace }>as cantor</Trans><br />
							<Trans ns={ translation_namespace }>in the Jewish Community, Trondheim.</Trans><br />
							<Trans ns={ translation_namespace }>The permit is valid until February 1st, 1941.</Trans><br />
							<Trans ns={ translation_namespace }>but is subject to withdrawal if good reason to do so should arise.</Trans><br />
							<Trans ns={ translation_namespace }>The holder of this work permit is obliged to report to the local police on the place of residence prior to commencing his work and no later than 6 days after arrival.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Oslo, on February 3rd, 1940.</Trans>
						</p>
					</div>
				</SheetOfPaper>
			</Page>
		</Chapter>
	);
};

export default C05P14;
import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../../i18n';
import { Trans } from "react-i18next";
import { Page, TransparentPage, SheetOfPaper } from "../../../components/Page/Page";
import Chapter from "../../../components/Chapter/Chapter";
import SourceHeader from "../../../components/SourceHeader/SourceHeader";

import routes from './c02_routes.js';
import ENTranscriptPDF from './Transcripts/en/en_c2_p9.pdf';
import NOTranscriptPDF from './Transcripts/no/no_c2_p9.pdf';
import DocumentIMG1 from './Media/c2_p9_m1.jpg';
import DocumentIMG2 from './Media/c2_p9_m2.jpg';

const C02P09 = (props) => {
	const { t } = useTranslation();
	const translation_namespace = 'c02_p09';

	return (
		<Chapter pages={routes} chapterName={props.chapterName} currentPageYear={props.year}>
			<Page className="C02P09">
				<TransparentPage>
					<p><img src={ DocumentIMG1 } alt={ t('Image of the original document transcribed next') } /></p>
					<p><img src={ DocumentIMG2 } alt={ t('Image of the original document transcribed next') } /></p>
					<ul className="download-links">
						<li><a href={ i18n.language === 'nb-NO' ? NOTranscriptPDF : ENTranscriptPDF } target="_blank"><Trans>Download</Trans> pdf <Trans>file</Trans></a></li>
						<li><a href={DocumentIMG1} target="_blank"><Trans>Download</Trans> <Trans>image file</Trans> 1</a></li>
						<li><a href={DocumentIMG2} target="_blank"><Trans>Download</Trans> <Trans>image file</Trans> 2</a></li>
					</ul>
				</TransparentPage>
				<SheetOfPaper>
					<SourceHeader name={ props.name } chapterName={ props.chapterName } />
					<p>
						<strong><Trans ns={ translation_namespace }>Extract of the minutes from an extraordinary congregational meeting in the Jewish Community in Trondheim, dated January 31st, 1939. The Jewish Museum in Trondheim.</Trans></strong>
					</p>
					<h2><Trans>Transcript</Trans>:</h2>
					<div className="transcript">
						<p>
							<Trans ns={ translation_namespace }>An extraordinary congregational meeting was arranged in the congregation’s premises Monday January 31st, 1939. On the agenda was the board’s proposition to employ cantor Josef Grabowski, Gleiwitz, as cantor in the congregation, for the present for one year. The Chairman, Mr. Isidor Isaksen, presented the issue, and then the secretary, Mr. Oskar Mendelsohn, gave an account of the correspondence having taken place between the congregation and cantor Josef Grabowski Gleiwitz, cantor Leo Grabowski in Copenhagen and the Central Passport Office and the immigration department of the police in Trondheim.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>It was cantor Leo Grabowski in Copenhagen who requested this congregation to employ his brother, Josef Grabowski, as cantor. Cantor Leo Grabowski had paid NOK 900 to the congregation to cover 6 month’s pay for cantor Josef Grabowski, as the congregation’s board had offered him a salary of NOK 150 per month for the time being.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Further more, Mr. merchant Frederik Herz, Copenhagen, issued a guarantee valid until January 1st, 1940, or until the time when Josef Grabowski has stayed 1 year in Norway, stating that Leo Grabowski will make monthly payments of NOK 150 to our congregation. Originally, the board demanded further guarantees for a longer period of time, but as it was difficult for Mr. Leo Grabowski to obtain such guarantees, and since Mr. Josef Grabowski was very anxious to gain an entry and residence permit as well as a work permit in Norway as soon as possible (in order for the Central Passport Office to grant these permits, the congregation had to give a statement to the Central Passport Office that Josef Grabowski would not be a burden to the public), the board suggested to waive the claim for further guarantees as an absolute condition for employing Grabowski , and immediately give the Central Passport Office the guarantee they required.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>The Chairman further informed that Josef Grabowski’s stay in Trondheim most likely would be of short duration, as cantor Grabowski had already been in touch with a congregation in St. Francisco. Another question was how soon Mr. Grabowski would succeed in obtaining an entry permit to America. It was the board’s opinion that the Central Passport Office could be given the required guarantee, as the risk did not seem to be very high. Should, however, the worst thing happen and the congregation be put to greater financial responsibility, it must be kept in mind that the congregation has a moral duty to help in times like these. Jews in Trondheim had not done the same for refugees as had been done in other cities, come to that. (...)</Trans>
						</p>
					</div>
				</SheetOfPaper>
			</Page>
		</Chapter>
	);
};

export default C02P09;
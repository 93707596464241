import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../../i18n';
import { Trans } from "react-i18next";
import { Page, TransparentPage, SheetOfPaper } from "../../../components/Page/Page";
import Chapter from "../../../components/Chapter/Chapter";
import SourceHeader from "../../../components/SourceHeader/SourceHeader";

import routes from './c01_routes.js';
import ENTranscriptPDF from './Transcripts/en/en_c1_p3.pdf';
import NOTranscriptPDF from './Transcripts/no/no_c1_p3.pdf';
import DocumentIMG from './Media/c1_p3.jpg';

const C01P03 = (props) => {
	const { t } = useTranslation();

	return (
		<Chapter pages={routes} chapterName={props.chapterName} currentPageYear={props.year}>
			<Page className="C01P03">
				<TransparentPage>
					<img src={ DocumentIMG } alt={ t('Image of the original document transcribed next') } />
					<ul className="download-links">
						<li><a href={ i18n.language === 'nb-NO' ? NOTranscriptPDF : ENTranscriptPDF } target="_blank"><Trans>Download</Trans> pdf <Trans>file</Trans></a></li>
						<li><a href={DocumentIMG} target="_blank"><Trans>Download</Trans> <Trans>image file</Trans></a></li>
					</ul>
				</TransparentPage>
				<SheetOfPaper>
					<SourceHeader name={ props.name } chapterName={ props.chapterName } />
					<p><strong><Trans ns="c01_p03">Story by former Falstad prisoner, Frank Storm Johansen, the Falstad Centre’s archives, F series.</Trans></strong></p>
					<h2><Trans>Transcript</Trans>:</h2>
					<p><Trans ns="c01_p03">... Among the Jews, there was an opera singer, a Polish refugee, apparently. The guards, like most Germans, liked singing, and some times this chap was called forward on the evening assembly and ordered to sing. Then he would stand in front of us and sing whatever the Germans demanded, mostly operetta melodies. Nobody who experienced that will ever forget it. The Jew in a worn prison uniform, tired and exhausted after the strain and the suffering of the day, singing for a troop of prisoners in lines straight as an arrow and to his tormentors, the German guards. Some times the atmosphere was brutally broken by a German wanting to hear a different song. And some times the singer eventually was chased back to his place with kicks and roars ...</Trans></p>
				</SheetOfPaper>
			</Page>
		</Chapter>
	);
};

export default C01P03;
import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../../i18n';
import { Trans } from "react-i18next";
import { Page, TransparentPage, SheetOfPaper } from "../../../components/Page/Page";
import Chapter from "../../../components/Chapter/Chapter";
import SourceHeader from "../../../components/SourceHeader/SourceHeader";

import routes from './c05_routes.js';
import ENTranscriptPDF from './Transcripts/en/en_c5_p5.pdf';
import NOTranscriptPDF from './Transcripts/no/no_c5_p5.pdf';
import DocumentIMG from './Media/c5_p5.jpg';

const C05P05 = (props) => {
	const { t } = useTranslation();
	const translation_namespace = 'c05_p05';

	return (
		<Chapter pages={routes} chapterName={props.chapterName} currentPageYear={props.year}>
			<Page className="C05P05">
				<TransparentPage>
					<p><img src={ DocumentIMG } alt={ t('Image of the original document transcribed next') } /></p>
					<ul className="download-links">
						<li><a href={ i18n.language === 'nb-NO' ? NOTranscriptPDF : ENTranscriptPDF } target="_blank"><Trans>Download</Trans> pdf <Trans>file</Trans></a></li>
						<li><a href={DocumentIMG} target="_blank"><Trans>Download</Trans> <Trans>image file</Trans></a></li>
					</ul>
				</TransparentPage>
				<SheetOfPaper>
					<SourceHeader name={ props.name } chapterName={ props.chapterName } />
					<p>
						<strong><Trans ns={ translation_namespace }>Aliens Registration Form A for Josef Grabowski, dated January 14th, 1939. Journal no. 1941/254 in the Central Passport Office’s archives. The National Archives in Oslo.</Trans></strong>
					</p>
					<h2><Trans>Transcript</Trans>:</h2>
					<div className="transcript">
						<p>
							<Trans ns={ translation_namespace }>Aliens Registration Form A</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Application for work permit</Trans>
						</p>
						<p>
							<ul>
								<li><Trans ns={ translation_namespace }>Undersigned (full name)... Josef Grabowski</Trans></li>
								<li><Trans ns={ translation_namespace }>born ... May 11th, 1911 in Posen (seit 1918 Polen) [= since 1918 Poland]</Trans></li>
								<li><Trans ns={ translation_namespace }>citizen of... Deutscher Staatsbürger [= German citizen]</Trans></li>
								<li><Trans ns={ translation_namespace }>education... als Kantor [= as cantor]</Trans></li>
								<li><Trans ns={ translation_namespace }>former occupation... Kantor</Trans></li>
								<li><Trans ns={ translation_namespace }>present position... Kantor bei der mosaischen Gemeinde in Gleiwitz [= cantor in the Jewish congregation in Gleiwitz]</Trans></li>
								<li><Trans ns={ translation_namespace }>place of residence... Gleiwitz O.S. Reichspräsidentenplatz 1.</Trans></li>
								<li><Trans ns={ translation_namespace }>applies for a permit to take over the position as... als Kantor [= as
cantor]</Trans></li>
								<li><Trans ns={ translation_namespace }>at... bei Det Mosaiske Trossamfund in Trondheim [= in the Jewish
Community in Trondheim]</Trans></li>
								<li><Trans ns={ translation_namespace }>for a period of... einem Jahre [= one year] from sofort [= as soon as
possible]</Trans></li>
							</ul>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Gleiwitz on January 14th, 1939</Trans><br />
							Josef Grabowski
						</p>
						<p>
							<Trans ns={ translation_namespace }>To the Central Passport Office, Oslo.</Trans>
						</p>
					</div>
				</SheetOfPaper>
			</Page>
		</Chapter>
	);
};

export default C05P05;
import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../../i18n';
import { Trans } from "react-i18next";
import { Page, TransparentPage, SheetOfPaper } from "../../../components/Page/Page";
import Chapter from "../../../components/Chapter/Chapter";
import SourceHeader from "../../../components/SourceHeader/SourceHeader";

import routes from './c05_routes.js';
import ENTranscriptPDF from './Transcripts/en/en_c5_p1.pdf';
import NOTranscriptPDF from './Transcripts/no/no_c5_p1.pdf';
import DocumentIMG from './Media/c5_p1.jpg';

const C05P01 = (props) => {
	const { t } = useTranslation();
	const translation_namespace = 'c05_p01';

	return (
		<Chapter pages={routes} chapterName={props.chapterName} currentPageYear={props.year}>
			<Page className="C05P01">
				<TransparentPage>
					<p><img src={ DocumentIMG } alt={ t('Image of the original document transcribed next') } /></p>
					<ul className="download-links">
						<li><a href={ i18n.language === 'nb-NO' ? NOTranscriptPDF : ENTranscriptPDF } target="_blank"><Trans>Download</Trans> pdf <Trans>file</Trans></a></li>
						<li><a href={DocumentIMG} target="_blank"><Trans>Download</Trans> <Trans>image file</Trans></a></li>
					</ul>
				</TransparentPage>
				<SheetOfPaper>
					<SourceHeader name={ props.name } chapterName={ props.chapterName } />
					<p>
						<strong><Trans ns={ translation_namespace }>Letter from the Jewish Community in Trondheim to the Central Passport Office December 19th, 1938. Journal no. 1941/254 in the Central Passport Office’s archives. The National Archives in Oslo.</Trans></strong>
					</p>
					<h2><Trans>Transcript</Trans>:</h2>
					<div className="transcript">
						<p>
							<Trans ns={ translation_namespace }>The Jewish Community</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Trondheim December 19th, 1938</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>To the Central Passport Office,</Trans><br />
							<Trans ns={ translation_namespace }>Oslo.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>The Jewish Community, Trondheim, hereby applies for an entry and residence permit, and if necessary also for a work permit, for cantor Josef Grabowski who the congregational board meeting on December 4th, 1938 decided to employ as cantor for the congregation, for the present for one year.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Sadly, for several years the congregation has not been able to fill this position, which is so important for the Mosaic service, with a suitably educated man. However, the congregation’s board is now of the opinion that this position must be filled permanently, as we up until this day sporadically have hired cantors from abroad to the important holidays. As there are no duly educated Jewish cantors in Norway to take up this position, the board has been forced to apply to foreign cantors. Cantor Josef Grabowski has declared himself willing to take this position, for one year for the present, against a monthly cash payment of NOK 150.00. – Cantor Josef Grabowski who is born on May 11th, 1911 in Posen (former Germany, today Poland), is a German citizen. He is educated at the Jewish seminars in Nürnberg and Cologne. He was hired as cantor for the Jewish congregation in Berlin-Friedenau and lately served as senior cantor and teacher in Gleiwitz, Silesia.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Cantor Josef Grabowski is presently staying in Gleiwitz, address Reichskanzlerplatz 1. Cantor Josef Grabowski is made aware of the fact that he himself must submit an application for an entry and residence permit, and work permit if needed.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>As we hope this application will be treated favourably and granted, we sign Yours faithfully, Isidor Isaksen of the Board of the Jewish Community, at present Chairman.</Trans>
						</p>
					</div>
				</SheetOfPaper>
			</Page>
		</Chapter>
	);
};

export default C05P01;
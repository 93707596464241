import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation, initReactI18next } from "react-i18next";
import { Trans } from "react-i18next";
import TimelineModal from '../TimelineModal/TimelineModal';
import './timeline.scss';

//import CircularGradient from '../../images/timeline_gradient.jpg';

const Timeline = (props) => {
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [selectedYear, setSelectedYear] = useState(null);
	const [modalContent, setModalContent] = useState('');

	const handleTimelineClick = (year, content) => {
		console.log('year', year);
		setSelectedYear(year);
		setModalContent(content);
		setModalIsOpen(true);
	}

	const handleCloseModal = () => {
		setSelectedYear(null);
		setModalIsOpen(false);
	}

	const y1933 = () => {
		return (
			<React.Fragment>
				<h1><Trans ns="timeline_1933">Hitler comes into power</Trans></h1>
				<p><Trans ns="timeline_1933">On January 20th, 1933, Adolf Hitler was appointed as Imperial Chancellor of Germany. Shortly after, he annulled the democracy. Already in spring that year, the first major action against the Jews is arranged in Germany: The Nazis boycott Jewish businesses. Then, laws are passed that result in Jewish public officers losing their jobs. Books written by Jewish writers and political opponents are burnt in public.</Trans></p>
			</React.Fragment>
		)
	}

	const y1935 = () => {
		return (
			<React.Fragment>
				<h1><Trans ns="timeline_1935">The Nuremberg Laws</Trans></h1>
				<p><Trans ns="timeline_1935">On September 15th, 1935, the "Race Laws" in Nuremberg are passed. The Nuremberg Laws pave the way to increased persecution of Jews in Germany: Jews are stripped of civil rights, and marriages between "Aryans" and "non-Aryans" are forbidden. The following year, jews, roma and sinti lose their rights to vote. Approximately 21,000 Jews emigrate from Germany in 1935.</Trans></p>
			</React.Fragment>
		)
	}

	const y1938 = () => {
		return (
			<React.Fragment>
				<h1><Trans ns="timeline_1938">Jews fleeing from Germany</Trans></h1>
				<p><Trans ns="timeline_1938">In July 1938, delegates from 32 countries meet in Evian in France to discuss the rising problem with the increasing flow of Jewish refugees from Germany. Most of the countries refuse to accept the refugees, including Norway. Norwegian authorities do not recognize Jewish refugees as political refugees. In the Norwegian media and among prominent politicians, there is a scepticism regarding the "flow of Jewish refugees".</Trans></p>
				<p><Trans ns="timeline_1938">At the same time, the conditions are further tightened up in Germany: All Jews are required to carry ID cards, they are forced to adopt Jewish first names, Sara or Israel, in addition to their original names, and in October, the letter "J" is stamped in the passports of Jewish Germans.</Trans></p>
				<h1><Trans ns="timeline_1938">The Night of Broken Glass</Trans></h1>
				<p><Trans ns="timeline_1938">On November 9-10th, 1938, yet another escalation of the persecution of Jews in Germany is carried out; the November Pogroms or the Night of Broken Glass. Businesses and flats owned by Jews are destroyed and robbed, and synagogues are burnt. Close to 30,000 Jewish men are arrested and sent to concentration camps. Approximately 10,000 of which are sent to the Buchenwald camp.</Trans></p>
			</React.Fragment>
		)
	}

	const y1939 = () => {
		return (
			<React.Fragment>
				<h1><Trans ns="timeline_1939">World War II begins</Trans></h1>
				<p><Trans ns="timeline_1939">On August 31st, Germany stages a Polish attack on the German radio station in Gleiwitz, close to the Polish border. The fake attach becomes the excuse for invading Poland, and World War II has begun. With the invasion of Poland, some 2 million Jews come under German control. Separate ghettos are established in the cities, with several hundred thousand Jewish inhabitants. In Norway, the refugee and immigration policies regarding Jewish refugees are still very restrictive. Before April 1940, approx. 400 foreign Jews have come to Norway.</Trans></p>
			</React.Fragment>
		)
	}

	const y1940 = () => {
		return (
			<React.Fragment>
				<h1><Trans ns="timeline_1940">The Occupation of Norway</Trans></h1>
				<p><Trans ns="timeline_1940">In 1940, Germany invades Norway, Denmark, Belgium, Luxembourg, Holland and France. In Norway, the registration of Norwegian and foreign Jews starts already in May that year - first in Oslo, then in Trondheim. The lists of members for the Jewish communities are handed over to the police. Norwegian Jews are also forced to hand in their radios.</Trans></p>
			</React.Fragment>
		)
	}

	const y1941 = () => {
		return (
			<React.Fragment>
				<h1><Trans ns="timeline_1941">Racial war in the east</Trans></h1>
				<p><Trans ns="timeline_1941">On June 22nd, 1941, Germany attacks the Soviet Union. The attack marks the start of a racial and extermination war against the "Jewish Bolshevism". Organised mass executions of Jews and communists are carried out by German SS-Einsatz groups. Deportations of Jews to ghettos in Poland are arranged on a large scale.</Trans></p>
				<h1><Trans ns="timeline_1941">Tightening the grip in Norway</Trans></h1>
				<p><Trans ns="timeline_1941">In 1941, a systematic mapping of Norwegian Jews and their property is initiated. The mapping is mainly conducted by Norwegian authorities. On April 21st, German occupation authorities confiscate the synagogue in Trondheim. A few months later, all male Jews in North-Norway are arrested, followed by 60 stateless Jews.</Trans></p>
			</React.Fragment>
		)
	}

	const y1942 = () => {
		return (
			<React.Fragment>
				<h1><Trans ns="timeline_1942">The Final Solution</Trans></h1>
				<p><Trans ns="timeline_1942">On January 20th, 1942, representatives for the German state, the Nazi Party and the SS meet in Wannsee outside Berlin to discuss "the final solution of the Jewish question". The process towards the extermination of Jews in Europe has already begun, but the deportations to extermination camps in the east are now coordinated and cover all Jews in countries occupied by Germany, including Norway. Over the following months, hundreds of thousands of Jews are deported to the extermination camps -Birkenau, Bełżec, Chełmno, Majdanek, Sobibor and Treblinka - the majority is gassed to death.</Trans></p>
				<h1><Trans ns="timeline_1942">Deportations from Norway</Trans></h1>
				<p><Trans ns="timeline_1942">In autumn 1942, mass arrests of Jews are organised all over Norway. The arrests are carried out by the Norwegian police. In Trondheim, Jewish women and children are kept in detention in a flat at Museumsplass, while 27 men are sent to Falstad prison camp.</Trans></p>
				<p><Trans ns="timeline_1942">In November, Jews from all over the country are gathered in Oslo for deportation. During the winter months, they are sent on the ships "Donau", "Monte Rosa" and "Gotenland" from the quay in Oslo to Stettin, and from there by train in direction of Auschwitz. Jews from Trondheim arrive at the extermination camp on March 3rd, 1943. Most of them are taken directly to the gas chambers, while 28 men are put to forced labour in the subsidiary camp Buna-Monowitz.</Trans></p>
				<p><Trans ns="timeline_1942">In total, 771 Norwegian Jews are deported.</Trans></p>
			</React.Fragment>
		)
	}

	const y1943 = () => {
		return (
			<React.Fragment>
				<h1><Trans ns="timeline_1943">Rebellions in ghettos and camps</Trans></h1>
				<p><Trans ns="timeline_1943">In February 1943, Germany loses the battle of Stalingrad. The defeat marks a turning point of the war. In the Warsaw ghetto, the Jews rebel and fight for almost a month before the resistance is stopped and several thousand are executed. In the Sobibor and Treblinka camps, the Jews also try to fight, but the resistance is beaten down. The following year, prisoners in Auschwitz succeed in blowing up a crematorium before they are overpowered and executed.</Trans></p>
			</React.Fragment>
		)
	}

	const y1945 = () => {
		return (
			<React.Fragment>
				<h1><Trans ns="timeline_1945">The Liberation of the Camps</Trans></h1>
				<p><Trans ns="timeline_1945">At the start of 1945, Germany is losing the war on all fronts. On January 27th, Soviet troops liberate the Auschwitz complex but only find a few thousand prisoners in the camp. Ten days earlier, the Nazis decided to close down the camp and sent 60,000 prisoners on a "death march" heading west. In the following months, one death camp after the other is liberated. On May 7th, Germany surrenders and World War II is over.</Trans></p>
				<h2><Trans ns="timeline_1945">Death tolls</Trans></h2>
				<p><Trans ns="timeline_1945">Approximately six million Jews became victims of the Nazi's genocide during World War II. About half of them came from German-occupied Poland.</Trans></p>
				<p><Trans ns="timeline_1945">Norway lost one third of its Jewish population, as 771 Norwegian Jews were deported to Auschwitz in autumn 1942 and winter 1943. Only 34 of them survived.</Trans></p>
				<p><Trans ns="timeline_1945">A total of 130 of the Norwegian victims came from the Jewish Community in Trondheim.</Trans></p>
			</React.Fragment>
		)
	}

	useEffect(() => {
		console.log('props.year', props.year);
	}, [props.year]);

	return (
		<div className="Timeline">
			<ul>
				<li className={`year ${ props.year == 1933 ? "current" : "" }`}><a href="#1933" onClick={() => { handleTimelineClick(1933, y1933()); }}>1933</a></li>
				<li className={`year ${ props.year == 1935 ? "current" : "" }`}><a href="#1935" onClick={() => { handleTimelineClick(1935, y1935()); }}>1935</a></li>
				<li className={`year ${ props.year == 1938 ? "current" : "" }`}><a href="#1938" onClick={() => { handleTimelineClick(1938, y1938()); }}>1938</a></li>
				<li className={`year ${ props.year == 1939 ? "current" : "" }`}><a href="#1939" onClick={() => { handleTimelineClick(1939, y1939()); }}>1939</a></li>
				<li className={`year ${ props.year == 1940 ? "current" : "" }`}><a href="#1940" onClick={() => { handleTimelineClick(1940, y1940()); }}>1940</a></li>
				<li className={`year ${ props.year == 1941 ? "current" : "" }`}><a href="#1941" onClick={() => { handleTimelineClick(1941, y1941()); }}>1941</a></li>
				<li className={`year ${ props.year == 1942 ? "current" : "" }`}><a href="#1942" onClick={() => { handleTimelineClick(1942, y1942()); }}>1942</a></li>
				<li className={`year ${ props.year == 1943 ? "current" : "" }`}><a href="#1943" onClick={() => { handleTimelineClick(1943, y1943()); }}>1943</a></li>
				<li className={`year ${ props.year == 1945 ? "current" : "" }`}><a href="#1945" onClick={() => { handleTimelineClick(1945, y1945()); }}>1945</a></li>
			</ul>
			<TimelineModal open={modalIsOpen} year={selectedYear} onClose={handleCloseModal}>
				{ modalContent }
			</TimelineModal>
		</div>
	);
}

Timeline.defaultProps = {
	
}

Timeline.propTypes = {
	
}

export default Timeline;
import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../../i18n';
import { Trans } from "react-i18next";
import { Page, TransparentPage, SheetOfPaper } from "../../../components/Page/Page";
import Chapter from "../../../components/Chapter/Chapter";
import SourceHeader from "../../../components/SourceHeader/SourceHeader";

import routes from './c02_routes.js';
import ENTranscriptPDF from './Transcripts/en/en_c2_p3.pdf';
import NOTranscriptPDF from './Transcripts/no/no_c2_p3.pdf';
import DocumentIMG from './Media/c2_p3.jpg';

const C02P03 = (props) => {
	const { t } = useTranslation();

	return (
		<Chapter pages={routes} chapterName={props.chapterName} currentPageYear={props.year}>
			<Page className="C02P03">
				<TransparentPage>
					<img src={ DocumentIMG } alt={ t('Image of the original document transcribed next') } />
					<ul className="download-links">
						<li><a href={ i18n.language === 'nb-NO' ? NOTranscriptPDF : ENTranscriptPDF } target="_blank"><Trans>Download</Trans> pdf <Trans>file</Trans></a></li>
						<li><a href={DocumentIMG} target="_blank"><Trans>Download</Trans> <Trans>image file</Trans></a></li>
					</ul>
				</TransparentPage>
				<SheetOfPaper>
					<SourceHeader name={ props.name } chapterName={ props.chapterName } />
					<p><strong><Trans ns="c02_p03">Annual Report for The Jewish Community in Trondheim, dated December 24th, 1941. The Jewish Museum in Trondheim.</Trans></strong></p>
					<p>
						<Trans>The Jewish Community</Trans><br />
						Trondheim <Trans ns="c02_p03">December 24th, 1941</Trans>
					</p>
					<p><Trans ns="c02_p03">Report for the period between the 1940 General Assembly and the 1941 Congregational Meeting</Trans></p>
					<p><Trans ns="c02_p03">The annual general assembly was held on Sunday December 15th, 1940.</Trans></p>
					<p><Trans ns="c02_p03">Since the general assembly, 3 regular board meetings have been held, discussing a total of 12 items. In addition, 2 board conferences have been arranged. Further more, several conferences between the chairman of the board, the treasurer and the secretary were held, partly including the other board members, when matters requiring a rapid decision were discussed. For one of the board meetings and some of the board conferences, cantor Grabowski was summoned, to the board conferences as the representative of Chewrol Kadischoh.</Trans></p>
					<p><Trans ns="c02_p03">The board has made a part payment on the house property in Ark. Christies gt.1 b of NOK 500.00, so the mortgage debt now amounts to NOK 52,700.00. On the whole, the subscriptions have come more easily this year than previously. This year, subscriptions, fees and donations amount to approx. NOK 16,600.00. (as against approx. 12,000.00 last year). As of Aug. 31st, arrears amount to NOK 6,783.15, which is approx. NOK 2,000 less than the previous year.</Trans></p>
					<p><Trans ns="c02_p03">Ruler Jacobsohn has not succeeded in returning during the past year, and nor has it been possible to transfer his payment to the full. Nor have several of the members who were outside the Norwegian boarders the first months after April 9th, 1940 been able to return. Further more, due to recent events some members have been prevented from participating in congregational life.</Trans></p>
					<p><Trans ns="c02_p03">Services have been arranged regularly throughout the year. The service attendance has to some extent been very good. In the period before April 21st, the services were arranged in the congregation’s synagogue, Ark. Christies gt 1 b. On April 21st, these premises were taken in use by the German protective force. Negotiations have been conducted with the German authorities on rental fees, but they resulted in the German authorities not finding it possible to pay such fees. In the period following April 21st, the services have been arranged in 2 rooms placed at the congregation’s disposal for this purpose in Aron Mendelsohn’s flat, Kjøpmannsgata 40. For the Rosch-ha-Schono and Yom-Kippur larger premises in the city had to be hired for the services.</Trans></p>
					<p><Trans ns="c02_p03">The Jewish societies have not had any meetings in the past year. In the period from Aug. 19th, 1940 until Passover, the congregation’s meeting facilities were used for teaching for pupils from Kalvskinnet primary school. It was the intention that the same facilities were to be used by the school Katedralskolen after Passover. In the first 4 months of 1941, the religious teaching was conducted in the congregation’s teaching rooms. Later, it has been conducted at cantor Grabowski’s home. At present, the religious school has 12 pupils, 5 boys and 7 girls.</Trans></p>
					<p><Trans ns="c02_p03">The church yard has been planted as usual.</Trans></p>
					<p><Trans ns="c02_p03">In the past year, cantor Grabowski has cooperated with the clerical functions associated with the community in Oslo. The board has also frequently corresponded with ruler Jacobsohn. The board has not succeeded in providing the members with meat supplies from Sweden or Denmark.</Trans></p>
					<p><Trans ns="c02_p03">In the past year, the board has had the same composition as last year, though in such a manner that Mr. W. Klein has acted as the 2nd deputy member instead of Mr. Bernhard Buchmann. As auditors, the general assembly last year elected Mr. Rudolf Isaksen and Mr. Heyman Abrahamsen, with Mr. Mendel Klein as deputy auditor. Functioning as the school committee: Oskar Mendelsohn (chairman), Aron Mendelsohn, Berhard Isaksen, H. Komissar and Isak Lankelinsky.</Trans></p>
					<p><Trans ns="c02_p03">As the board member Mr. Marcus Guttman past away in September this year, Mr. W. Klein stepped into the board. However, as there were no deputy board members left, the acting board has appointed the following deputy members for the present: 1) Bernhard Isaksen 2) Oskar Mendelsohn.</Trans></p>
					<p>
						<Trans ns="c02_p03">In the board for the Jewish Community</Trans><br />
						<Trans>chairman</Trans>
					</p>
					<p>
						<Trans>Statistics</Trans><br />
						<table>
							<tr>
								<td><Trans>Marriages</Trans>: 3</td>
								<td><Trans>Born</Trans>: 1 <Trans>boy</Trans>, 1 <Trans>girl</Trans></td>
								<td><Trans>Dead</Trans>: 3 <Trans>men</Trans>, 2 <Trans>women</Trans></td>
							</tr>
						</table>
					</p>
				</SheetOfPaper>
			</Page>
		</Chapter>
	);
};

export default C02P03;
import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../../i18n';
import { Trans } from "react-i18next";
import { Page, TransparentPage, SheetOfPaper } from "../../../components/Page/Page";
import Chapter from "../../../components/Chapter/Chapter";
import SourceHeader from "../../../components/SourceHeader/SourceHeader";

import routes from './c03_routes.js';
import ENTranscriptPDF from './Transcripts/en/en_c3_p13.pdf';
import NOTranscriptPDF from './Transcripts/no/no_c3_p13.pdf';
import DocumentIMG from './Media/c3_p13.jpg';

const C03P13 = (props) => {
	const { t } = useTranslation();
	const translation_namespace = 'c03_p13';

	return (
		<Chapter pages={routes} chapterName={props.chapterName} currentPageYear={props.year}>
			<Page className="C03P13">
				<TransparentPage>
					<p><img src={ DocumentIMG } alt={ t('Image of the original document transcribed next') } /></p>
					<ul className="download-links">
						<li><a href={ i18n.language === 'nb-NO' ? NOTranscriptPDF : ENTranscriptPDF } target="_blank"><Trans>Download</Trans> pdf <Trans>file</Trans></a></li>
						<li><a href={DocumentIMG} target="_blank"><Trans>Download</Trans> <Trans>image file</Trans></a></li>
					</ul>
				</TransparentPage>
				<SheetOfPaper>
					<SourceHeader name={ props.name } chapterName={ props.chapterName } />
					<p>
						<strong><Trans ns={ translation_namespace }>Entry no. 1939/709 in the Police’s immigration journals for 1935– 1942, dated September 11th, 1939. The Regional State Archives in Trondheim.</Trans></strong>
					</p>
					<h2><Trans>Transcript</Trans>:</h2>
					<div className="transcript">
						<p>
							<Trans ns={ translation_namespace }>Ruler Jacobsohn informs that Mrs. Lisbeth Grabowski, Gleiwitz, has applied in person to the Norwegian Consulate in Berlin regarding an entry permit and that the matter could not be dealt with without instructions from the Central Passport Office.</Trans>
						</p>
					</div>
				</SheetOfPaper>
			</Page>
		</Chapter>
	);
};

export default C03P13;
import React from 'react';
import { i18n } from "../../../i18n.js";

import C07P01 from './C07P01';
import C07P02 from './C07P02';

const chapterName = "Buchenwald and Auschwitz";

const c07_routes = [
	{
		path: '/chapter/7/page/1',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 1',
		name: 'Source 1',
		element: <C07P01 chapterName={chapterName} name="Source 1" language="en" year="1938" />
	},
	{
		path: '/chapter/7/page/2',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 2',
		name: 'Source 2',
		element: <C07P02 chapterName={chapterName} name="Source 2" language="en" year="1943" />
	},
	{
		path: '/chapter/7/',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 1',
		name: 'Source 1',
		ignoreInPagelist: true,
		element: <C07P01 chapterName={chapterName} name="Source 1" language="en" year="1938" />
	},
];

export default c07_routes;
import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../../i18n';
import { Trans } from "react-i18next";
import { Page, TransparentPage, SheetOfPaper } from "../../../components/Page/Page";
import Chapter from "../../../components/Chapter/Chapter";
import SourceHeader from "../../../components/SourceHeader/SourceHeader";

import routes from './c05_routes.js';
import ENTranscriptPDF from './Transcripts/en/en_c5_p4.pdf';
import NOTranscriptPDF from './Transcripts/no/no_c5_p4.pdf';
import DocumentIMG from './Media/c5_p4.jpg';

const C05P04 = (props) => {
	const { t } = useTranslation();
	const translation_namespace = 'c05_p04';

	return (
		<Chapter pages={routes} chapterName={props.chapterName} currentPageYear={props.year}>
			<Page className="C05P04">
				<TransparentPage>
					<p><img src={ DocumentIMG } alt={ t('Image of the original document transcribed next') } /></p>
					<ul className="download-links">
						<li><a href={ i18n.language === 'nb-NO' ? NOTranscriptPDF : ENTranscriptPDF } target="_blank"><Trans>Download</Trans> pdf <Trans>file</Trans></a></li>
						<li><a href={DocumentIMG} target="_blank"><Trans>Download</Trans> <Trans>image file</Trans></a></li>
					</ul>
				</TransparentPage>
				<SheetOfPaper>
					<SourceHeader name={ props.name } chapterName={ props.chapterName } />
					<p>
						<strong><Trans ns={ translation_namespace }>Letter from Josef Grabowski to the Central Passport Office January 2nd, 1939. Journal no. 1941/254 in the Central Passport Office’s archives. The National Archives in Oslo.</Trans></strong>
					</p>
					<h2><Trans>Transcript</Trans>:</h2>
					<div className="transcript">
						<p>
							Josef Grabowski <br />
							Gleiwitz O/S
						</p>
						<p>
							Gleiwitz, den 2. Januar 1939 <br />
							Reichspräsidentenplatz 1
						</p>
						<p>
							Centralpasskontoret <br />
							Oslo
						</p>
						<p>
							Die Gemeinde (Det Mosaiske Trossamfund) in Trondheim hat mich in ihrer Gemeinde als Kantor angestellt und gebeten, diese Stellung sofort anzutreten.
						</p>
						<p>
							Nach der Mitteilung des Breslauer Königl. Norwegischen Konsulates vom 21.12.1938 und der Gemeinde Trondheim benötige ich zur Einreise- Aufenthalts- und Arbeitserlaubnis Ihre Genehmigung.
						</p>
						<p>
							Meine Personalien: Josef Grabowski geb. 11.5.1911 in Posen.
						</p>
						<p>
							Die personalien meiner Frau Liesbeth geb. Wolff geboren 30.4.1920 in Gleiwitz O/S [Ober-Schlesien]. Da ich vorläufig auf ein Probejahr engagiert bin, bleibt meine Frau in Gleiwitz. Ich bin seit 3 Jahren als Kantor in der Gemeinde Gleiwitz angestellt, nachdem ich vorher in Köln am Rhein und in Berlin-Friedenau in der gleichen Stellung tätig war.
						</p>
						<p>
							Ich bitte ergebenst um Genehmigung meines Gesuches.
						</p>
						<p>
							Mit weiteren evt. Angaben stehe ich jeder zeit gern zur Verfügung.
						</p>
						<p>
							Mit Roksicht darauf, dass ich die Stellung sofort antreten soll, wäre ich für besonders beschleunigte Erledigung verbunden.
						</p>
						<p>
							Mit vorzüglicher Hochachtung <br />
							Josef Grabowski
						</p>
					</div>
					<h2><Trans>Translation</Trans>:</h2>
					<div className="transcript">
						<p>
							<Trans ns={ translation_namespace }>The congregation (The Jewish Community) in Trondheim has employed me as cantor in their congregation and requests that this position is taken up as soon as possible.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>According to information from the Norwegian Royal Consulate in Breslau [= Wroclaw] of December 21st, 1938 and from the congregation in Trondheim I need to obtain an entry and residence permit from you.
My personal information: Josef Grabowski, born May 11th, 1911 in Posen [= Poznan]</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>My wife Liesbeth’s personal information: born Wolff, born April 30th, 1920 in Gleiwitz O/S [= Gliwice]. As I for the time being have been engaged for a trial period of one year, my wife will remain in Gleiwitz. For the past three years, I have been employed as cantor in the congregation in Gleiwitz, and prior to that, I worked in a similar position in Cologne upon Rhein and in Berlin-Friedenau.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>I humbly ask that my application is granted.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>I will be more than happy to oblige you with further details if needed.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Considering the fact that I am to enter into this position as soon as possible, I would be grateful for a rapid treatment.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Sincerely yours,</Trans><br />
							Josef Grabowski
						</p>
					</div>
				</SheetOfPaper>
			</Page>
		</Chapter>
	);
};

export default C05P04;
import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../../i18n';
import { Trans } from "react-i18next";
import { Page, TransparentPage, SheetOfPaper } from "../../../components/Page/Page";
import Chapter from "../../../components/Chapter/Chapter";
import SourceHeader from "../../../components/SourceHeader/SourceHeader";

import routes from './c02_routes.js';
import ENTranscriptPDF from './Transcripts/en/en_c2_p8.pdf';
import NOTranscriptPDF from './Transcripts/no/no_c2_p8.pdf';
import DocumentIMG from './Media/c2_p8.jpg';

const C02P08 = (props) => {
	const { t } = useTranslation();
	const translation_namespace = 'c02_p08';

	return (
		<Chapter pages={routes} chapterName={props.chapterName} currentPageYear={props.year}>
			<Page className="C02P08">
				<TransparentPage>
					<img src={ DocumentIMG } alt={ t('Image of the original document transcribed next') } />
					<ul className="download-links">
						<li><a href={ i18n.language === 'nb-NO' ? NOTranscriptPDF : ENTranscriptPDF } target="_blank"><Trans>Download</Trans> pdf <Trans>file</Trans></a></li>
						<li><a href={DocumentIMG} target="_blank"><Trans>Download</Trans> <Trans>image file</Trans></a></li>
					</ul>
				</TransparentPage>
				<SheetOfPaper>
					<SourceHeader name={ props.name } chapterName={ props.chapterName } />
					<p>
						<strong><Trans ns={ translation_namespace }>Letter to the Central Passport Office from the Jewish Community in Trondheim, dated January 17th, 1941. The Jewish Museum in Trondheim.</Trans></strong>
					</p>
					<h2><Trans>Transcript</Trans>:</h2>
					<div className="transcript">
						<p>
							<Trans ns={ translation_namespace }>January 17th, 1941</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>To the Central Passport Office,</Trans><br />
							Oslo.
						</p>
						<p>
							<Trans ns={ translation_namespace }>Cantor Josef Grabowski is still employed as cantor in the Jewish Community in Trondheim, and in this occasion the community ventures to apply for a residence and work permit for cantor Josef Grabowski for yet another year. The Community hereby declares that it is still bound by the guarantee given on January 31st, 1939 stating that cantor Josef Grabowski will not be a burden to the public as long as he lives in Norway.</Trans>
						</p>
						<p>
							<Trans>Yours faithfully</Trans><br />
							<Trans>for the Jewish Community</Trans><br />
							<Trans>chairman</Trans>
						</p>
					</div>
				</SheetOfPaper>
			</Page>
		</Chapter>
	);
};

export default C02P08;
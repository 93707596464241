import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../../i18n';
import { Trans } from "react-i18next";
import { Page, TransparentPage, SheetOfPaper } from "../../../components/Page/Page";
import Chapter from "../../../components/Chapter/Chapter";
import SourceHeader from "../../../components/SourceHeader/SourceHeader";

import routes from './c02_routes.js';
import ENTranscriptPDF from './Transcripts/en/en_c2_p14.pdf';
import NOTranscriptPDF from './Transcripts/no/no_c2_p14.pdf';
import DocumentIMG from './Media/c2_p14.jpg';

const C02P14 = (props) => {
	const { t } = useTranslation();
	const translation_namespace = 'c02_p14';

	return (
		<Chapter pages={routes} chapterName={props.chapterName} currentPageYear={props.year}>
			<Page className="C02P14">
				<TransparentPage>
					<p><img src={ DocumentIMG } alt={ t('Image of the original document transcribed next') } /></p>
					<ul className="download-links">
						<li><a href={ i18n.language === 'nb-NO' ? NOTranscriptPDF : ENTranscriptPDF } target="_blank"><Trans>Download</Trans> pdf <Trans>file</Trans></a></li>
						<li><a href={DocumentIMG} target="_blank"><Trans>Download</Trans> <Trans>image file</Trans></a></li>
					</ul>
				</TransparentPage>
				<SheetOfPaper>
					<SourceHeader name={ props.name } chapterName={ props.chapterName } />
					<p>
						<strong><Trans ns={ translation_namespace }>Extract of the minutes from a board meeting in the Jewish Community in Trondheim, dated June 1st, 1939. The Jewish Museum in Trondheim.</Trans></strong>
					</p>
					<h2><Trans>Transcript</Trans>:</h2>
					<div className="transcript">
						<p>
							<Trans ns={ translation_namespace }>Board meeting June 1st at the chairman’s home. H. Klein not present.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>An account was given of the reply from the Ministry of Agriculture to our application of April 20th for exemption from the ban on import of fresh meat from Sweden. The Ministry rejects the application.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>An account was given of a letter from Jewish women requesting the right to vote in the congregation. The board has decided to present the case on the first ordinary congregational meeting.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>An account was given of a letter from Trondheim Social Security Office about unemployment benefits. According to this letter, the congregation must pay unemployment benefits of NOK 0.25 per week for Cantor Jakobsohn.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>It was decided upon to grant Cantor Grabowski extra payment as cantor in the congregation. This payment is fixed at NOK 50 per month starting from May 1st. For servicing on important holidays this autumn, additional payment may be granted. The size of this payment will be determined later. (...)</Trans>
						</p>
					</div>
				</SheetOfPaper>
			</Page>
		</Chapter>
	);
};

export default C02P14;
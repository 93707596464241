import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../../i18n';
import { Trans } from "react-i18next";
import { Page, TransparentPage, SheetOfPaper } from "../../../components/Page/Page";
import Chapter from "../../../components/Chapter/Chapter";
import SourceHeader from "../../../components/SourceHeader/SourceHeader";

import routes from './c05_routes.js';
import ENTranscriptPDF from './Transcripts/en/en_c5_p11.pdf';
import NOTranscriptPDF from './Transcripts/no/no_c5_p11.pdf';
import DocumentIMG from './Media/c5_p11.jpg';

const C05P11 = (props) => {
	const { t } = useTranslation();
	const translation_namespace = 'c05_p11';

	return (
		<Chapter pages={routes} chapterName={props.chapterName} currentPageYear={props.year}>
			<Page className="C05P11">
				<TransparentPage>
					<p><img src={ DocumentIMG } alt={ t('Image of the original document transcribed next') } /></p>
					<ul className="download-links">
						<li><a href={ i18n.language === 'nb-NO' ? NOTranscriptPDF : ENTranscriptPDF } target="_blank"><Trans>Download</Trans> pdf <Trans>file</Trans></a></li>
						<li><a href={DocumentIMG} target="_blank"><Trans>Download</Trans> <Trans>image file</Trans></a></li>
					</ul>
				</TransparentPage>
				<SheetOfPaper>
					<SourceHeader name={ props.name } chapterName={ props.chapterName } />
					<p>
						<strong><Trans ns={ translation_namespace }>”Fragebogen” (Personal Information Form) for Liesbeth Grabowski, dated 1939. Journal no. 1941/254 in the Central Passport Office’s archives. The National Archives in Oslo.</Trans></strong>
					</p>
					<h2><Trans>Transcript</Trans>:</h2>
					<div className="transcript">
						<p>
							<Trans ns={ translation_namespace }>Fragebogen</Trans><br />
							<Trans ns={ translation_namespace }>In lateinischer Schrift deutlich auszufüllen.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Arier?</Trans><br />
							<Trans ns={ translation_namespace }>Nichtarier?</Trans>
						</p>
						<ul>
							<li>Familienname: Grabowski geborene Wolff</li>
							<li>Vornamen: Liesbeth</li>
							<li>Beruf: Ehefrau</li>
							<li>Geboren am: 20. April 1920 in: Gleiwitz O/S</li>
							<li>Staatsangehörigkeit: Deutches Reich</li>
							<li>Pass Nr: 628/39</li>
							<li>Ausgestellt von: dem Polizeipräsidenten des oberschlesischen Industrigebietes in Gleiwitz.</li>
							<li>Geltungsbereich des Passes: In- und Ausland</li>
							<li>Grund der reise: Ich fahre zu meinem Ehemann Josef Grabowski nach Trondheim, der dort Aufenthalts- und Arbeiterlaubnis hat und für mich schon einen Antrag in Oslo Einreichte</li>
							<li>Gültigkeitsdauer des Passes: 12. September 1940</li>
							<li>Dauer des Aufenthals in Norwegen: Unbestimmt</li>
							<li>Referenzen in Norwegen: I. „Det Mosaiske Trossamfund“ Trondheim. II. Josef Grabowski Trondheim, Kongens-Gate 84 V c/o Selnaes</li>
							<li>Adresse für Norwegen (für Besuchsreisen): (Trond)</li>
							<li>Sind Sie früher in Norwegen gewesen? -Wann? Nein!</li>
							<li>Einreise in Norwegen – ungefähr am: Sofort nach Erteilung des Einreisevisums</li>
							<li>Unterschrift: Liesbeth Grabowski</li>
							<li>Wohnort und Adresse: Gleiwitz O/S Markgrafenstr. 7</li>
							<li>(in Firma):...</li>
						</ul>
					</div>
					<h2><Trans>Translation</Trans>:</h2>
					<div className="transcript">
						<p>
							<Trans ns={ translation_namespace }>Questionnaire</Trans><br />
							<Trans ns={ translation_namespace }>Please write distinctly using Latin letters.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Aryan?</Trans><br />
							<Trans ns={ translation_namespace }>Non-Aryan?</Trans>
						</p>
						<ul>
							<li><Trans ns={ translation_namespace }>Family name: Grabowski born Wolff</Trans></li>
							<li><Trans ns={ translation_namespace }>First name: Liesbeth</Trans></li>
							<li><Trans ns={ translation_namespace }>Profession: Wife</Trans></li>
							<li><Trans ns={ translation_namespace }>Born: April 20th, 1920 in Gleiwitz O/S</Trans></li>
							<li><Trans ns={ translation_namespace }>Citizenship: The German realm</Trans></li>
							<li><Trans ns={ translation_namespace }>Passport no: 628/39</Trans></li>
							<li><Trans ns={ translation_namespace }>Issued by: The Chief Constable in the industrial area of Gleiwitz in upper Silesia</Trans></li>
							<li><Trans ns={ translation_namespace }>Area of validity of passport: At home and abroad</Trans></li>
							<li><Trans ns={ translation_namespace }>Cause of travel: I am travelling to my husband Josef Grabowski in Trondheim, where he has a residence and work permit. He recently sent an application to Oslo [for residence permit] for me.</Trans></li>
							<li><Trans ns={ translation_namespace }>Passport duration: September 12th, 1940</Trans></li>
							<li><Trans ns={ translation_namespace }>Duration of stay in Norway: Indefinite</Trans></li>
							<li><Trans ns={ translation_namespace }>References in Norway: 1. The Jewish Community, Trondheim. 2. Josef Grabowski , Kongens-gate 84 V c/o Selnæs</Trans></li>
							<li><Trans ns={ translation_namespace }>Address in Norway (only for journeys of visit): (Trond) [Trondheim]</Trans></li>
							<li><Trans ns={ translation_namespace }>Have you been to Norway before? When? No!</Trans></li>
							<li><Trans ns={ translation_namespace }>Entry into Norway – approximately on: As soon as an entry permit is issued.</Trans></li>
							<li><Trans ns={ translation_namespace }>Signature: Liesbeth Grabowski</Trans></li>
							<li><Trans ns={ translation_namespace }>Place and address of residence: O/S Markgrafenstr. 7, Gleiwitz</Trans></li>
							<li><Trans ns={ translation_namespace }>(in Firm):...</Trans></li>
						</ul>
					</div>
				</SheetOfPaper>
			</Page>
		</Chapter>
	);
};

export default C05P11;
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../i18n';
import { isNotNullUndefinedOrEmpty, isNullUndefinedOrEmpty } from '../../utilities';

import './sourceHeader.scss';

const SourceHeader = (props) => {
	const { t } = useTranslation();

	return (
		<div className="SourceHeader">
			<Row>
				<Col xs="12">
					<h2>{ props.name } { isNotNullUndefinedOrEmpty( props.chapterName ) && ( <React.Fragment>- <span className="chapter-name">{ props.chapterName }</span></React.Fragment> ) }</h2>
				</Col>
			</Row>
		</div>
	);
};

SourceHeader.defaultProps = {
	name: '',
	chapterName: '',
}

SourceHeader.propTypes = {
	name: PropTypes.string,
	chapterName: PropTypes.string,
}

export default SourceHeader;
import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../../i18n';
import { Trans } from "react-i18next";
import { Page, TransparentPage, SheetOfPaper } from "../../../components/Page/Page";
import Chapter from "../../../components/Chapter/Chapter";
import SourceHeader from "../../../components/SourceHeader/SourceHeader";

import routes from './c02_routes.js';
import ENTranscriptPDF from './Transcripts/en/en_c2_p7.pdf';
import NOTranscriptPDF from './Transcripts/no/no_c2_p7.pdf';
import DocumentIMG from './Media/c2_p7.jpg';

const C02P07 = (props) => {
	const { t } = useTranslation();
	const translation_namespace = 'c02_p07';

	return (
		<Chapter pages={routes} chapterName={props.chapterName} currentPageYear={props.year}>
			<Page className="C02P07">
				<TransparentPage>
					<img src={ DocumentIMG } alt={ t('Image of the original document transcribed next') } />
					<ul className="download-links">
						<li><a href={ i18n.language === 'nb-NO' ? NOTranscriptPDF : ENTranscriptPDF } target="_blank"><Trans>Download</Trans> pdf <Trans>file</Trans></a></li>
						<li><a href={DocumentIMG} target="_blank"><Trans>Download</Trans> <Trans>image file</Trans></a></li>
					</ul>
				</TransparentPage>
				<SheetOfPaper>
					<SourceHeader name={ props.name } chapterName={ props.chapterName } />
					<p>
						<strong><Trans ns={ translation_namespace }>Extract of a circular to the members of the Jewish Community in Trondheim, dated August 24th, 1941. The Jewish Museum in Trondheim.</Trans></strong>
					</p>
					<h2><Trans>Transcript</Trans>:</h2>
					<div className="transcript">
						<p>
							<Trans>The Jewish Community</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Trondheim August 24th, 1941.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Circular.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>The congregation’s school board hereby informs parents that the teaching will be resumed after the summer holidays, on Thursday August 28th. All children who according to the congregation’s rules and regulations shall or may attend the religious school, will this day meet at ... at cantor Grabowski’s home. The first few times, the teaching will be conducted at cantor Grabowski’s home, later the teaching will probably be moved to a more appropriate school facility. (...)</Trans>
						</p>
					</div>
				</SheetOfPaper>
			</Page>
		</Chapter>
	);
};

export default C02P07;
import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../../i18n';
import { Trans } from "react-i18next";
import { Page, TransparentPage, SheetOfPaper } from "../../../components/Page/Page";
import Chapter from "../../../components/Chapter/Chapter";
import SourceHeader from "../../../components/SourceHeader/SourceHeader";

import routes from './c04_routes.js';
import ENTranscriptPDF from './Transcripts/en/en_c4_p2.pdf';
import NOTranscriptPDF from './Transcripts/no/no_c4_p2.pdf';
import DocumentIMG1 from './Media/c4_p2_m1.jpg';
import DocumentIMG2 from './Media/c4_p2_m2.jpg';

const C04P02 = (props) => {
	const { t } = useTranslation();

	return (
		<Chapter pages={routes} chapterName={props.chapterName} currentPageYear={props.year}>
			<Page className="C04P02">
				<TransparentPage>
					<p><img src={ DocumentIMG1 } alt={ t('Image of the original document transcribed next') } /></p>
					<p><img src={ DocumentIMG2 } alt={ t('Image of the original document transcribed next') } /></p>
					<ul className="download-links">
						<li><a href={ i18n.language === 'nb-NO' ? NOTranscriptPDF : ENTranscriptPDF } target="_blank"><Trans>Download</Trans> pdf <Trans>file</Trans></a></li>
						<li><a href={DocumentIMG1} target="_blank"><Trans>Download</Trans> <Trans>first</Trans> <Trans>image file</Trans></a></li>
						<li><a href={DocumentIMG2} target="_blank"><Trans>Download</Trans> <Trans>second</Trans> <Trans>image file</Trans></a></li>
					</ul>
				</TransparentPage>
				<SheetOfPaper>
					<SourceHeader name={ props.name } chapterName={ props.chapterName } />
					<p><strong><Trans ns="c04_p02">National register card for the Grabowski family, kept in the period 1895-1932. Poznan city archives.</Trans></strong></p>
					<h2><Trans>Transcript</Trans>:</h2>
					<div className="transcript">
						<p>
							Grabowski<br />
							Eltern heissen: Leopold G. [...?] Kempen<br />
							[i hovedfelt til venstre:]<br />
							Familien und Vor-namen:
						</p>
						<ul>
							<li>Simon Grabowski / Stand: Comis. / Geburts-Tag, -Monat, -Jahr: 21.8.78 / Religion: m / Geburts-Ort: Kempen Posen</li>
							<li>Rosa geb. Schäfer / Stand: Frau, / Geburts-Tag, -Monat, -Jahr: 2.4.77 / Religion: mos / Geburts-Ort: Berlin [på linjen under:] smarwa 20.11.32</li>
							<li>Siegfried / Stand: Sohn / Geburts-Tag, -Monat, -Jahr: 25.6.05 / Religion: mos, Geburts-Ort: Posen.</li>
							<li>Leopold / Stand: Sohn / Geburts-Tag, -Monat, -Jahr: 13.12.06 / Religion: mos, Geburts-Ort: Posen</li>
							<li>[på baksiden av kortet:] Miriam / Stand: Sohn [!] / Geburts-Tag, - Monat, -Jahr: 16.2.08 / Religion ms, Geburts-Ort: Posen</li>
							<li>Joseph / Stand: Sohn / Geburts-Tag, -Monat, -Jahr: 11.5.11 / Religion ms, Geburts-Ort: Posen</li>
						</ul>
						<p>
							[i felt til høyre:]<br />
							Persönliche Verhältnisse z.B. bezüglich des Gewerbes u. s. w. 26.10.03 [?] 805<br />
							Wohnung: [...?] [siste oppføring s. 2:] 5.10.07 Gr.Garberstr. 38
						</p>
					</div>
					<h2><Trans>Translation</Trans>:</h2>
					<div className="transcript">
						<p>
							Grabowski<br />
							<Trans>Father’s name</Trans>: Leopold G. [...?] Kempen<br />
							[<Trans>in main text to the left</Trans>:]<br />
							<Trans>Family and first names</Trans>:
						</p>
						<ul>
							<li>Simon Grabowski / <Trans>Position</Trans>: <Trans>Merchant</Trans> / <Trans>Birthday</Trans>, -<Trans>month</Trans>, -<Trans>year</Trans>: <Trans ns="c04_p02">August 21st, 1878</Trans> / <Trans>Religion</Trans>: <Trans>m[osaic]</Trans> / <Trans>Place of birth</Trans>: Kempen Posen [= Poznan]</li>
							<li>Rosa <Trans>born</Trans> Schäfer / <Trans>Position</Trans>: <Trans>Wife</Trans>, / <Trans>Birthday</Trans>, -<Trans>month</Trans>, -<Trans>year</Trans>: <Trans ns="c04_p02">April 2nd, 1877</Trans> / <Trans>Religion</Trans>: <Trans>mos[aic]</Trans> / <Trans>Place of birth</Trans>: Berlin [<Trans>on the line below</Trans>:] <Trans>dead</Trans> <Trans>November 20th, 1932</Trans></li>
							<li>Siegfred / <Trans>Position</Trans>: <Trans>son</Trans> / <Trans>Birthday</Trans>, -<Trans>month</Trans>, -<Trans>year</Trans>: <Trans ns="c04_p02">June 25, 1905</Trans> / <Trans>Religion</Trans>: <Trans>mos</Trans>., <Trans>Place of birth</Trans>: Posen [= Poznan].</li>
							<li>Leopold / <Trans>Position</Trans>: <Trans>son</Trans> / <Trans>Birthday</Trans>, -<Trans>month</Trans>, -<Trans>year</Trans>: <Trans ns="c04_p02">December 13, 1906</Trans> / <Trans>Religion</Trans>: <Trans>mos</Trans>, <Trans>Place of birth</Trans>: Posen</li>
							<li>[<Trans>on the backside of the card</Trans>:] Miriam / <Trans>Position</Trans>: <Trans>son</Trans> [!] / <Trans>Birthday</Trans>, - <Trans>month</Trans>, -<Trans>year</Trans>: <Trans ns="c04_p02">February 16th, 1908</Trans> / <Trans>Religion</Trans>: <Trans>ms</Trans>, <Trans>Place of birth</Trans>: Posen</li>
							<li>Joseph / <Trans>Position</Trans>: <Trans>son</Trans> / <Trans>Birthday</Trans>, -<Trans>month</Trans>, -<Trans>year</Trans>: <Trans ns="c04_p02">May 11, 1911</Trans> / <Trans>Religion</Trans> <Trans>ms</Trans>, <Trans>Place of birth</Trans>: Posen</li>
						</ul>
						<p>
							[<Trans>in field to the right</Trans>:]<br />
							<Trans>Personal circumstances, e.g. regarding profession, etc.</Trans>: <Trans ns="c04_p02">October 26, 1903</Trans> [?] 805<br />
							<Trans>Places of residence</Trans>: [...?] [<Trans>last entry</Trans> <Trans ns="c04_p02">p. 2</Trans>:] <Trans ns="c04_p02">October 5th, 1907</Trans> Grosse Garberstrasse 38
						</p>
					</div>
				</SheetOfPaper>
			</Page>
		</Chapter>
	);
};

export default C04P02;
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    ns: [
      'translation', 
      'c01_p01', 'c01_p02', 'c01_p03', 'c01_p04', 'c01_p05', 'c01_p06', 'c01_p07',
      'c02_p01', 'c02_p02', 'c02_p03', 'c02_p04', 'c02_p05', 'c02_p06', 'c02_p07', 'c02_p08', 'c02_p09', 'c02_p10', 'c02_p11', 'c02_p12', 'c02_p13', 'c02_p14', 'c02_p15', 'c02_p16', 'c02_p17', 'c02_p18', 'c02_p19',
      'c03_p01', 'c03_p02', 'c03_p03', 'c03_p04', 'c03_p05', 'c03_p06', 'c03_p07', 'c03_p08', 'c03_p09', 'c03_p10', 'c03_p11', 'c03_p12', 'c03_p13', 'c03_p14', 'c03_p15', 'c03_p16', 'c03_p17', 'c03_p18', 'c03_p19', 'c03_p20', 'c03_p21', 'c03_p22', 'c03_p23', 'c03_p24', 'c03_p25',
      'c04_p01', 'c04_p02',
      'c05_p01', 'c05_p02', 'c05_p03', 'c05_p04', 'c05_p05', 'c05_p06', 'c05_p07', 'c05_p08', 'c05_p09', 'c05_p10', 'c05_p11', 'c05_p12', 'c05_p13', 'c05_p14', 'c05_p15', 'c05_p16', 'c05_p17', 'c05_p18', 'c05_p19', 'c05_p20',
      'c06_p01',
      'c07_p01', 'c07_p02',
      'timeline_1933', 'timeline_1935', 'timeline_1938', 'timeline_1939', 'timeline_1940', 'timeline_1941', 'timeline_1942', 'timeline_1943', 'timeline_1945'
    ],
    defaultNS: 'translation',
    fallbackLng: 'en',
    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });


export default i18n;
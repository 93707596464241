import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../../i18n';
import { Trans } from "react-i18next";
import { Page, TransparentPage, SheetOfPaper } from "../../../components/Page/Page";
import Chapter from "../../../components/Chapter/Chapter";
import SourceHeader from "../../../components/SourceHeader/SourceHeader";

import routes from './c02_routes.js';
import ENTranscriptPDF from './Transcripts/en/en_c2_p13.pdf';
import NOTranscriptPDF from './Transcripts/no/no_c2_p13.pdf';
import DocumentIMG from './Media/c2_p13.jpg';

const C02P13 = (props) => {
	const { t } = useTranslation();
	const translation_namespace = 'c02_p13';

	return (
		<Chapter pages={routes} chapterName={props.chapterName} currentPageYear={props.year}>
			<Page className="C02P13">
				<TransparentPage>
					<p><img src={ DocumentIMG } alt={ t('Image of the original document transcribed next') } /></p>
					<ul className="download-links">
						<li><a href={ i18n.language === 'nb-NO' ? NOTranscriptPDF : ENTranscriptPDF } target="_blank"><Trans>Download</Trans> pdf <Trans>file</Trans></a></li>
						<li><a href={DocumentIMG} target="_blank"><Trans>Download</Trans> <Trans>image file</Trans></a></li>
					</ul>
				</TransparentPage>
				<SheetOfPaper>
					<SourceHeader name={ props.name } chapterName={ props.chapterName } />
					<p>
						<strong><Trans ns={ translation_namespace }>Extract of the minutes from a board meeting in the Jewish Community, dated January 30th, 1939. The Jewish Museum in Trondheim.</Trans></strong>
					</p>
					<h2><Trans>Transcript</Trans>:</h2>
					<div className="transcript">
						<p>
							<Trans ns={ translation_namespace }>1) Board meeting January 30th. For H. Klein, deputy member 1) H. Abrahamsen attended. An account was given of a letter dated January 25th, 1939 from cantor Leo Grabowski about Josef Grabowski being able to work later for a congregation in St. Francisco. – In this occasion, Mr. Grabowski requests the waiving of further guarantees. -</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>The chairman suggests that Josef Grabowski should receive some payment from the congregation directly. The chairman also suggests waiving any further guarantee requests beyond January 1st, 1940 as an absolute necessity for employment, but that this guarantee should still be sought given by Mr. Grabowski and a person in Copenhagen trusted by us, preferably Mr. merchant Herz.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>– In the further discussion, the chairman gave up the first part of his suggestion that Mr. Grabowski should receive payment directly from the congregation. The withdrawal of the guarantee request is based on a photostatic copy of the contract between Josef Grabowski and the congregation in St. Francisco accompanying the letter from Leo Grabowski, and the fact that we by waiving the guarantee as an absolute necessity for employment, may accelerate the case.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>The board agrees to the chairman’s suggestion which will be the board’s recommendation for the congregational meeting on January 30th. (...)</Trans>
						</p>
					</div>
				</SheetOfPaper>
			</Page>
		</Chapter>
	);
};

export default C02P13;
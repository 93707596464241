import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../../i18n';
import { Trans } from "react-i18next";
import { Page, TransparentPage, SheetOfPaper } from "../../../components/Page/Page";
import Chapter from "../../../components/Chapter/Chapter";
import SourceHeader from "../../../components/SourceHeader/SourceHeader";

import routes from './c01_routes.js';
import ENTranscriptPDF from './Transcripts/en/en_c1_p5.pdf';
import NOTranscriptPDF from './Transcripts/no/no_c1_p5.pdf';
import DocumentIMG from './Media/c1_p5.jpg';

const C01P05 = (props) => {
	const { t } = useTranslation();

	return (
		<Chapter pages={routes} chapterName={props.chapterName} currentPageYear={props.year}>
			<Page className="C01P05">
				<TransparentPage>
					<img src={ DocumentIMG } alt={ t('Image of the original document transcribed next') } />
					<ul className="download-links">
						<li><a href={ i18n.language === 'nb-NO' ? NOTranscriptPDF : ENTranscriptPDF } target="_blank"><Trans>Download</Trans> pdf <Trans>file</Trans></a></li>
						<li><a href={DocumentIMG} target="_blank"><Trans>Download</Trans> <Trans>image file</Trans></a></li>
					</ul>
				</TransparentPage>
				<SheetOfPaper>
					<SourceHeader name={ props.name } chapterName={ props.chapterName } />
					<p><strong><Trans ns="c01_p05">Letter dated 2006 from Harald Haave, son of former Falstad prisoner Magne Haave. The Falstad Centre’s archives, F series.</Trans></strong></p>
					<h2><Trans>Transcript</Trans>:</h2>
					<p><Trans ns="c01_p05">... What I can recall later is connected to what father told us. There were many tragic experiences. We didn’t have a gramophone at home back then, but father still bought a large concert record (78) with music from the operetta The Tsarevitch by Franz Lehar. And here is why: When they were lined up at night in the prison yard, some prisoners were ordered to sing. One night a partisan was commanded, but what the prison guards didn’t know was that this person was an opera singer. So he sang in German, the beautiful and dramatic song Volgalied from The Tsarevitch starting like this: ”Es steht ein Soldat am Volga strand ...” He did this with great empathy and a rich voice. The Germans present were standing in silence and after a while they went inside. Father thought they were ashamed, and he felt this was the only time they ever showed any human disposition ...</Trans></p>
				</SheetOfPaper>
			</Page>
		</Chapter>
	);
};

export default C01P05;
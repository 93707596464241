import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../../i18n';
import { Trans } from "react-i18next";
import { Page, TransparentPage, SheetOfPaper } from "../../../components/Page/Page";
import Chapter from "../../../components/Chapter/Chapter";
import SourceHeader from "../../../components/SourceHeader/SourceHeader";

import routes from './c01_routes.js';
import ENTranscriptPDF from './Transcripts/en/en_c1_p6.pdf';
import NOTranscriptPDF from './Transcripts/no/no_c1_p6.pdf';
import DocumentIMG from './Media/c1_p6.jpg';

const C01P06 = (props) => {
	const { t } = useTranslation();

	return (
		<Chapter pages={routes} chapterName={props.chapterName} currentPageYear={props.year}>
			<Page className="C01P06">
				<TransparentPage>
					<img src={ DocumentIMG } alt={ t('Image of the original document transcribed next') } />
					<ul className="download-links">
						<li><a href={ i18n.language === 'nb-NO' ? NOTranscriptPDF : ENTranscriptPDF } target="_blank"><Trans>Download</Trans> pdf <Trans>file</Trans></a></li>
						<li><a href={DocumentIMG} target="_blank"><Trans>Download</Trans> <Trans>image file</Trans></a></li>
					</ul>
				</TransparentPage>
				<SheetOfPaper>
					<SourceHeader name={ props.name } chapterName={ props.chapterName } />
					<p><strong><Trans ns="c01_p06">Copy of prisoner’s card, the Falstad Centre’s archives. (The originals are kept in the National Archives in Oslo.)</Trans></strong></p>
					<h2><Trans>Transcript</Trans>:</h2>
					<p>
						Name: Grabowski<br />
						Vorname: Josef<br />
						Geburtstag/ort: 11.5.1911 Posen [i dag Poznan] <br />
						Ehefrau bezw Angehörige:...<br />
						Fetsgenommen am:... um...Ort...<br />
						Eingeliefert am: 9.10.42... um... Ort: Falstad <br />
						Festnahmegrund: Jøde<br />
						Sachbearbeiter:...<br />
						Entlassen am:?<br />
						Bezw. Überführt nach...<br />
					</p>
					<h2><Trans>Translation</Trans>:</h2>
					<p>
						<Trans>Name</Trans>: Grabowski<br />
						<Trans>First name</Trans>: Josef<br />
						<Trans>Date of birth/place</Trans>: <Trans ns="c01_p06">May 11 ,1911 Posen [today Poznan]</Trans> <br />
						<Trans>Wife, next of kin respectively</Trans>:...<br />
						<Trans>Arrested on</Trans>:... <Trans ns="c01_p06">at</Trans>...<br />
						<Trans>Admitted on</Trans>: <Trans ns="c01_p06">October 9th, 1942</Trans>... <Trans ns="c01_p06">at</Trans>... <Trans>Place</Trans>: Falstad <br />
						<Trans>Cause of arrest</Trans>: <Trans>Jew</Trans><br />
						<Trans>Executive officer</Trans>:...<br />
						<Trans>Released on</Trans>:?<br />
						<Trans>Respectively transferred to</Trans>:...<br />
					</p>
				</SheetOfPaper>
			</Page>
		</Chapter>
	);
};

export default C01P06;
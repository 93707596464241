import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../../i18n';
import { Trans } from "react-i18next";
import { Page, TransparentPage, SheetOfPaper } from "../../../components/Page/Page";
import Chapter from "../../../components/Chapter/Chapter";
import SourceHeader from "../../../components/SourceHeader/SourceHeader";

import routes from './c05_routes.js';
import ENTranscriptPDF from './Transcripts/en/en_c5_p19.pdf';
import NOTranscriptPDF from './Transcripts/no/no_c5_p19.pdf';
import DocumentIMG from './Media/c5_p19.jpg';

const C05P19 = (props) => {
	const { t } = useTranslation();
	const translation_namespace = 'c05_p19';

	return (
		<Chapter pages={routes} chapterName={props.chapterName} currentPageYear={props.year}>
			<Page className="C05P19">
				<TransparentPage>
					<p><img src={ DocumentIMG } alt={ t('Image of the original document transcribed next') } /></p>
					<ul className="download-links">
						<li><a href={ i18n.language === 'nb-NO' ? NOTranscriptPDF : ENTranscriptPDF } target="_blank"><Trans>Download</Trans> pdf <Trans>file</Trans></a></li>
						<li><a href={DocumentIMG} target="_blank"><Trans>Download</Trans> <Trans>image file</Trans></a></li>
					</ul>
				</TransparentPage>
				<SheetOfPaper>
					<SourceHeader name={ props.name } chapterName={ props.chapterName } />
					<p>
						<strong><Trans ns={ translation_namespace }>List of confiscated property, not dated. The Ministry of Justice’s archives, Hca-E III series – Jewish estates, Nordenfjeldske Department Trondheim, The National Archives.</Trans></strong>
					</p>
					<h2><Trans>Transcript</Trans>:</h2>
					<div className="transcript">
						<p>
							Grabowsky Innherredsveien 9
							<table>
								<tr>
									<td><Trans ns={ translation_namespace }>1. Bookcase (white-wood)</Trans></td>
									<td><Trans ns={ translation_namespace }>NOK.</Trans></td>
									<td>10.00</td>
								</tr>
								<tr>
									<td><Trans ns={ translation_namespace }>1. Pot 1. sugar bowl</Trans></td>
									<td>"</td>
									<td>3.00</td>
								</tr>
								<tr>
									<td><Trans ns={ translation_namespace }>1. Oval table worn</Trans></td>
									<td>"</td>
									<td>20.00</td>
								</tr>
								<tr>
									<td><Trans ns={ translation_namespace }>5. Chairs</Trans></td>
									<td>"</td>
									<td>30.00</td>
								</tr>
								<tr>
									<td><Trans ns={ translation_namespace }>1. Flower vase</Trans></td>
									<td>"</td>
									<td>4.00</td>
								</tr>
								<tr>
									<td><Trans ns={ translation_namespace }>1. Emb. table cloth</Trans></td>
									<td>"</td>
									<td>4.00</td>
								</tr>
								<tr>
									<td><Trans ns={ translation_namespace }>1. Brass tray</Trans></td>
									<td>"</td>
									<td>2.00</td>
								</tr>
								<tr>
									<td><Trans ns={ translation_namespace }>1. Bedside table (unpainted)</Trans></td>
									<td>"</td>
									<td>10.00</td>
								</tr>
								<tr>
									<td><Trans ns={ translation_namespace }>1. Small table</Trans></td>
									<td>"</td>
									<td>4.00</td>
								</tr>
								<tr>
									<td><Trans ns={ translation_namespace }>1. Vase</Trans></td>
									<td>"</td>
									<td>2.00</td>
								</tr>
								<tr>
									<td><Trans ns={ translation_namespace }>2. small do. [=ditto = vases]</Trans></td>
									<td>"</td>
									<td>2.00</td>
								</tr>
								<tr>
									<td><Trans ns={ translation_namespace }>1. Étagère (4 shelves)</Trans></td>
									<td>"</td>
									<td>4.00</td>
								</tr>
								<tr>
									<td><Trans ns={ translation_namespace }>1. l. pot and cream j.</Trans></td>
									<td>"</td>
									<td>2.00</td>
								</tr>
								<tr>
									<td><Trans ns={ translation_namespace }>1. Wardrobe suitcase w/ var. clothes</Trans></td>
									<td>"</td>
									<td>50.00</td>
								</tr>
								<tr>
									<td><Trans ns={ translation_namespace }>1. L. Kitchen table</Trans></td>
									<td>"</td>
									<td>6.00</td>
								</tr>
								<tr>
									<td><Trans ns={ translation_namespace }>2. Spindleback chairs</Trans></td>
									<td>"</td>
									<td>5.00</td>
								</tr>
								<tr>
									<td><Trans ns={ translation_namespace }>1. Case with fabric (pieces, etc.)</Trans></td>
									<td>"</td>
									<td>15.00</td>
								</tr>
								<tr>
									<td><Trans ns={ translation_namespace }>1. Small table cloth</Trans></td>
									<td>"</td>
									<td>3.00</td>
								</tr>
								<tr>
									<td><Trans ns={ translation_namespace }>1. Plated bowl</Trans></td>
									<td>"</td>
									<td>5.00</td>
								</tr>
								<tr>
									<td><Trans ns={ translation_namespace }>Var. kitchen utensils</Trans></td>
									<td>"</td>
									<td>12.00</td>
								</tr>
								<tr>
									<td><Trans ns={ translation_namespace }>2. Hot-plates</Trans></td>
									<td>"</td>
									<td>15.00</td>
								</tr>
								<tr>
									<td><Trans ns={ translation_namespace }>4. Towels</Trans></td>
									<td>"</td>
									<td>2.00</td>
								</tr>
								<tr>
									<td><Trans ns={ translation_namespace }>2. Pairs of curtains</Trans></td>
									<td>"</td>
									<td>16.00</td>
								</tr>
								<tr>
									<td><Trans ns={ translation_namespace }>1. Pair of grey trousers</Trans></td>
									<td>"</td>
									<td>10.00</td>
								</tr>
								<tr>
									<td><Trans ns={ translation_namespace }>2. Hats</Trans></td>
									<td>"</td>
									<td>4.00</td>
								</tr>
								<tr>
									<td><Trans ns={ translation_namespace }>1. Women’s w. coat</Trans></td>
									<td>"</td>
									<td>40.00</td>
								</tr>
								<tr>
									<td><Trans ns={ translation_namespace }>10. P. ass cups</Trans></td>
									<td>"</td>
									<td>5.00</td>
								</tr>
								<tr>
									<td><Trans ns={ translation_namespace }>1. Tea pot</Trans></td>
									<td>"</td>
									<td>1.50</td>
								</tr>
								<tr>
									<td><Trans ns={ translation_namespace }>2. Glasses with stem</Trans></td>
									<td>"</td>
									<td>1.00</td>
								</tr>
								<tr>
									<td><Trans ns={ translation_namespace }>3. Glass bowls</Trans></td>
									<td>"</td>
									<td>1.00</td>
								</tr>
								<tr>
									<td><Trans ns={ translation_namespace }>1. Grey suit</Trans></td>
									<td>"</td>
									<td>70.00</td>
								</tr>
								<tr>
									<td><Trans ns={ translation_namespace }>1. Grey vest and trousers</Trans></td>
									<td>"</td>
									<td>20.00</td>
								</tr>
								<tr>
									<td><Trans ns={ translation_namespace }>1. Pair of Oxford trousers</Trans></td>
									<td>"</td>
									<td>12.00</td>
								</tr>
								<tr>
									<td><Trans ns={ translation_namespace }>1. Black suit</Trans></td>
									<td>"</td>
									<td>65.00</td>
								</tr>
								<tr>
									<td><Trans ns={ translation_namespace }>2. P. of men’s shoes</Trans></td>
									<td>"</td>
									<td>10.00</td>
								</tr>
								<tr>
									<td><Trans ns={ translation_namespace }>1. Blanket</Trans></td>
									<td>"</td>
									<td>10.00</td>
								</tr>
								<tr>
									<td><Trans ns={ translation_namespace }>4. Blinds</Trans></td>
									<td>"</td>
									<td>10.00</td>
								</tr>
								<tr>
									<td></td>
									<td><Trans ns={ translation_namespace }>NOK.</Trans></td>
									<td>485.50</td>
								</tr>
							</table>
						</p>
					</div>
				</SheetOfPaper>
			</Page>
		</Chapter>
	);
};

export default C05P19;
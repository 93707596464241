import React from 'react';
import { i18n } from "../../../i18n.js";

import C06P01 from './C06P01';

const chapterName = "Yad Vashem";

const c06_routes = [
	{
		path: '/chapter/6/page/1',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 1',
		name: 'Source 1',
		element: <C06P01 chapterName={chapterName} name="Source 1" language="en" year="1943" />
	},
	{
		path: '/chapter/6/',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 1',
		name: 'Source 1',
		ignoreInPagelist: true,
		element: <C06P01 chapterName={chapterName} name="Source 1" language="en" year="1943" />
	},
];

export default c06_routes;
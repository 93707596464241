import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import i18n from '../../i18n';
import { isNotNullUndefinedOrEmpty, isNullUndefinedOrEmpty } from '../../utilities';
import { Col, Row, Button } from "reactstrap";
import './LanguageSwitch.scss';

const LanguageSwitch = (props) => {
	const handleLanguageChange = (language) => {
		i18n.changeLanguage(language);
	}

	//{ i18n.language === 'en' ? 'disabled' : '' }

	return (
		<div className="LanguageSwitch">
			<h4>Velg språk/Choose language</h4>
			<Button className="languageSwitcher" onClick={() => handleLanguageChange('nb-NO')}>Norsk</Button>
			<Button className="languageSwitcher" onClick={() => handleLanguageChange('en')}>English</Button>
		</div>
	);
};

LanguageSwitch.defaultProps = {
	pages: [],
}

LanguageSwitch.propTypes = {
	pages: PropTypes.arrayOf(PropTypes.object),
}

export default LanguageSwitch;
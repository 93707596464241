import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../../i18n';
import { Trans } from "react-i18next";
import { Page, TransparentPage, SheetOfPaper } from "../../../components/Page/Page";
import Chapter from "../../../components/Chapter/Chapter";
import SourceHeader from "../../../components/SourceHeader/SourceHeader";

import routes from './c03_routes.js';
import ENTranscriptPDF from './Transcripts/en/en_c3_p24.pdf';
import NOTranscriptPDF from './Transcripts/no/no_c3_p24.pdf';
import DocumentIMG from './Media/c3_p24.jpg';

const C03P24 = (props) => {
	const { t } = useTranslation();
	const translation_namespace = 'c03_p24';

	return (
		<Chapter pages={routes} chapterName={props.chapterName} currentPageYear={props.year}>
			<Page className="C03P24">
				<TransparentPage>
					<p><img src={ DocumentIMG } alt={ t('Image of the original document transcribed next') } /></p>
					<ul className="download-links">
						<li><a href={ i18n.language === 'nb-NO' ? NOTranscriptPDF : ENTranscriptPDF } target="_blank"><Trans>Download</Trans> pdf <Trans>file</Trans></a></li>
						<li><a href={DocumentIMG} target="_blank"><Trans>Download</Trans> <Trans>image file</Trans></a></li>
					</ul>
				</TransparentPage>
				<SheetOfPaper>
					<SourceHeader name={ props.name } chapterName={ props.chapterName } />
					<p>
						<strong><Trans ns={ translation_namespace }>Advance publicity of concert, Adresseavisen April 2nd, 1940. The Regional State Archives in Trondheim.</Trans></strong>
					</p>
					<h2><Trans>Transcript</Trans>:</h2>
					<div className="transcript">
						<p>
							<Trans ns={ translation_namespace }>Great expectations to tonight’s concert.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Yesterday afternoon, the Symphony Orchestra and Nidaros Mandssangforening had in every sense a most promising rehearsal for tonight's concert. As is well known, the programme included Beethoven’s wonderful first symphony and Fridtjov Anderssen’s mighty ”Utferdsdråpa”, a truly dramatic and expressive composition in the Norse spirit.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>The orchestra and the choir alike delivered a performance that gives the best of promises to this night’s concert in the Frimurerlosjen, and the soloist debutant, the tenor J. Grabowski who is a member of Nidaros Mandssangforening, was a very pleasant acquaintance. He has a wonderfully beautiful tenor voice and will be a great and pleasant surprise to the audience tonight.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>It has already been a popular demand for tickets, so it is safe to assume that it will be a joyful closure of the concert season for a full house, and that the audience will be taken by the expressive and moving strength of ”Utferdsdråpa”. Beethoven’s wonderful symphony no. 1 does not need any closer introduction. It is probably the best known and most beloved symphony of this master, and that says it all.</Trans>
						</p>
						<p>
							Oskar Skaug.
						</p>
					</div>
				</SheetOfPaper>
			</Page>
		</Chapter>
	);
};

export default C03P24;
import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../../i18n';
import { Trans } from "react-i18next";
import { Page, TransparentPage, SheetOfPaper } from "../../../components/Page/Page";
import Chapter from "../../../components/Chapter/Chapter";
import SourceHeader from "../../../components/SourceHeader/SourceHeader";

import routes from './c04_routes.js';
import ENTranscriptPDF from './Transcripts/en/en_c4_p1.pdf';
import NOTranscriptPDF from './Transcripts/no/no_c4_p1.pdf';
import DocumentIMG from './Media/c4_p1.jpg';

const C04P01 = (props) => {
	const { t } = useTranslation();

	return (
		<Chapter pages={routes} chapterName={props.chapterName} currentPageYear={props.year}>
			<Page className="C04P01">
				<TransparentPage>
					<img src={ DocumentIMG } alt={ t('Image of the original document transcribed next') } />
					<ul className="download-links">
						<li><a href={ i18n.language === 'nb-NO' ? NOTranscriptPDF : ENTranscriptPDF } target="_blank"><Trans>Download</Trans> pdf <Trans>file</Trans></a></li>
						<li><a href={DocumentIMG} target="_blank"><Trans>Download</Trans> <Trans>image file</Trans></a></li>
					</ul>
				</TransparentPage>
				<SheetOfPaper>
					<SourceHeader name={ props.name } chapterName={ props.chapterName } />
					<p><strong><Trans ns="c04_p01">Marriage certificate for Josef og Liesbeth Grabowski, dated December 14th, 1938. Gliwice Registration Office, Poland.</Trans></strong></p>
					<h2><Trans>Transcript</Trans>:</h2>
					<div className="transcript">
						<p>[merknad i venstre hjørne]Gleiwitz, den 1. Februar 1939. Die Ehefrau führt nach der Verordnung vom 17. August 1938 zusätzlich den Vornahme ”Sara”. Der Standesbeamte: In Vertretung: [signatur]</p>
						<p>[hovedtekst]
							Nr. 902
							Erster Teil
							Gleiwitz, den 14. dezember 1938.
							1. Der Kantor Josef Grabowski, jüdisch, geboren am 11. Mai 1911 in Posen (Standesamt Poznan, Nr 957/1911), wohnhaft in Gleiwitz, Klosterstrasse 4, und Liesbeth Dorothea Wolff, ohne Beruf, jüdisch, geboren am 30. April 1920 in Gleiwitz (Standesamt I Gleiwitz, Nr. 746/1920), wohnhaft in Berlin – Grunewald, Wangenheimstrasse 36 Erschienen heute zum Zwecke der Eheschliessung vor dem unterzeichneten Standesbeamten. Der Standesbeamte fragte sie einzeln und nacheinander, ob sie die Ehe miteinander eingehen wollen. Die Verlobten bejahten die Frage. Der Standesbeamte sprach im Namen des Reiches aus, dass sie nunmehr rechtmässig verbundene Eheleute seien. Als Zeugen waren anwesend:
							der Krankenbehandler Doktor der Medizin Hugo Schlesinger, 70 Jahre alt, wohnhaft in Gleiwitz, Niederwallstrasse 17 ausgewiesen durch Personenausweis
							2. der Kaufmann Erich Wolff 46 Jahre alt, wohnhaft in Gleiwitz, Reichspräsidentenplatz 1 ausgewiesen durch Personenausweis.
						</p>
					</div>
					<h2><Trans>Translation</Trans>:</h2>
					<div className="transcript">
						<p>
							<Trans ns="c04_p01">[note in left-hand corner] st
	Gleiwitz [=Gliwice], February 1 , 1939. Following regulation of August 17th, 1938 the wife also carries the first name of ”Sara”. Acting on behalf of the city judge: [signature]</Trans>
						</p>
						<p>
							<Trans ns="c04_p01">[Main text]
								No. 902
								First part th
								Gleiwitz, on December 14 , 1938. th
								Cantor Josef Grabowski, Jewish, born on May 11 , 1911 in Posen [=Poznan] (City judge in Poznan, no 957/1911), place of residence Klosterstrasse 4, Gleiwitz, and Liesbeth Dorothea Wolff, no profession, Jewish, born on April 30th, 1920 in Gleiwitz (City judge in Gleiwitz no. 746/1920), place of residence Wangenheimstrasse 36 Berlin-Grunewald today presented themselves in the intention of marrying at the undersigned city judge. The city judge asked each of them if they wanted to become the other person’s spouse. The engaged couple replied I do to the question. In the name of the Reich the city judge declared them husband and wife from this moment on. Present as witnesses: doctor Hugo Schlesinger, 70 years old, residing in Niederwallstrasse 17 in Gleiwitz, identified by an identity card [and] merchant Erich Wolff, 46 years old, residing in Reichspräsidentenplatz 1 in Gleiwitz, identified by an identity card.
							</Trans>
						</p>
					</div>
				</SheetOfPaper>
			</Page>
		</Chapter>
	);
};

export default C04P01;
import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from "react-i18next";
import { Button } from "reactstrap";
import { isNotNullUndefinedOrEmpty } from "../../utilities.js"
import './ChapterButton.scss';

const ChapterButton = (props) => {
	const handleClick = () => {
		if(isNotNullUndefinedOrEmpty(props.onClick)){
			props.onClick();
		}
		if(isNotNullUndefinedOrEmpty(props.url)){
			props.onClick();
		}
	}

	return (
		<Button className="ChapterButton" onClick={ handleClick }>
			<div className="page-fold"></div>
			<div className="page-fold-top"></div>
			<div className="page-fold-bottom"></div>
			<span className="title"><Trans>{ props.label }</Trans></span>
			{ props.children }
		</Button>
	);
};

ChapterButton.defaultProps = {
	label: '',
	url: '',
}

ChapterButton.propTypes = {
	label: PropTypes.string,
	url: PropTypes.string,
	onClick: PropTypes.func,
}

export default ChapterButton;
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from "reactstrap";
import Header from '../Header/Header';
import './page.scss';

// Page
export const Page = (props) => {
	return (
		<div className="Page">
			<div className="page-fold"></div>
			<div className="page-fold-top"></div>
			<div className="page-fold-bottom"></div>
			<Row>
				{ props.children }
			</Row>
		</div>
	);
}

Page.defaultProps = {
}

Page.propTypes = {
	/*children: PropTypes.oneOfType([
		PropTypes.transparentPage,
		PropTypes.sheetOfPaper,
	]),*/
}

// Transparent page, used as the left page
export const TransparentPage = (props) => {
	return (
		<Col className="TransparentPage" sm="12" md="6">
			{ props.children }
		</Col>
	);
}

TransparentPage.defaultProps = {
}

TransparentPage.propTypes = {
}

// Sheet of paper on page, used as the right page
export const SheetOfPaper = (props) => {
	return (
		<Col className="SheetOfPaper" sm="12" md="6">
			<div className="paper-border paper-border-top"></div>
			<div className="paper-border paper-border-left"></div>
			{ props.children }
		</Col>
	);
}

SheetOfPaper.defaultProps = {
}

SheetOfPaper.propTypes = {
}
import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../../i18n';
import { Trans } from "react-i18next";
import { Page, TransparentPage, SheetOfPaper } from "../../../components/Page/Page";
import Chapter from "../../../components/Chapter/Chapter";
import SourceHeader from "../../../components/SourceHeader/SourceHeader";

import routes from './c05_routes.js';
import ENTranscriptPDF from './Transcripts/en/en_c5_p17.pdf';
import NOTranscriptPDF from './Transcripts/no/no_c5_p17.pdf';
import DocumentIMG from './Media/c5_p17.jpg';

const C05P17 = (props) => {
	const { t } = useTranslation();
	const translation_namespace = 'c05_p17';

	return (
		<Chapter pages={routes} chapterName={props.chapterName} currentPageYear={props.year}>
			<Page className="C05P17">
				<TransparentPage>
					<p><img src={ DocumentIMG } alt={ t('Image of the original document transcribed next') } /></p>
					<ul className="download-links">
						<li><a href={ i18n.language === 'nb-NO' ? NOTranscriptPDF : ENTranscriptPDF } target="_blank"><Trans>Download</Trans> pdf <Trans>file</Trans></a></li>
						<li><a href={DocumentIMG} target="_blank"><Trans>Download</Trans> <Trans>image file</Trans></a></li>
					</ul>
				</TransparentPage>
				<SheetOfPaper>
					<SourceHeader name={ props.name } chapterName={ props.chapterName } />
					<p>
						<strong><Trans ns={ translation_namespace }>Card for Josef Grabowski in card index of deported Jews, Cej series ”Refugees/deported Jews/books of statistics” in the Central Passport Office’s archives. The National Archives in Oslo.</Trans></strong>
					</p>
					<h2><Trans>Transcript</Trans>:</h2>
					<div className="transcript">
						<p>
							<Trans ns={ translation_namespace }>Trondheim Police Station</Trans><br />
							<Trans ns={ translation_namespace }>The Register of Aliens</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>[in field to the left:]</Trans><br />
							<Trans ns={ translation_namespace }>Name: Grabowski, Josef Israel</Trans><br />
							<Trans ns={ translation_namespace }>Position: Cantor</Trans><br />
							<Trans ns={ translation_namespace }>Born: May 11th, 1911 in Posen</Trans><br />
							<Trans ns={ translation_namespace }>Domicile: Gleiwitz, Germany</Trans><br />
							<Trans ns={ translation_namespace }>Citizenship: German (J.)</Trans><br />
							<Trans ns={ translation_namespace }>Arrived in Norway: May 4th, 1939 from Copenhagen</Trans><br />
							<Trans ns={ translation_namespace }>Place of residence in Norway: ...</Trans><br />
							<Trans ns={ translation_namespace }>Report book issued: May 5th, 1939 by Trondheim p.st. [= police station]</Trans><br />
							<Trans ns={ translation_namespace }>Married to: Lisbeth b. April 30th, 1920</Trans><br />
							<Trans ns={ translation_namespace }>Children under the age of 21 living at home: ...</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>[in field to the right:]</Trans><br />
							<Trans ns={ translation_namespace }>Place of residence (refugees). When. Where:</Trans><br />
							<Trans ns={ translation_namespace }>May 4th, 1939 Bondeheimen, Trondheim</Trans><br />
							<Trans ns={ translation_namespace }>May 4th, 1939 Kongensgt 84 5.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>[written in pencil over both fields:]</Trans><br />
							<Trans ns={ translation_namespace }>Jew Regarded as deceased</Trans>
						</p>
					</div>
				</SheetOfPaper>
			</Page>
		</Chapter>
	);
};

export default C05P17;
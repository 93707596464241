import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../../i18n';
import { Trans } from "react-i18next";
import { Page, TransparentPage, SheetOfPaper } from "../../../components/Page/Page";
import Chapter from "../../../components/Chapter/Chapter";
import SourceHeader from "../../../components/SourceHeader/SourceHeader";

import routes from './c07_routes.js';
import ENTranscriptPDF from './Transcripts/en/en_c7_p1.pdf';
import NOTranscriptPDF from './Transcripts/no/no_c7_p1.pdf';

const C07P01 = (props) => {
	const { t } = useTranslation();
	const translation_namespace = 'c07_p01';

	return (
		<Chapter pages={routes} chapterName={props.chapterName} currentPageYear={props.year}>
			<Page className="C07P01">
				<TransparentPage>
					<ul className="download-links">
						<li><a href={ i18n.language === 'nb-NO' ? NOTranscriptPDF : ENTranscriptPDF } target="_blank"><Trans>Download</Trans> pdf <Trans>file</Trans></a></li>
					</ul>
				</TransparentPage>
				<SheetOfPaper>
					<SourceHeader name={ props.name } chapterName={ props.chapterName } />
					<p>
						<strong><Trans ns={ translation_namespace }>E-mail from the archivist at the Buchenwald Memorial.</Trans></strong>
					</p>
					<h2><Trans>Transcript</Trans>:</h2>
					<div className="transcript">
						<p>
							Aus den bei uns vorhandenen Unterlagen, die sich aus unvollständigem Sammlungsmaterial zusammensetzen, geht hervor, dass Josef Grabowski unter die Häftlingsnummern 27356 als jüdischer Häftlinge im KZ Buchenwald registriert war. Diese Häftlingsnummern wurden nur für diese besondere Verhaftungsaktion vom November 1938, der sog. Kristallnacht, vergeben. Am 12. November 1938 kamen jüdische Männer aus Oppeln nach Buchenwald, unter Ihnen Josef Grabowski. Das Nummernbuch belegt, dass am 29. November 1938 Josef Grabowski als sog. Aktions- Jude aus dem Konzentrationslager Buchenwald entlassen wurde.
						</p>
						<p>
							Mit freundlichen Grüßen,<br />
							NN<br />
							Archiv<br />
						</p>
					</div>
					<h2><Trans>Translation</Trans>:</h2>
					<div className="transcript">
						<p>
							<Trans ns={ translation_namespace }>Based on the available evidence, composed by incomplete source material, Josef Grabowski appears to have been registered as a Jewish prisoner in the concentration camp Buchenwald as prisoner no. 27356. These prisoner numbers were only given during the special arrest action in November 1938, the so-called Night of Broken Glass. On November 12th, Jewish men from Oppeln [ = Opole – the historical capital of Upper Silesia] came to Buchenwald, amongst whom were Josef Grabowski. The entry book documents that Josef Grabowski was released from Buchenwald concentration camp on November 29th, 1938 as a so-called Aktions-Jude [”Action Jew”].</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Sincerely yours,</Trans><br />
							<Trans ns={ translation_namespace }>NN</Trans><br />
							<Trans ns={ translation_namespace }>Archives</Trans>
						</p>
					</div>
				</SheetOfPaper>
			</Page>
		</Chapter>
	);
};

export default C07P01;
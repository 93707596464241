import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../../i18n';
import { Trans } from "react-i18next";
import { Page, TransparentPage, SheetOfPaper } from "../../../components/Page/Page";
import Chapter from "../../../components/Chapter/Chapter";
import SourceHeader from "../../../components/SourceHeader/SourceHeader";

import routes from './c02_routes.js';
import ENTranscriptPDF from './Transcripts/en/en_c2_p19.pdf';
import NOTranscriptPDF from './Transcripts/no/no_c2_p19.pdf';
import DocumentIMG from './Media/c2_p19.jpg';

const C02P19 = (props) => {
	const { t } = useTranslation();
	const translation_namespace = 'c02_p19';

	return (
		<Chapter pages={routes} chapterName={props.chapterName} currentPageYear={props.year}>
			<Page className="C02P19">
				<TransparentPage>
					<p><img src={ DocumentIMG } alt={ t('Image of the original document transcribed next') } /></p>
					<ul className="download-links">
						<li><a href={ i18n.language === 'nb-NO' ? NOTranscriptPDF : ENTranscriptPDF } target="_blank"><Trans>Download</Trans> pdf <Trans>file</Trans></a></li>
						<li><a href={DocumentIMG} target="_blank"><Trans>Download</Trans> <Trans>image file</Trans></a></li>
					</ul>
				</TransparentPage>
				<SheetOfPaper>
					<SourceHeader name={ props.name } chapterName={ props.chapterName } />
					<p>
						<strong><Trans ns={ translation_namespace }>At the family Mendelsohn’s cabin in Lian in Trondheim, spring 1940. Behind from the left: Thora and Aron Mendelsohn, Josef Grabowski, Oscar Mendelsohn with his niece Liv Berit on his shoulders and Marcus Guttman. In the front from the left: Bernard Guttman, Rita and Esther Mendelsohn, Frida Guttman and Lisbeth Grabowski. The Jewish Museum in Trondheim.</Trans></strong>
					</p>
				</SheetOfPaper>
			</Page>
		</Chapter>
	);
};

export default C02P19;
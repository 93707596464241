import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../../i18n';
import { Trans } from "react-i18next";
import { Page, TransparentPage, SheetOfPaper } from "../../../components/Page/Page";
import Chapter from "../../../components/Chapter/Chapter";
import SourceHeader from "../../../components/SourceHeader/SourceHeader";

import routes from './c01_routes.js';
import ENTranscriptPDF from './Transcripts/en/en_c1_p2.pdf';
import NOTranscriptPDF from './Transcripts/no/no_c1_p2.pdf';
import VideoInterviewMP4 from './Media/c1_p2_interview.mp4';
import VideoInterviewWEBM from './Media/c1_p2_interview.webm';

const C01P02 = (props) => {
	const { t } = useTranslation();

	return (
		<Chapter pages={routes} chapterName={props.chapterName} currentPageYear={props.year}>
			<Page className="C01P02">
				<TransparentPage>
					<video width="320" height="240" controls>
						<source src={ VideoInterviewMP4 } type="video/mp4" />
						<source src={ VideoInterviewWEBM } type="video/webm" />
						Your browser does not support the video tag.
					</video>
					<ul className="download-links">
						<li><a href={ i18n.language === 'nb-NO' ? NOTranscriptPDF : ENTranscriptPDF } target="_blank"><Trans>Download</Trans> pdf <Trans>file</Trans></a></li>
						<li><a href={VideoInterviewMP4} target="_blank"><Trans>Download</Trans> <Trans>video file</Trans></a></li>
					</ul>
				</TransparentPage>
				<SheetOfPaper>
					<SourceHeader name={ props.name } chapterName={ props.chapterName } />
					<p><strong><Trans ns="c01_p02">Interview with former Falstad prisoner, Julius Paltiel, 2001, by Frida Krüger. The Falstad Centre’s archives, F series.</Trans></strong></p>
					<h2><Trans>Transcript</Trans>:</h2>
					<p><Trans ns="c01_p02">... The night when Josef Grabowski was ordered to sing some German songs and everybody is lined up and after 15 minutes he had sung those songs, and the SS men were standing completely still – it was dead quiet in Falstad. The weather was lovely, cold, and it is a very special experience standing inside this atrium story and hearing a great singer sing. And then after ... say fifteen minutes or so, this Schaefer standing outside, the watchdog, starts howling over the silence. And I can still hear that howl, because it was totally quiet ... That’s when the SS men woke up and started yelling and shouting. And naturally, we were to blame because we ... for a moment, they had been ”normal”. Because it was so quiet, and that wasn’t normal to them. Of course, they had been thinking about their girlfriends and families and mothers and fathers, for my part ... several of them were quite young ...
	And when that happened, they came, and we were ... we were ordered to shake down the leaves of the big tree standing there, and when we had done that ... we were going to look for some brooms and rakes and spades, because we were to sweep it up. And then they told us no, you are just going to walk over to that corner and lay down on your stomach, and put a leaf in our mouth, that is, use the mouth to pick up the leaf, and then we were going to wriggle across to the other side of the yard, which is some 70–80 metres, and then put the leaf in a pile there, wriggle back and clear the entire yard of leaves. They had hoped that the prisoners would applaud this sight, but they turned their backs on it ...</Trans></p>
				</SheetOfPaper>
			</Page>
		</Chapter>
	);
};

export default C01P02;
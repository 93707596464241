import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../../i18n';
import { Trans } from "react-i18next";
import { Page, TransparentPage, SheetOfPaper } from "../../../components/Page/Page";
import Chapter from "../../../components/Chapter/Chapter";
import SourceHeader from "../../../components/SourceHeader/SourceHeader";

import routes from './c05_routes.js';
import ENTranscriptPDF from './Transcripts/en/en_c5_p13.pdf';
import NOTranscriptPDF from './Transcripts/no/no_c5_p13.pdf';
import DocumentIMG from './Media/c5_p13.jpg';

const C05P13 = (props) => {
	const { t } = useTranslation();
	const translation_namespace = 'c05_p13';

	return (
		<Chapter pages={routes} chapterName={props.chapterName} currentPageYear={props.year}>
			<Page className="C05P13">
				<TransparentPage>
					<p><img src={ DocumentIMG } alt={ t('Image of the original document transcribed next') } /></p>
					<ul className="download-links">
						<li><a href={ i18n.language === 'nb-NO' ? NOTranscriptPDF : ENTranscriptPDF } target="_blank"><Trans>Download</Trans> pdf <Trans>file</Trans></a></li>
						<li><a href={DocumentIMG} target="_blank"><Trans>Download</Trans> <Trans>image file</Trans></a></li>
					</ul>
				</TransparentPage>
				<SheetOfPaper>
					<SourceHeader name={ props.name } chapterName={ props.chapterName } />
					<p>
						<strong><Trans ns={ translation_namespace }>Letter from Josef Grabowski to the Central Passport Office January 19th, 1940. Journal no. 1941/254 in the Central Passport Office’s archives. The National Archives in Oslo.</Trans></strong>
					</p>
					<h2><Trans>Transcript</Trans>:</h2>
					<div className="transcript">
						<p>
							<Trans ns={ translation_namespace }>Trondheim January 19th, 1940.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>To the Central Passport Office,</Trans><br />
							Oslo.
						</p>
						<p>
							<Trans ns={ translation_namespace }>The undersigned Josef Grabowski, German citizen, born May 11th, 1911 in Posen, who is employed as cantor for the Jewish Community in Trondheim, permits himself most honourably to apply the Central Passport Office for a residence and work permit in Norway for one more year, and for the same period for a residence permit for my wife Liesbeth born April 30th, 1920 in Gleiwitz.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>In connection with this application of mine, I permit myself to refer to the correspondence from the Jewish Community of January 12th t.y. [= this year] where the Community guarantees that we will not be a burden to the public during our stay in Norway.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>As I await the honourable reply from the Central Passport Office, I hereby sign</Trans><br />
							<Trans ns={ translation_namespace }>Yours faithfully</Trans><br />
							Josef Grabowski
						</p>
						<p>
							c/o. Josef Grabowski.<br />
							Innherredsv. 9.<br />
							Trondheim.
						</p>
					</div>
				</SheetOfPaper>
			</Page>
		</Chapter>
	);
};

export default C05P13;
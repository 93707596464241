import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../../i18n';
import { Trans } from "react-i18next";
import { Page, TransparentPage, SheetOfPaper } from "../../../components/Page/Page";
import Chapter from "../../../components/Chapter/Chapter";
import SourceHeader from "../../../components/SourceHeader/SourceHeader";

import routes from './c03_routes.js';
import ENTranscriptPDF from './Transcripts/en/en_c3_p22.pdf';
import NOTranscriptPDF from './Transcripts/no/no_c3_p22.pdf';
import DocumentIMG from './Media/c3_p22.jpg';

const C03P22 = (props) => {
	const { t } = useTranslation();
	const translation_namespace = 'c03_p22';

	return (
		<Chapter pages={routes} chapterName={props.chapterName} currentPageYear={props.year}>
			<Page className="C03P22">
				<TransparentPage>
					<p><img src={ DocumentIMG } alt={ t('Image of the original document transcribed next') } /></p>
					<ul className="download-links">
						<li><a href={ i18n.language === 'nb-NO' ? NOTranscriptPDF : ENTranscriptPDF } target="_blank"><Trans>Download</Trans> pdf <Trans>file</Trans></a></li>
						<li><a href={DocumentIMG} target="_blank"><Trans>Download</Trans> <Trans>image file</Trans></a></li>
					</ul>
				</TransparentPage>
				<SheetOfPaper>
					<SourceHeader name={ props.name } chapterName={ props.chapterName } />
					<p>
						<strong><Trans ns={ translation_namespace }>Concert review, Dagsposten April 3rd, 1940. The Regional State Archives in Trondheim.</Trans></strong>
					</p>
					<h2><Trans>Transcript</Trans>:</h2>
					<div className="transcript">
						<p>
							<Trans ns={ translation_namespace }>Orchestral concert</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>It is safe to say that the orchestral concert on Tuesday was a great success! Further more, the evening turned into quite a break-through for the conductor Haakon Hoem. Previously, Hoem has merely given us brief moments of artistic experiences in his conduction; however, last night Hoem practically burst into flames and revealed himself as a thoroughbred artist where the emotional joined hands with the reasonable. Hoem had also acquired more authority and will, be it in Beethoven's classical 1st symphony or Fridthjof Andersen’s romantic work ”Utferdsdråpa” for male voices, soloists and orchestra. The symphony’s strict form was a noticeable progress, an honest will to style and rising. The goodwill of the orchestra was also noticeable, particularly in the surprisingly steady solo parts from both woodwind players, brass instruments and the strings. In Fridthjof Andersen’s expressive work where the orchestra powerfully participates, it was a joy to follow the precise and sonorous performance of Nidaros Mannssangforening. At times, the orchestra may have been too powerful for the choir, but that was probably due to the fact that the instrumentation was too exaggerated. Andersen’s music is lyrically and dramatically emphasized by substantial Scandinavian colours that are reminiscent of Gade and Hartmann. In the solo parts, Egil Nordsjø’s sophisticated and sonorous bass voice was heard as well as the melodious light tenor of J. Grabowski. The latter was somewhat of a surprise.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>The final part of ”Utferdsdråpa” had a moving effect. – A piece of fully- developed music, performed with warmth and life.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Yesterday’s concert was fairly well visited, and the atmosphere in the audience was warm and heartily all night through.</Trans>
						</p>
						<p>
							E.G.
						</p>
					</div>
				</SheetOfPaper>
			</Page>
		</Chapter>
	);
};

export default C03P22;
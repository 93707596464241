import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../../i18n';
import { Trans } from "react-i18next";
import { Page, TransparentPage, SheetOfPaper } from "../../../components/Page/Page";
import Chapter from "../../../components/Chapter/Chapter";
import SourceHeader from "../../../components/SourceHeader/SourceHeader";

import routes from './c05_routes.js';
import ENTranscriptPDF from './Transcripts/en/en_c5_p15.pdf';
import NOTranscriptPDF from './Transcripts/no/no_c5_p15.pdf';
import DocumentIMG from './Media/c5_p15.jpg';

const C05P15 = (props) => {
	const { t } = useTranslation();
	const translation_namespace = 'c05_p15';

	return (
		<Chapter pages={routes} chapterName={props.chapterName} currentPageYear={props.year}>
			<Page className="C05P15">
				<TransparentPage>
					<p><img src={ DocumentIMG } alt={ t('Image of the original document transcribed next') } /></p>
					<ul className="download-links">
						<li><a href={ i18n.language === 'nb-NO' ? NOTranscriptPDF : ENTranscriptPDF } target="_blank"><Trans>Download</Trans> pdf <Trans>file</Trans></a></li>
						<li><a href={DocumentIMG} target="_blank"><Trans>Download</Trans> <Trans>image file</Trans></a></li>
					</ul>
				</TransparentPage>
				<SheetOfPaper>
					<SourceHeader name={ props.name } chapterName={ props.chapterName } />
					<p>
						<strong><Trans ns={ translation_namespace }>Letter from the Jewish Community to the Central Passport Office January 17th, 1941. Journal no. 1941/254 in the Central Passport Office’s archives. The National Archives in Oslo.</Trans></strong>
					</p>
					<h2><Trans>Transcript</Trans>:</h2>
					<div className="transcript">
						<p>
							<Trans ns={ translation_namespace }>The Jewish Community</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Trondheim January 17th, 1941</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>To the Central Passport Office,</Trans><br />
							Oslo.
						</p>
						<p>
							<Trans ns={ translation_namespace }>Cantor Josef Grabowski is still employed as cantor in the Jewish Community in Trondheim, and in this occasion the community ventures to apply for a residence and work permit for cantor Josef Grabowski for yet another year. The Community hereby declares that it is still bound by the guarantee given on January 31st, 1939 stating that cantor Josef Grabowski will not be a burden to the public as long as he lives in Norway.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Yours faithfully</Trans><br />
							<Trans ns={ translation_namespace }>for the Jewish Community</Trans><br />
							Isidor Isaksen<br />
							<Trans ns={ translation_namespace }>chairman</Trans>
						</p>
						<p>
							Oskar Mendelsohn.
						</p>
					</div>
				</SheetOfPaper>
			</Page>
		</Chapter>
	);
};

export default C05P15;
import React from 'react';
import { i18n } from "../../../i18n.js";

import C05P01 from './C05P01';
import C05P02 from './C05P02';
import C05P03 from './C05P03';
import C05P04 from './C05P04';
import C05P05 from './C05P05';
import C05P06 from './C05P06';
import C05P07 from './C05P07';
import C05P08 from './C05P08';
import C05P09 from './C05P09';
import C05P10 from './C05P10';
import C05P11 from './C05P11';
import C05P12 from './C05P12';
import C05P13 from './C05P13';
import C05P14 from './C05P14';
import C05P15 from './C05P15';
import C05P16 from './C05P16';
import C05P17 from './C05P17';
import C05P18 from './C05P18';
import C05P19 from './C05P19';
import C05P20 from './C05P20';

const chapterName = "Buchenwald and Auschwitz";

const c05_routes = [
	{
		path: '/chapter/5/page/1',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 1',
		name: 'Source 1',
		element: <C05P01 chapterName={chapterName} name="Source 1" language="en" year="1938" />
	},
	{
		path: '/chapter/5/page/2',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 2',
		name: 'Source 2',
		element: <C05P02 chapterName={chapterName} name="Source 2" language="en" year="1938" />
	},
	{
		path: '/chapter/5/page/3',
		title: 'Fange nr: 434 - Josef Grabowski - ' + chapterName +' - Source 3',
		name: 'Source 3',
		element: <C05P03 chapterName={chapterName} name="Source 3" language="en" year="1938" />
	},
	{
		path: '/chapter/5/page/4',
		title: 'Fange nr: 434 - Josef Grabowski - ' + chapterName +' - Source 4',
		name: 'Source 4',
		element: <C05P04 chapterName={chapterName} name="Source 4" language="en" year="1939" />
	},
	{
		path: '/chapter/5/page/5',
		title: 'Fange nr: 434 - Josef Grabowski - ' + chapterName +' - Source 5',
		name: 'Source 5',
		element: <C05P05 chapterName={chapterName} name="Source 5" language="en" year="1939" />
	},
	{
		path: '/chapter/5/page/6',
		title: 'Fange nr: 434 - Josef Grabowski - ' + chapterName +' - Source 6',
		name: 'Source 6',
		element: <C05P06 chapterName={chapterName} name="Source 6" language="en" year="1939" />
	},
	{
		path: '/chapter/5/page/7',
		title: 'Fange nr: 434 - Josef Grabowski - ' + chapterName +' - Source 7',
		name: 'Source 7',
		element: <C05P07 chapterName={chapterName} name="Source 7" language="en" year="1939" />
	},
	{
		path: '/chapter/5/page/8',
		title: 'Fange nr: 434 - Josef Grabowski - ' + chapterName +' - Source 8',
		name: 'Source 8',
		element: <C05P08 chapterName={chapterName} name="Source 8" language="en" year="1939" />
	},
	{
		path: '/chapter/5/page/9',
		title: 'Fange nr: 434 - Josef Grabowski - ' + chapterName +' - Source 9',
		name: 'Source 9',
		element: <C05P09 chapterName={chapterName} name="Source 9" language="en" year="1939" />
	},
	{
		path: '/chapter/5/page/10',
		title: 'Fange nr: 434 - Josef Grabowski - ' + chapterName +' - Source 10',
		name: 'Source 10',
		element: <C05P10 chapterName={chapterName} name="Source 10" language="en" year="1939" />
	},
	{
		path: '/chapter/5/page/11',
		title: 'Fange nr: 434 - Josef Grabowski - ' + chapterName +' - Source 11',
		name: 'Source 11',
		element: <C05P11 chapterName={chapterName} name="Source 11" language="en" year="1939" />
	},
	{
		path: '/chapter/5/page/12',
		title: 'Fange nr: 434 - Josef Grabowski - ' + chapterName +' - Source 12',
		name: 'Source 12',
		element: <C05P12 chapterName={chapterName} name="Source 12" language="en" year="1939" />
	},
	{
		path: '/chapter/5/page/13',
		title: 'Fange nr: 434 - Josef Grabowski - ' + chapterName +' - Source 13',
		name: 'Source 13',
		element: <C05P13 chapterName={chapterName} name="Source 13" language="en" year="1940" />
	},
	{
		path: '/chapter/5/page/14',
		title: 'Fange nr: 434 - Josef Grabowski - ' + chapterName +' - Source 14',
		name: 'Source 14',
		element: <C05P14 chapterName={chapterName} name="Source 14" language="en" year="1940" />
	},
	{
		path: '/chapter/5/page/15',
		title: 'Fange nr: 434 - Josef Grabowski - ' + chapterName +' - Source 15',
		name: 'Source 15',
		element: <C05P15 chapterName={chapterName} name="Source 15" language="en" year="1941" />
	},
	{
		path: '/chapter/5/page/16',
		title: 'Fange nr: 434 - Josef Grabowski - ' + chapterName +' - Source 16',
		name: 'Source 16',
		element: <C05P16 chapterName={chapterName} name="Source 16" language="en" year="1941" />
	},
	{
		path: '/chapter/5/page/17',
		title: 'Fange nr: 434 - Josef Grabowski - ' + chapterName +' - Source 17',
		name: 'Source 17',
		element: <C05P17 chapterName={chapterName} name="Source 17" language="en" year="" />
	},
	{
		path: '/chapter/5/page/18',
		title: 'Fange nr: 434 - Josef Grabowski - ' + chapterName +' - Source 18',
		name: 'Source 18',
		element: <C05P18 chapterName={chapterName} name="Source 18" language="en" year="" />
	},
	{
		path: '/chapter/5/page/19',
		title: 'Fange nr: 434 - Josef Grabowski - ' + chapterName +' - Source 19',
		name: 'Source 19',
		element: <C05P19 chapterName={chapterName} name="Source 19" language="en" year="" />
	},
	{
		path: '/chapter/5/page/20',
		title: 'Fange nr: 434 - Josef Grabowski - ' + chapterName +' - Source 20',
		name: 'Source 20',
		element: <C05P20 chapterName={chapterName} name="Source 20" language="en" year="1942" />
	},
	{
		path: '/chapter/5/',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 1',
		name: 'Source 1',
		ignoreInPagelist: true,
		element: <C05P01 chapterName={chapterName} name="Source 1" language="en" year="1938" />
	},
];

export default c05_routes;
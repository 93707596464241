import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from "reactstrap";
//import './pagelist.scss';

const Pagelist = (props) => {
	return (
		<div className="Pagelist">
			<div className="page-list-border"></div>
			<ul>
				{ 
					Array.isArray(props.pages) && props.pages.map((page, index) => {
						return (
							<li key={index}><a href={ page.path }>{ page.name }</a></li>
						);
					})
				}
			</ul>
		</div>
	);
}

Pagelist.defaultProps = {
	pages: [],
}

Pagelist.propTypes = {
	pages: PropTypes.arrayOf(PropTypes.shape({
		path: PropTypes.string,
		name: PropTypes.string,
	})),
}

export default Pagelist;
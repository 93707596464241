import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../../i18n';
import { Trans } from "react-i18next";
import { Page, TransparentPage, SheetOfPaper } from "../../../components/Page/Page";
import Chapter from "../../../components/Chapter/Chapter";
import SourceHeader from "../../../components/SourceHeader/SourceHeader";

import routes from './c02_routes.js';
import ENTranscriptPDF from './Transcripts/en/en_c2_p11.pdf';
import NOTranscriptPDF from './Transcripts/no/no_c2_p11.pdf';
import DocumentIMG from './Media/c2_p11.jpg';

const C02P11 = (props) => {
	const { t } = useTranslation();
	const translation_namespace = 'c02_p11';

	return (
		<Chapter pages={routes} chapterName={props.chapterName} currentPageYear={props.year}>
			<Page className="C02P11">
				<TransparentPage>
					<p><img src={ DocumentIMG } alt={ t('Image of the original document transcribed next') } /></p>
					<ul className="download-links">
						<li><a href={ i18n.language === 'nb-NO' ? NOTranscriptPDF : ENTranscriptPDF } target="_blank"><Trans>Download</Trans> pdf <Trans>file</Trans></a></li>
						<li><a href={DocumentIMG} target="_blank"><Trans>Download</Trans> <Trans>image file</Trans></a></li>
					</ul>
				</TransparentPage>
				<SheetOfPaper>
					<SourceHeader name={ props.name } chapterName={ props.chapterName } />
					<p>
						<strong><Trans ns={ translation_namespace }>Minutes from a board meeting in the Jewish Community in Trondheim, dated December 4th, 1938. The Jewish Museum in Trondheim.</Trans></strong>
					</p>
					<h2><Trans>Transcript</Trans>:</h2>
					<div className="transcript">
						<p>
							<Trans ns={ translation_namespace }>Board meeting December 4th, 1938. In addition to the board, ruler J. Jakobsohn is present and gives an account of a letter from cantor Leo Grabowski in Copenhagen, requesting our community to employ his brother Josef Grabowski, Gleiwitz, as cantor in Trondheim. The congregation will not be put to expenses, since Leo Grabowski will provide the necessary amount for his brother. The board decides to offer Josef Grabowski the position as cantor for the congregation, for the time being for one year, and also decides to apply for an entry, residence and work permit for him. In case the authorities demand a guarantee from the congregation that he will not be a burden to the public, a similar guarantee must be demanded from Leo Grabowski in Copenhagen. The board further decides that Josef Grabowski will be offered a fixed salary of NOK 150 per month, which Leo Grabowski will pay in advance for the first year (NOK 1800).</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>(If it proves difficult for Leo Grabowski to pay for a whole year, the chairman may agree to a payment of 6 months’ pay of NOK 900.) The money will be deposited in a bank book. Further more, Leo Grabowski and merchant Herz in Copenhagen (or someone else trusted by us) will provide guarantees for the future that the pay of NOK 150 per month will be paid us regularly.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Tr.heim December 4th, 1938. Abel L. Bernstein</Trans>
						</p>
						<p>
							Isidor Isaksen, Abel Mendelsohn, M. Guttmann O. Mendelsohn
						</p>
					</div>
				</SheetOfPaper>
			</Page>
		</Chapter>
	);
};

export default C02P11;
import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../../i18n';
import { Trans } from "react-i18next";
import { Page, TransparentPage, SheetOfPaper } from "../../../components/Page/Page";
import Chapter from "../../../components/Chapter/Chapter";
import SourceHeader from "../../../components/SourceHeader/SourceHeader";

import routes from './c05_routes.js';
import ENTranscriptPDF from './Transcripts/en/en_c5_p10.pdf';
import NOTranscriptPDF from './Transcripts/no/no_c5_p10.pdf';
import DocumentIMG from './Media/c5_p10.jpg';

const C05P10 = (props) => {
	const { t } = useTranslation();
	const translation_namespace = 'c05_p10';

	return (
		<Chapter pages={routes} chapterName={props.chapterName} currentPageYear={props.year}>
			<Page className="C05P10">
				<TransparentPage>
					<p><img src={ DocumentIMG } alt={ t('Image of the original document transcribed next') } /></p>
					<ul className="download-links">
						<li><a href={ i18n.language === 'nb-NO' ? NOTranscriptPDF : ENTranscriptPDF } target="_blank"><Trans>Download</Trans> pdf <Trans>file</Trans></a></li>
						<li><a href={DocumentIMG} target="_blank"><Trans>Download</Trans> <Trans>image file</Trans></a></li>
					</ul>
				</TransparentPage>
				<SheetOfPaper>
					<SourceHeader name={ props.name } chapterName={ props.chapterName } />
					<p>
						<strong><Trans ns={ translation_namespace }>Report from the Chief Constable in Trondheim, dated August 28th, 1939. Journal no. 1941/254 in the Central Passport Office’s archives. The National Archives in Oslo.</Trans></strong>
					</p>
					<h2><Trans>Transcript</Trans>:</h2>
					<div className="transcript">
						<p>
							<Trans ns={ translation_namespace }>Trondheim August 28th, 1939.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Report</Trans><br />
							<Trans ns={ translation_namespace }>to the Chief Constable in Trondheim</Trans><br />
							<Trans ns={ translation_namespace }>reported by Police Inspector Dybdal.</Trans>
						</p>
						<p>
							Josef Grabowski<br />
							<Trans ns={ translation_namespace }>Lisbeth Grabowski – entry permit.</Trans><br />
							<Trans ns={ translation_namespace }>Cantor Josef Grabowski, here, presented himself at the police station today, August 28th, 1939 and applied for an entry permit for his wife Lisbeth Grabowski, who is currently staying in Gleiwitz, Germany.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>On February 4th, 1939, Grabowski was granted a work permit from the Central Passport Office to work as cantor in the Jewish Community in Trondheim for the time period up until February 1st, 1940. Grabowski came to Norway via Copenhagen on May 4th, 1939. He has since then stayed in Trondheim as cantor in the Jewish Community. His wife remained in Gleiwitz. At the time, no application for an entry permit was submitted for her. Her parents recently left Gleiwitz for Palestine, and the wife remains alone in Gleiwitz without work and destitute. The conditions in Germany make it impossible for her to live on human terms down there. The couple does not have children.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>On his entry into Norway, Grabowski intended to travel on to the U.S.A. as soon as he was granted the required entry permit. He is still in touch with the American authorities (the Consulate-General in Berlin), but has not yet been given a confirmation as to when an entry permit to America will be granted. An entry permit to America will also include the wife.</Trans>
						</p>
					</div>
				</SheetOfPaper>
			</Page>
		</Chapter>
	);
};

export default C05P10;
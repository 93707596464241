import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../../i18n';
import { Trans } from "react-i18next";
import { Page, TransparentPage, SheetOfPaper } from "../../../components/Page/Page";
import Chapter from "../../../components/Chapter/Chapter";
import SourceHeader from "../../../components/SourceHeader/SourceHeader";

import routes from './c02_routes.js';
import ENTranscriptPDF from './Transcripts/en/en_c2_p16.pdf';
import NOTranscriptPDF from './Transcripts/no/no_c2_p16.pdf';
import DocumentIMG from './Media/c2_p16.jpg';

const C02P16 = (props) => {
	const { t } = useTranslation();
	const translation_namespace = 'c02_p16';

	return (
		<Chapter pages={routes} chapterName={props.chapterName} currentPageYear={props.year}>
			<Page className="C02P16">
				<TransparentPage>
					<p><img src={ DocumentIMG } alt={ t('Image of the original document transcribed next') } /></p>
					<ul className="download-links">
						<li><a href={ i18n.language === 'nb-NO' ? NOTranscriptPDF : ENTranscriptPDF } target="_blank"><Trans>Download</Trans> pdf <Trans>file</Trans></a></li>
						<li><a href={DocumentIMG} target="_blank"><Trans>Download</Trans> <Trans>image file</Trans></a></li>
					</ul>
				</TransparentPage>
				<SheetOfPaper>
					<SourceHeader name={ props.name } chapterName={ props.chapterName } />
					<p>
						<strong><Trans ns={ translation_namespace }>Extract of the minutes from a board meeting in the Jewish Community in Trondheim, dated May 21st, 1941. The Jewish Museum in Trondheim.</Trans></strong>
					</p>
					<h2><Trans>Transcript</Trans>:</h2>
					<div className="transcript">
						<p>
							<Trans ns={ translation_namespace }>Board meeting May 21st, 1941. All board members present.</Trans>
						</p>
						<p>
							(...)
						</p>
						<p>
							<Trans ns={ translation_namespace }>10) An account given of the congregation’s property in Arkitekt Christies gt. 1b taken in use in its entirety on Monday April 21st by the German ”Wehrmacht” without prior written notice to the congregation.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Trondheim May 21st, 1941.</Trans>
						</p>
						<p>
							Isidor Isaksen, Abel L. Bernstein, H. Klein, M. Guttmann, A. Mendelsohn
						</p>
					</div>
				</SheetOfPaper>
			</Page>
		</Chapter>
	);
};

export default C02P16;
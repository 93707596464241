import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../../i18n';
import { Trans } from "react-i18next";
import { Page, TransparentPage, SheetOfPaper } from "../../../components/Page/Page";
import Chapter from "../../../components/Chapter/Chapter";
import SourceHeader from "../../../components/SourceHeader/SourceHeader";

import routes from './c01_routes.js';
import ENTranscriptPDF from './Transcripts/en/en_c1_p1.pdf';
import NOTranscriptPDF from './Transcripts/no/no_c1_p1.pdf';
import AudioInterview from './Media/c1_p1_interview.mp3';

const C01P01 = (props) => {
	const { t } = useTranslation();

	return (
		<Chapter pages={routes} chapterName={props.chapterName} currentPageYear={props.year}>
			<Page className="C01P01">
				<TransparentPage>
					<audio controls>
						<source src={ AudioInterview } type="audio/mpeg" />
						Your browser does not support the audio tag.
					</audio>
					<ul className="download-links">
						<li><a href={ i18n.language === 'nb-NO' ? NOTranscriptPDF : ENTranscriptPDF } target="_blank"><Trans>Download</Trans> pdf <Trans>file</Trans></a></li>
						<li><a href={AudioInterview} target="_blank"><Trans>Download</Trans> <Trans>audio file</Trans></a></li>
					</ul>
				</TransparentPage>
				<SheetOfPaper>
					<SourceHeader name={ props.name } chapterName={ props.chapterName } />
					<p><strong><Trans ns="c01_p01">Interview with former Falstad prisoner Knut Gjørtz, 1995, by Sverre Krüger, the Falstad Centre’s archives, F series.</Trans></strong></p>
					<h2><Trans>Transcript</Trans>:</h2>
					<p><Trans ns="c01_p01">... It must have been around Christmas. He was called forward and stood on the manhole cover, in the middle of the Hof [= the quad]. And we are lined up. It was cold, so it must have been in the winter. For some reason, he was allowed to sing. I think they wanted him to ruin his voice, because of the cold, I guess. Otherwise ... I couldn’t imagine they would give us the pleasure we got. He is standing on the manhole cover. Everyone is lined up in front of him. He is facing us. And then he sings several songs with the most wonderful voice. I don’t know what he was, if he was a singer in that sense or if he was ... had anything to do with the church or anything. But I think I can say that fifty per cent of the prisoners standing there cried while he was singing. It hit us all straight in the heart. Marvellous experience in captivity. It’s difficult to describe because I feel that we quite simply reveal our inmost being, but ... I cried, pure and simple, by listening to the beautiful singing, and then your mind went: What on earth, where are you? You’re in hell in Falstad, and you’re being served something as wonderful as this! Anyway, that was definitely an experience I’ll never forget ...</Trans></p>
				</SheetOfPaper>
			</Page>
		</Chapter>
	);
};

export default C01P01;
import React from 'react';
import { i18n } from "../../../i18n.js";

import C03P01 from './C03P01';
import C03P02 from './C03P02';
import C03P03 from './C03P03';
import C03P04 from './C03P04';
import C03P05 from './C03P05';
import C03P06 from './C03P06';
import C03P07 from './C03P07';
import C03P08 from './C03P08';
import C03P09 from './C03P09';
import C03P10 from './C03P10';
import C03P11 from './C03P11';
import C03P12 from './C03P12';
import C03P13 from './C03P13';
import C03P14 from './C03P14';
import C03P15 from './C03P15';
import C03P16 from './C03P16';
import C03P17 from './C03P17';
import C03P18 from './C03P18';
import C03P19 from './C03P19';
import C03P20 from './C03P20';
import C03P21 from './C03P21';
import C03P22 from './C03P22';
import C03P23 from './C03P23';
import C03P24 from './C03P24';

const chapterName = "The Regional State Archives in Trondheim";

const c03_routes = [
	{
		path: '/chapter/3/page/1',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 1',
		name: 'Source 1',
		element: <C03P01 chapterName={chapterName} name="Source 1" language="en" year="1943" />
	},
	{
		path: '/chapter/3/page/2',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 2',
		name: 'Source 2',
		element: <C03P02 chapterName={chapterName} name="Source 2" language="en" year="1938" />
	},
	{
		path: '/chapter/3/page/3',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 3',
		name: 'Source 3',
		element: <C03P03 chapterName={chapterName} name="Source 3" language="en" year="1938" />
	},
	{
		path: '/chapter/3/page/4',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 4',
		name: 'Source 4',
		element: <C03P04 chapterName={chapterName} name="Source 4" language="en" year="1939" />
	},
	{
		path: '/chapter/3/page/5',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 5',
		name: 'Source 5',
		element: <C03P05 chapterName={chapterName} name="Source 5" language="en" year="1939" />
	},
	{
		path: '/chapter/3/page/6',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 6',
		name: 'Source 6',
		element: <C03P06 chapterName={chapterName} name="Source 6" language="en" year="1939" />
	},
	{
		path: '/chapter/3/page/7',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 7',
		name: 'Source 7',
		element: <C03P07 chapterName={chapterName} name="Source 7" language="en" year="1939" />
	},
	{
		path: '/chapter/3/page/8',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 8',
		name: 'Source 8',
		element: <C03P08 chapterName={chapterName} name="Source 8" language="en" year="1939" />
	},
	{
		path: '/chapter/3/page/9',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 9',
		name: 'Source 9',
		element: <C03P09 chapterName={chapterName} name="Source 9" language="en" year="1939" />
	},
	{
		path: '/chapter/3/page/10',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 10',
		name: 'Source 10',
		element: <C03P10 chapterName={chapterName} name="Source 10" language="en" year="1939" />
	},
	{
		path: '/chapter/3/page/11',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 11',
		name: 'Source 11',
		element: <C03P11 chapterName={chapterName} name="Source 11" language="en" year="1939" />
	},
	{
		path: '/chapter/3/page/12',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 12',
		name: 'Source 12',
		element: <C03P12 chapterName={chapterName} name="Source 12" language="en" year="1939" />
	},
	{
		path: '/chapter/3/page/13',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 13',
		name: 'Source 13',
		element: <C03P13 chapterName={chapterName} name="Source 13" language="en" year="1939" />
	},
	{
		path: '/chapter/3/page/14',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 14',
		name: 'Source 14',
		element: <C03P14 chapterName={chapterName} name="Source 14" language="en" year="1939" />
	},
	{
		path: '/chapter/3/page/15',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 15',
		name: 'Source 15',
		element: <C03P15 chapterName={chapterName} name="Source 15" language="en" year="1939" />
	},
	{
		path: '/chapter/3/page/16',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 16',
		name: 'Source 16',
		element: <C03P16 chapterName={chapterName} name="Source 16" language="en" year="1939" />
	},
	{
		path: '/chapter/3/page/17',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 17',
		name: 'Source 17',
		element: <C03P17 chapterName={chapterName} name="Source 17" language="en" year="1940" />
	},
	{
		path: '/chapter/3/page/18',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 18',
		name: 'Source 18',
		element: <C03P18 chapterName={chapterName} name="Source 18" language="en" year="1940" />
	},
	{
		path: '/chapter/3/page/19',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 19',
		name: 'Source 19',
		element: <C03P19 chapterName={chapterName} name="Source 19" language="en" year="1940" />
	},
	{
		path: '/chapter/3/page/20',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 20',
		name: 'Source 20',
		element: <C03P20 chapterName={chapterName} name="Source 20" language="en" year="1941" />
	},
	{
		path: '/chapter/3/page/21',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 21',
		name: 'Source 21',
		element: <C03P21 chapterName={chapterName} name="Source 21" language="en" year="1940" />
	},
	{
		path: '/chapter/3/page/22',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 22',
		name: 'Source 22',
		element: <C03P22 chapterName={chapterName} name="Source 22" language="en" year="1940" />
	},
	{
		path: '/chapter/3/page/23',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 23',
		name: 'Source 23',
		element: <C03P23 chapterName={chapterName} name="Source 23" language="en" year="1940" />
	},
	{
		path: '/chapter/3/page/24',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 24',
		name: 'Source 24',
		element: <C03P24 chapterName={chapterName} name="Source 24" language="en" year="1940" />
	},
	{
		path: '/chapter/3/',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 1',
		name: 'Source 1',
		ignoreInPagelist: true,
		element: <C03P01 chapterName={chapterName} name="Source 1" language="en" year="1943" />
	},
];

export default c03_routes;
import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../../i18n';
import { Trans } from "react-i18next";
import { Page, TransparentPage, SheetOfPaper } from "../../../components/Page/Page";
import Chapter from "../../../components/Chapter/Chapter";
import SourceHeader from "../../../components/SourceHeader/SourceHeader";

import routes from './c02_routes.js';
import ENTranscriptPDF from './Transcripts/en/en_c2_p15.pdf';
import NOTranscriptPDF from './Transcripts/no/no_c2_p15.pdf';
import DocumentIMG from './Media/c2_p15.jpg';

const C02P15 = (props) => {
	const { t } = useTranslation();
	const translation_namespace = 'c02_p15';

	return (
		<Chapter pages={routes} chapterName={props.chapterName} currentPageYear={props.year}>
			<Page className="C02P15">
				<TransparentPage>
					<p><img src={ DocumentIMG } alt={ t('Image of the original document transcribed next') } /></p>
					<ul className="download-links">
						<li><a href={ i18n.language === 'nb-NO' ? NOTranscriptPDF : ENTranscriptPDF } target="_blank"><Trans>Download</Trans> pdf <Trans>file</Trans></a></li>
						<li><a href={DocumentIMG} target="_blank"><Trans>Download</Trans> <Trans>image file</Trans></a></li>
					</ul>
				</TransparentPage>
				<SheetOfPaper>
					<SourceHeader name={ props.name } chapterName={ props.chapterName } />
					<p>
						<strong><Trans ns={ translation_namespace }>Extract of the minutes from a board meeting in the Jewish Community in Trondheim, dated August 15th, 1939. The Jewish Museum in Trondheim.</Trans></strong>
					</p>
					<h2><Trans>Transcript</Trans>:</h2>
					<div className="transcript">
						<p>
							<Trans ns={ translation_namespace }>Board meeting August 15th at the synagogue. All board members present. Ruler Jacobsohn, summoned, also present.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Cantor Grabowski has informed us that it is practically impossible for his wife to remain in Germany. Cantor Grabowski is unable [to] provide any guarantees. The chairman thinks a guarantee must be demanded if his wife is also to come. In that case, Isaksen is willing to give Grabowski NOK 100 per month. Question as to if he may stay at the synagogue. Here, the question of property tax arises, though. We are currently not paying any property tax.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Ruler Jacobsohn suggests applying for an arrangement with the congregation in Sundsvall, so that they pay our congregation a fixed fee in return for Grabowski or Mr. Jacobsohn being at Sundsvall’s disposal. The board agrees to making attempts to bring about such an arrangement, so that the congregation in Sundsvall pays NOK 1,200 per year to our congregation, and that Mr. Jacobsohn and Mr. Grabowski travel to Sundsvall to negotiate. Travelling expenses will be covered privately. Mr. Isaksen wishes to emphasize that Grabowski must be in Trondheim on the important holidays. (...)</Trans>
						</p>
					</div>
				</SheetOfPaper>
			</Page>
		</Chapter>
	);
};

export default C02P15;
import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../../i18n';
import { Trans } from "react-i18next";
import { Page, TransparentPage, SheetOfPaper } from "../../../components/Page/Page";
import Chapter from "../../../components/Chapter/Chapter";
import SourceHeader from "../../../components/SourceHeader/SourceHeader";

import routes from './c03_routes.js';
import ENTranscriptPDF from './Transcripts/en/en_c3_p23.pdf';
import NOTranscriptPDF from './Transcripts/no/no_c3_p23.pdf';
import DocumentIMG from './Media/c3_p23.jpg';

const C03P23 = (props) => {
	const { t } = useTranslation();
	const translation_namespace = 'c03_p23';

	return (
		<Chapter pages={routes} chapterName={props.chapterName} currentPageYear={props.year}>
			<Page className="C03P23">
				<TransparentPage>
					<p><img src={ DocumentIMG } alt={ t('Image of the original document transcribed next') } /></p>
					<ul className="download-links">
						<li><a href={ i18n.language === 'nb-NO' ? NOTranscriptPDF : ENTranscriptPDF } target="_blank"><Trans>Download</Trans> pdf <Trans>file</Trans></a></li>
						<li><a href={DocumentIMG} target="_blank"><Trans>Download</Trans> <Trans>image file</Trans></a></li>
					</ul>
				</TransparentPage>
				<SheetOfPaper>
					<SourceHeader name={ props.name } chapterName={ props.chapterName } />
					<p>
						<strong><Trans ns={ translation_namespace }>Concert review, Arbeideravisen April 3rd, 1940. The Regional State Archives in Trondheim.</Trans></strong>
					</p>
					<h2><Trans>Transcript</Trans>:</h2>
					<div className="transcript">
						<p>
							<Trans ns={ translation_namespace }>The Symphony Orchestra – Nidaros Mandssangforening.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Yesterday’s concert offered an alliance between Nidaros Mandssangforening and the Symphony Orchestra, both under the direction of conductor Haakon Hoem. Fridthjov Anderssen’s ”Utferdsdraapa” with lyrics of Per Sivle is written for male voices, soloists and orchestra, and it is therefore an expensive affaire for a choir alone to solve this task. I presume it is conductor Hoem who initiated the performance of this significant work of Fridthjov Anderssen, and praise be to him for that.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>The lyrics of ”Utferdsdraapa” are powerful and expressive, and it is possible to say that the music deepens the atmosphere and the images. Yesterday, the choir and the orchestra sounded wonderfully together and created a total effect with both strength and beauty. Egil Nordsjø confirms the good impression we locals have of him as a bass soloist. He delivers broadly and manly. The tenor soloist J. Grabowski was new to our audience. He also delivered in a liberated spirit, but it is difficult to judge this voice after hearing him only once. Though this much can be said; that in him our city has acquired a tenor which will be much used. This piece of work must be heard more often. It is Norwegian through and through, and we could hardly remember Fridhtjov Anderssen in a more beautiful way than by performing his ”Utferdsdraapa”.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Last night’s second work – or strictly speaking the first – was Beethoven's symphony no. 1, which must be one of the master’s works from his youth. The symphony is lively and was all in all performed well, but the grand event of the evening was ”Utferdsdraapa”.</Trans>
						</p>
						<p>
							Oskar Skaug.
						</p>
					</div>
				</SheetOfPaper>
			</Page>
		</Chapter>
	);
};

export default C03P23;
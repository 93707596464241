import React, { useEffect, useState } from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { Col, Row, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Page, TransparentPage, SheetOfPaper } from "../Page/Page";
import Pagelist from "../Pagelist/Pagelist";
import Header from '../Header/Header';
import LanguageSwitch from '../LanguageSwitch/LanguageSwitch';
import Timeline from '../Timeline/Timeline';
import PropTypes from 'prop-types';
import { isNotNullUndefinedOrEmpty, isNullUndefinedOrEmpty } from '../../utilities';
import './TimelineModal.scss';

const TimelineModal = (props) => {
	const { t } = useTranslation();

	const [isOpen, setIsOpen] = useState(false);

	const handleToggle = () =>{
		setIsOpen((prevState) => {
			return !prevState;
		});
	}

	useEffect(() => {
		if(isNotNullUndefinedOrEmpty(props.open)){
			setIsOpen(props.open);
		}
	}, [props.open]);

	useEffect(() => {
		if(!isOpen){
			props.onClose();
		}
	}, [isOpen]);

	return (
		<Modal className="TimelineModal" isOpen={isOpen} toggle={handleToggle} size="lg" centered={true}>
			<div className="modal-border modal-border-top-left"></div>
			<div className="modal-border modal-border-top-right"></div>
			<div className="modal-border modal-border-bottom-left"></div>
			<div className="modal-border modal-border-bottom-right"></div>
			<ModalHeader toggle={handleToggle}></ModalHeader>
			<ModalBody>
				{ props.children }
			</ModalBody>
		</Modal>
	);
};

TimelineModal.defaultProps = {
	pages: [],
	year: null,
	open: null,
}

TimelineModal.propTypes = {
	pages: PropTypes.arrayOf(PropTypes.object),
	year: PropTypes.number,
	open: PropTypes.func,
	onClose: PropTypes.func,
}

export default TimelineModal;
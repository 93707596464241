import { useEffect } from 'react';
import logo from './logo.svg';
import { useTranslation, initReactI18next } from "react-i18next";
import { Routes, Route } from 'react-router-dom';
import routes from './routes';

import './i18n.js';
import './App.scss';

function App() {
  const { t } = useTranslation();

  useEffect(() => {
    const headerHeight = document.querySelector('.Header') ? document.querySelector('.Header').clientHeight : 0;
    const footerHeight = document.querySelector('.Footer') ? document.querySelector('.Footer').clientHeight : 120;
    document.documentElement.style.setProperty('--header-height', `${headerHeight}px`);
    document.documentElement.style.setProperty('--footer-height', `${footerHeight}px`);
  }, []);

  return (
    <div className="App">
      <Routes>
        {routes.map((route, index) => {
          return (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              name={route.name}
              element={route.element}
              chapterName={route.chapterName}
            />
          );
        })}
      </Routes>
    </div>
  );
}

export default App;

import React from 'react';
import Home from './views/Home/Home';
import NotFound from './views/NotFound/NotFound';
import Chapters from './views/Chapters/Chapters';
import TestView from './views/TestView/TestView';

import c01_routes from './views/Chapters/1/c01_routes.js';
import c02_routes from './views/Chapters/2/c02_routes.js';
import c03_routes from './views/Chapters/3/c03_routes.js';
import c04_routes from './views/Chapters/4/c04_routes.js';
import c05_routes from './views/Chapters/5/c05_routes.js';
import c06_routes from './views/Chapters/6/c06_routes.js';
import c07_routes from './views/Chapters/7/c07_routes.js';

const routes = [
	{
		path: '/',
		exact: true,
		title: 'Fange nr: 424 - Josef Grabowski',
		name: 'Home',
		element: <Home />
	},
	{
		path: '*',
		title: 'Not found',
		name: 'NotFound',
		element: <NotFound />
	},
	{
		path: '/chapters',
		title: 'Chapters',
		name: 'Chapters',
		element: <Chapters />
	},
	{
		path: '/testview',
		title: 'TestView',
		name: 'TestView',
		element: <TestView />
	},
	...c01_routes,
	...c02_routes,
	...c03_routes,
	...c04_routes,
	...c05_routes,
	...c06_routes,
	...c07_routes,
];

export default routes;
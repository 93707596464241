import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../i18n';
import { Trans } from "react-i18next";
import { Link, redirect } from "react-router-dom";
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import './chapters.scss';
import ChapterButton from '../../components/ChapterButton/ChapterButton';

const Chapters = (props) => {
	const { t } = useTranslation();

	return (
		<div className="Chapters">
			<Row>
				<Col sm="12">
					<Header />
				</Col>
			</Row>
			<Row>
				<Col sm="12" className="links d-flex flex-wrap justify-content-center align-content-stretch">
					<Link to="/chapter/1/page/1" className="chapter d-flex align-items-center justify-content-center">
						<ChapterButton label={ t('The Falstad Centres archive') } />
					</Link>
					<Link to="/chapter/2/page/1" className="chapter d-flex align-items-center justify-content-center">
						<ChapterButton label={ t('Jewish Museum in Trondheim') } />
					</Link>
					<Link to="/chapter/3/page/1" className="chapter d-flex align-items-center justify-content-center">
						<ChapterButton label={ t('The Regional State Archives in Trondheim') } />
					</Link>
					<Link to="/chapter/4/page/1" className="chapter d-flex align-items-center justify-content-center">
						<ChapterButton label={ t('City Archives of Poznan and Gliwice') } />
					</Link>
					<Link to="/chapter/5/page/1" className="chapter d-flex align-items-center justify-content-center">
						<ChapterButton label={ t('The National Archive in Oslo') } />
					</Link>
					<Link to="/chapter/6/page/1" className="chapter d-flex align-items-center justify-content-center">
						<ChapterButton label={ t('Yad Vashem') } />
					</Link>
					<Link to="/chapter/7/page/1" className="chapter d-flex align-items-center justify-content-center">
						<ChapterButton label={ t('Buchenwald and Auschwitz') } />
					</Link>
				</Col>
			</Row>
			<Row>
				<Col sm="12">
					<Footer />
				</Col>
			</Row>
		</div>
	);
};

export default Chapters;
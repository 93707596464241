import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../../i18n';
import { Trans } from "react-i18next";
import { Page, TransparentPage, SheetOfPaper } from "../../../components/Page/Page";
import Chapter from "../../../components/Chapter/Chapter";
import SourceHeader from "../../../components/SourceHeader/SourceHeader";

import routes from './c05_routes.js';
import ENTranscriptPDF from './Transcripts/en/en_c5_p3.pdf';
import NOTranscriptPDF from './Transcripts/no/no_c5_p3.pdf';
import DocumentIMG from './Media/c5_p3.jpg';

const C05P03 = (props) => {
	const { t } = useTranslation();
	const translation_namespace = 'c05_p03';

	return (
		<Chapter pages={routes} chapterName={props.chapterName} currentPageYear={props.year}>
			<Page className="C05P03">
				<TransparentPage>
					<p><img src={ DocumentIMG } alt={ t('Image of the original document transcribed next') } /></p>
					<ul className="download-links">
						<li><a href={ i18n.language === 'nb-NO' ? NOTranscriptPDF : ENTranscriptPDF } target="_blank"><Trans>Download</Trans> pdf <Trans>file</Trans></a></li>
						<li><a href={DocumentIMG} target="_blank"><Trans>Download</Trans> <Trans>image file</Trans></a></li>
					</ul>
				</TransparentPage>
				<SheetOfPaper>
					<SourceHeader name={ props.name } chapterName={ props.chapterName } />
					<p>
						<strong><Trans ns={ translation_namespace }>Letter from the Jewish Community in Trondheim to the Central Passport Office December 29th, 1938. Journal no. 1941/254 in the Central Passport Office’s archives. The National Archives in Oslo.</Trans></strong>
					</p>
					<h2><Trans>Transcript</Trans>:</h2>
					<div className="transcript">
						<p>
							<Trans ns={ translation_namespace }>The Jewish Community</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Trondheim December 29th, 1938</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>To the Central Passport Office, Oslo,</Trans><br />
							<Trans ns={ translation_namespace }>c/o The Immigration Department, Trondheim Police Station.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Reg. Josef Grabowski</Trans><br />
							<Trans ns={ translation_namespace }>We have received your correspondence dated December 23rd where our application for an entry and residence permit for cantor Josef Grabowski is not granted.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>As it is very important for us and our local congregation to fill this position, and as we have particular interest in Mr. Josef Grabowski, who is a brother of senior cantor Leopold Grabowski in the Jewish Community in Copenhagen, we take the liberty of asking if the issue would be different if the local congregation stands as guarantor for Josef Grabowski, in such a manner that he will not be a burden to the public neither locally nor nationally during his entire stay in Norway.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Similarly, we have privately received such a guarantee from his brother senior cantor Leopold Grabowski, Sølvgt. 95, Copenhagen, as well as a friend of his, merchant Fredrik Hertz, Palægt. 4, Copenhagen. These gentlemen are completely honourable and trustworthy men and very well off.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>We also permit ourselves to point out that the residence permit only applies to Mr. Josef Grabowski in person, as his wife will be staying in Germany.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>We hope that the matter will be viewed in a different light due to these circumstances, and we hope that the application will be granted.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Yours faithfully</Trans><br />
							<Trans ns={ translation_namespace }>In the Board for the Jewish Community</Trans><br />
							Isidor Isaksen<br />
							<Trans ns={ translation_namespace }>at present Chairman</Trans>
						</p>
					</div>
				</SheetOfPaper>
			</Page>
		</Chapter>
	);
};

export default C05P03;
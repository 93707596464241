import React from 'react';
import { i18n } from "../../../i18n.js";

import C04P01 from './C04P01';
import C04P02 from './C04P02';

const chapterName = "City Archives of Poznan and Gliwice";

const c04_routes = [
	{
		path: '/chapter/4/page/1',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 1',
		name: 'Source 1',
		element: <C04P01 chapterName={chapterName} name="Source 1" language="en" year="1938" />
	},
	{
		path: '/chapter/4/page/2',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 2',
		name: 'Source 2',
		element: <C04P02 chapterName={chapterName} name="Source 2" language="en" year="" />
	},
	{
		path: '/chapter/4/',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 1',
		name: 'Source 1',
		ignoreInPagelist: true,
		element: <C04P01 chapterName={chapterName} name="Source 1" language="en" year="1938" />
	},
];

export default c04_routes;
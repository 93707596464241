import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../../i18n';
import { Trans } from "react-i18next";
import { Page, TransparentPage, SheetOfPaper } from "../../../components/Page/Page";
import Chapter from "../../../components/Chapter/Chapter";
import SourceHeader from "../../../components/SourceHeader/SourceHeader";

import routes from './c06_routes.js';
import ENTranscriptPDF from './Transcripts/en/en_c6_p1.pdf';
import NOTranscriptPDF from './Transcripts/no/no_c6_p1.pdf';
import DocumentIMG from './Media/c6_p1.jpg';

const C03P01 = (props) => {
	const { t } = useTranslation();
	const translation_namespace = 'c06_p01';

	return (
		<Chapter pages={routes} chapterName={props.chapterName} currentPageYear={props.year}>
			<Page className="C03P01">
				<TransparentPage>
					<p><img src={ DocumentIMG } alt={ t('Image of the original document transcribed next') } /></p>
					<ul className="download-links">
						<li><a href={ i18n.language === 'nb-NO' ? NOTranscriptPDF : ENTranscriptPDF } target="_blank"><Trans>Download</Trans> pdf <Trans>file</Trans></a></li>
						<li><a href={DocumentIMG} target="_blank"><Trans>Download</Trans> <Trans>image file</Trans></a></li>
					</ul>
				</TransparentPage>
				<SheetOfPaper>
					<SourceHeader name={ props.name } chapterName={ props.chapterName } />
					<p>
						<strong><Trans ns={ translation_namespace }>Transcript of a testimony in Yad Vashem’s database of Holocaust victims.</Trans></strong>
					</p>
					<h2><Trans>Transcript</Trans>:</h2>
					<div className="transcript">
						<p>
							<Trans ns={ translation_namespace }>A Page of Testimony</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Family name: Grabowski</Trans><br />
							<Trans ns={ translation_namespace }>First name:</Trans><br />
							<Trans ns={ translation_namespace }>Place of birth: Posen, Poland (Germany)</Trans><br />
							<Trans ns={ translation_namespace }>Date of birth: May 11th, 1911</Trans><br />
							<Trans ns={ translation_namespace }>Name of mother: ROSA G. SCHÄFFER</Trans><br />
							<Trans ns={ translation_namespace }>Name of father: SCHIMON</Trans><br />
							<Trans ns={ translation_namespace }>Name of spouse: LISBETH G. WOLFF (SHE ALSO DIED IN AUSSCHWITZ)</Trans><br />
							<Trans ns={ translation_namespace }>Place of residence before the war: GLEIWITZ, SCHLESSIEN</Trans><br />
							<Trans ns={ translation_namespace }>Places of residence during the war: CAME TO TRONDHEIM, NORWAY FROM BUCHENWALDE, END OF 1939</Trans><br />
							<Trans ns={ translation_namespace }>Circumstances of death (place, date, etc): AUSSCHWITZ ABOUT MAY 1943</Trans><br />
							<Trans ns={ translation_namespace }>I the undersigned SARAH GRABOWSKI</Trans><br />
							<Trans ns={ translation_namespace }>Residing at (full address): 16, VENDERSGADE, 1363 COPENHAGEN</Trans><br />
							<Trans ns={ translation_namespace }>Relationship to the deceased: SISTER-IN-LAW</Trans><br />
							<Trans ns={ translation_namespace }>Hereby declare that this testimony is correct to the best of my knowledge</Trans><br />
							<Trans ns={ translation_namespace }>Signature: Sarah Grabowski</Trans><br />
							<Trans ns={ translation_namespace }>Place and date: COPENHAGEN, NOV. 26, 1973</Trans>
						</p>
					</div>
				</SheetOfPaper>
			</Page>
		</Chapter>
	);
};

export default C03P01;
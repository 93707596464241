import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../../i18n';
import { Trans } from "react-i18next";
import { Page, TransparentPage, SheetOfPaper } from "../../../components/Page/Page";
import Chapter from "../../../components/Chapter/Chapter";
import SourceHeader from "../../../components/SourceHeader/SourceHeader";

import routes from './c03_routes.js';
import ENTranscriptPDF from './Transcripts/en/en_c3_p18.pdf';
import NOTranscriptPDF from './Transcripts/no/no_c3_p18.pdf';
import DocumentIMG from './Media/c3_p18.jpg';

const C03P18 = (props) => {
	const { t } = useTranslation();
	const translation_namespace = 'c03_p18';

	return (
		<Chapter pages={routes} chapterName={props.chapterName} currentPageYear={props.year}>
			<Page className="C03P18">
				<TransparentPage>
					<p><img src={ DocumentIMG } alt={ t('Image of the original document transcribed next') } /></p>
					<ul className="download-links">
						<li><a href={ i18n.language === 'nb-NO' ? NOTranscriptPDF : ENTranscriptPDF } target="_blank"><Trans>Download</Trans> pdf <Trans>file</Trans></a></li>
						<li><a href={DocumentIMG} target="_blank"><Trans>Download</Trans> <Trans>image file</Trans></a></li>
					</ul>
				</TransparentPage>
				<SheetOfPaper>
					<SourceHeader name={ props.name } chapterName={ props.chapterName } />
					<p>
						<strong><Trans ns={ translation_namespace }>Entry no. 1940/106 in the Police’s immigration journals for 1935– 1942, dated January 19th, 1940. The Regional State Archives in Trondheim.</Trans></strong>
					</p>
					<h2><Trans>Transcript</Trans>:</h2>
					<div className="transcript">
						<p>
							<Trans ns={ translation_namespace }>Josef Grabowski, German citizen, b. May 11th, 1911 in Posen, employed as cantor for the Jewish Community in Trondheim, applies for prolonged residence and work permit here for one year. For the same period, an th application is made for a residence permit for his wife Lisbeth b. April 30 , 1920 in Gleiwitz. He refers to a letter with guarantees from the Jewish Community dated January 12th, 1940. 2 passports included. Letter addressed to the Central Passport Office.</Trans>
						</p>
					</div>
				</SheetOfPaper>
			</Page>
		</Chapter>
	);
};

export default C03P18;
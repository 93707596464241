import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../../i18n';
import { Trans } from "react-i18next";
import { Page, TransparentPage, SheetOfPaper } from "../../../components/Page/Page";
import Chapter from "../../../components/Chapter/Chapter";
import SourceHeader from "../../../components/SourceHeader/SourceHeader";

import routes from './c03_routes.js';
import ENTranscriptPDF from './Transcripts/en/en_c3_p1.pdf';
import NOTranscriptPDF from './Transcripts/no/no_c3_p1.pdf';
import DocumentIMG from './Media/c3_p1.jpg';

const C03P01 = (props) => {
	const { t } = useTranslation();
	const translation_namespace = 'c03_p01';

	return (
		<Chapter pages={routes} chapterName={props.chapterName} currentPageYear={props.year}>
			<Page className="C03P01">
				<TransparentPage>
					<p><img src={ DocumentIMG } alt={ t('Image of the original document transcribed next') } /></p>
					<ul className="download-links">
						<li><a href={ i18n.language === 'nb-NO' ? NOTranscriptPDF : ENTranscriptPDF } target="_blank"><Trans>Download</Trans> pdf <Trans>file</Trans></a></li>
						<li><a href={DocumentIMG} target="_blank"><Trans>Download</Trans> <Trans>image file</Trans></a></li>
					</ul>
				</TransparentPage>
				<SheetOfPaper>
					<SourceHeader name={ props.name } chapterName={ props.chapterName } />
					<p>
						<strong><Trans ns={ translation_namespace }>Card from Trondheim Registration Office’s card index of deceased 1943. The Regional State Archives in Trondheim.</Trans></strong>
					</p>
					<h2><Trans>Transcript</Trans>:</h2>
					<div className="transcript">
						<p>
							Grabowski, Josef Israel
						</p>
						<p>
							<Trans ns={ translation_namespace }>[in left-hand corner]</Trans><br />
							<Trans ns={ translation_namespace }>Fld. d. [Filled in date]: May 10th, 1939</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>[in right-hand corner]</Trans><br />
							<Trans ns={ translation_namespace }>Both Dead 19 ? (1943?) in German captivity (imm. dept. inf.)</Trans><br />
							<Trans ns={ translation_namespace }>[immigration department information]</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>[personal information to the left]</Trans><br />
							<Trans ns={ translation_namespace }>Male</Trans>:<br />
							<Trans ns={ translation_namespace }>Name: Grabowski, Josef</Trans><br />
							<Trans ns={ translation_namespace }>Year and date of birth: 1911, on May 11th</Trans><br />
							<Trans ns={ translation_namespace }>Place of birth: Posen</Trans><br />
							<Trans ns={ translation_namespace }>Position in life (profession): cantor</Trans><br />
							<Trans ns={ translation_namespace }>Marital status:</Trans><br />
							<Trans ns={ translation_namespace }>Citizenship: German</Trans><br />
							<Trans ns={ translation_namespace }>Moved to Tr.heim on: May 4th, 1939</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Female</Trans>:<br />
							<Trans ns={ translation_namespace }>Name: Lisbeth</Trans><br />
							<Trans ns={ translation_namespace }>Year and date of birth: 1920, on April 30th</Trans><br />
							<Trans ns={ translation_namespace }>Place of birth: Gleiwitz, Germany</Trans><br />
							<Trans ns={ translation_namespace }>Position in life (profession): wife</Trans><br />
							<Trans ns={ translation_namespace }>Marital status: m [married]</Trans><br />
							<Trans ns={ translation_namespace }>Citizenship: German</Trans><br />
							<Trans ns={ translation_namespace }>Moved to Tr.heim on: November 5th, 1939</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>[information on residence to the right]</Trans><br />
							<Trans ns={ translation_namespace }>Moved when and where:</Trans><br />
							<Trans ns={ translation_namespace }>Year: 39 / Date: - / Street: from Denmark / Note: P</Trans><br />
							<Trans ns={ translation_namespace }>Year 39 / Date: May 4th / Street: Kongens gt / No: 84 / Floor: V / Note: Selnes</Trans><br />
							<Trans ns={ translation_namespace }>Year 39 / Date: November 5th / Street: Innh. veien / No: 9 / Floor: IV / Note: for both</Trans><br />
							<Trans ns={ translation_namespace }>Year 1942 / Date: November 5th / Street: Museumsplass / No: 3 / Floor: II / Note: Klein</Trans>
						</p>
					</div>
				</SheetOfPaper>
			</Page>
		</Chapter>
	);
};

export default C03P01;
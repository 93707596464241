import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../../i18n';
import { Trans } from "react-i18next";
import { Page, TransparentPage, SheetOfPaper } from "../../../components/Page/Page";
import Chapter from "../../../components/Chapter/Chapter";
import SourceHeader from "../../../components/SourceHeader/SourceHeader";

import routes from './c05_routes.js';
import ENTranscriptPDF from './Transcripts/en/en_c5_p2.pdf';
import NOTranscriptPDF from './Transcripts/no/no_c5_p2.pdf';
import DocumentIMG from './Media/c5_p2.jpg';

const C05P02 = (props) => {
	const { t } = useTranslation();
	const translation_namespace = 'c05_p02';

	return (
		<Chapter pages={routes} chapterName={props.chapterName} currentPageYear={props.year}>
			<Page className="C05P02">
				<TransparentPage>
					<p><img src={ DocumentIMG } alt={ t('Image of the original document transcribed next') } /></p>
					<ul className="download-links">
						<li><a href={ i18n.language === 'nb-NO' ? NOTranscriptPDF : ENTranscriptPDF } target="_blank"><Trans>Download</Trans> pdf <Trans>file</Trans></a></li>
						<li><a href={DocumentIMG} target="_blank"><Trans>Download</Trans> <Trans>image file</Trans></a></li>
					</ul>
				</TransparentPage>
				<SheetOfPaper>
					<SourceHeader name={ props.name } chapterName={ props.chapterName } />
					<p>
						<strong><Trans ns={ translation_namespace }>Letter from Trondheim Police Station to the Central Passport Office December 20th, 1938. Journal no. 1941/254 in the Central Passport Office’s archives. The National Archives in Oslo.</Trans></strong>
					</p>
					<h2><Trans>Transcript</Trans>:</h2>
					<div className="transcript">
						<p>
							<Trans ns={ translation_namespace }>To be sent to the Central Passport Office, Oslo.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>The Chairman of the Board of the Jewish Community, merchant Isidor Isaksen, here, informes on inquiry that Grabowski in any case would be employed for the time being as cantor for the congregation in Trondheim for a period of one year. As NOK 150 per month will be too little to live of, the board has decided that Grabowski will also receive some payment in kind. Merchant Isaksen cannot give a clear statement about a possible unconditional guarantee that Grabowski will not be a burden to the public. This question will have to be discussed by the board. Grabowski, who is recently married, has no other family than his wife.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>TRONDHEIM POLICE STATION, December 20th, 1938.</Trans><br />
							<Trans ns={ translation_namespace }>For Chief Constable CM Frøseth</Trans><br />
							<Trans ns={ translation_namespace }>Cop. Jan Dybdal</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>J.no. 5366-38</Trans><br />
							<Trans ns={ translation_namespace }>Residence Permit – Josef Grabowski</Trans><br />
							<Trans ns={ translation_namespace }>To be returned to Mr. Chief Constable in Trondheim.</Trans><br />
							<Trans ns={ translation_namespace }>Based on the presented information, the Central Passport Office does not find reason to agree to an entry and residence permit for the German citizen Josef Grabowski.</Trans><br />
							<Trans ns={ translation_namespace }>The Central Passport Office, Oslo December 21 , 1938.</Trans><br />
							<Trans ns={ translation_namespace }>[signature]</Trans>
						</p>
					</div>
				</SheetOfPaper>
			</Page>
		</Chapter>
	);
};

export default C05P02;
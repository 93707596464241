import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../../i18n';
import { Trans } from "react-i18next";
import { Page, TransparentPage, SheetOfPaper } from "../../../components/Page/Page";
import Chapter from "../../../components/Chapter/Chapter";
import SourceHeader from "../../../components/SourceHeader/SourceHeader";

import routes from './c01_routes.js';
import ENTranscriptPDF from './Transcripts/en/en_c1_p7.pdf';
import NOTranscriptPDF from './Transcripts/no/no_c1_p7.pdf';
import DocumentIMG from './Media/c1_p7.jpg';

const C01P07 = (props) => {
	const { t } = useTranslation();

	return (
		<Chapter pages={routes} chapterName={props.chapterName} currentPageYear={props.year}>
			<Page className="C01P07">
				<TransparentPage>
					<img src={ DocumentIMG } alt={ t('Image of the original document transcribed next') } />
					<ul className="download-links">
						<li><a href={ i18n.language === 'nb-NO' ? NOTranscriptPDF : ENTranscriptPDF } target="_blank"><Trans>Download</Trans> pdf <Trans>file</Trans></a></li>
						<li><a href={DocumentIMG} target="_blank"><Trans>Download</Trans> <Trans>image file</Trans></a></li>
					</ul>
				</TransparentPage>
				<SheetOfPaper>
					<SourceHeader name={ props.name } chapterName={ props.chapterName } />
					<p><strong><Trans ns="c01_p07">Copy of page in prisoner protocol from the SS Strafgefangenenlager Falstad October 8th, 1942. The Falstad Centre’s archives, M1.102. (The original is kept in the National Archives in Oslo).</Trans></strong></p>
					<h2><Trans>Transcript</Trans>:</h2>
					<p>
						<table>
							<tr>
								<th><Trans>Prisoner no</Trans></th>
								<th><Trans>Last name</Trans></th> 
								<th><Trans>First name</Trans></th>
								<th><Trans>Category</Trans></th>
							</tr>
							<tr>
								<td>420</td>
								<td>Dvoretsky</td> 
								<td>Sigurd...</td>
								<td><Trans>yellow</Trans> 1</td>
							</tr>
							<tr>
								<td>421</td>
								<td>Dvorsky</td> 
								<td>David...</td>
								<td>"</td>
							</tr>
							<tr>
								<td>422</td>
								<td>Dvorsky</td> 
								<td>Selig</td>
								<td>"</td>
							</tr>
							<tr>
								<td>423</td>
								<td>Glick</td> 
								<td>Calman</td>
								<td>"</td>
							</tr>
							<tr>
								<td>424</td>
								<td>Grabowski</td> 
								<td>Josef</td>
								<td>"</td>
							</tr>
							<tr>
								<td>425</td>
								<td>Gurewitz</td> 
								<td>Josef</td>
								<td>"</td>
							</tr>
							<tr>
								<td>426</td>
								<td>Gurewitz</td> 
								<td>Michael</td>
								<td>"</td>
							</tr>
							<tr>
								<td>427</td>
								<td>Hirsch</td> 
								<td>Asriel Berl</td>
								<td>"</td>
							</tr>
							<tr>
								<td>428</td>
								<td>Hirsch</td> 
								<td>Assor</td>
								<td>"</td>
							</tr>
							<tr>
								<td>429</td>
								<td>Hirsch</td> 
								<td>Leon</td>
								<td>"</td>
							</tr>
							<tr>
								<td>430</td>
								<td>Hirsch</td> 
								<td>Ruben</td>
								<td>"</td>
							</tr>
							<tr>
								<td>431</td>
								<td>Hirsch</td> 
								<td>Chaim</td>
								<td>"</td>
							</tr>
							<tr>
								<td>432</td>
								<td>Jacobsen</td> 
								<td>Herman</td>
								<td>"</td>
							</tr>
							<tr>
								<td>433</td>
								<td>Jacobsen</td> 
								<td>Paltiel</td>
								<td>"</td>
							</tr>
							<tr>
								<td>434</td>
								<td>Klein</td> 
								<td>Abraham</td>
								<td>"</td>
							</tr>
							<tr>
								<td>435</td>
								<td>Klein</td> 
								<td>Wulf</td>
								<td>"</td>
							</tr>
							<tr>
								<td>436</td>
								<td>Lankelinsky</td> 
								<td>Isak</td>
								<td>"</td>
							</tr>
							<tr>
								<td>437</td>
								<td>Lankelinsky</td> 
								<td>Jacob</td>
								<td>"</td>
							</tr>
							<tr>
								<td>438</td>
								<td>Levin</td> 
								<td>Herman</td>
								<td>"</td>
							</tr>
							<tr>
								<td>439</td>
								<td>Maliniak</td> 
								<td>Jack</td>
								<td>"</td>
							</tr>
							<tr>
								<td>440</td>
								<td>Mendelsohn</td> 
								<td>Aron</td>
								<td>"</td>
							</tr>
							<tr>
								<td>441</td>
								<td>Paltiel</td> 
								<td>Julius</td>
								<td>"</td>
							</tr>
							<tr>
								<td>442</td>
								<td>Phillipsohn</td> 
								<td>Abraham</td>
								<td>"</td>
							</tr>
							<tr>
								<td>443</td>
								<td>Rosenberg</td> 
								<td></td>
								<td>"</td>
							</tr>
							<tr>
								<td>444</td>
								<td>Rosenberg</td> 
								<td>Charles</td>
								<td>"</td>
							</tr>
							<tr>
								<td>445</td>
								<td>Schidorsky</td> 
								<td>Herman</td>
								<td>"</td>
							</tr>
						</table>
					</p>
				</SheetOfPaper>
			</Page>
		</Chapter>
	);
};

export default C01P07;
import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../../i18n';
import { Trans } from "react-i18next";
import { Page, TransparentPage, SheetOfPaper } from "../../../components/Page/Page";
import Chapter from "../../../components/Chapter/Chapter";
import SourceHeader from "../../../components/SourceHeader/SourceHeader";

import routes from './c01_routes.js';
import ENTranscriptPDF from './Transcripts/en/en_c1_p4.pdf';
import NOTranscriptPDF from './Transcripts/no/no_c1_p4.pdf';
import DocumentIMG from './Media/c1_p4.jpg';

const C01P04 = (props) => {
	const { t } = useTranslation();

	return (
		<Chapter pages={routes} chapterName={props.chapterName} currentPageYear={props.year}>
			<Page className="C01P04">
				<TransparentPage>
					<img src={ DocumentIMG } alt={ t('Image of the original document transcribed next') } />
					<ul className="download-links">
						<li><a href={ i18n.language === 'nb-NO' ? NOTranscriptPDF : ENTranscriptPDF } target="_blank"><Trans>Download</Trans> pdf <Trans>file</Trans></a></li>
						<li><a href={DocumentIMG} target="_blank"><Trans>Download</Trans> <Trans>image file</Trans></a></li>
					</ul>
				</TransparentPage>
				<SheetOfPaper>
					<SourceHeader name={ props.name } chapterName={ props.chapterName } />
					<p><strong><Trans ns="c01_p04">The German SS camp Falstad, Ekne in North-Trøndelag. The picture was taken at the liberation in 1945. Photo: The Falstad Centre</Trans></strong></p>
				</SheetOfPaper>
			</Page>
		</Chapter>
	);
};

export default C01P04;
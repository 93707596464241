import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../../i18n';
import { Trans } from "react-i18next";
import { Page, TransparentPage, SheetOfPaper } from "../../../components/Page/Page";
import Chapter from "../../../components/Chapter/Chapter";
import SourceHeader from "../../../components/SourceHeader/SourceHeader";

import routes from './c05_routes.js';
import ENTranscriptPDF from './Transcripts/en/en_c5_p20.pdf';
import NOTranscriptPDF from './Transcripts/no/no_c5_p20.pdf';
import DocumentIMG from './Media/c5_p20.jpg';

const C05P20 = (props) => {
	const { t } = useTranslation();
	const translation_namespace = 'c05_p20';

	return (
		<Chapter pages={routes} chapterName={props.chapterName} currentPageYear={props.year}>
			<Page className="C05P20">
				<TransparentPage>
					<p><img src={ DocumentIMG } alt={ t('Image of the original document transcribed next') } /></p>
					<ul className="download-links">
						<li><a href={ i18n.language === 'nb-NO' ? NOTranscriptPDF : ENTranscriptPDF } target="_blank"><Trans>Download</Trans> pdf <Trans>file</Trans></a></li>
						<li><a href={DocumentIMG} target="_blank"><Trans>Download</Trans> <Trans>image file</Trans></a></li>
					</ul>
				</TransparentPage>
				<SheetOfPaper>
					<SourceHeader name={ props.name } chapterName={ props.chapterName } />
					<p>
						<strong><Trans ns={ translation_namespace }>Confiscation document, dated October 19th, 1942. The Ministry of Justice’s archives, Hca-E III series – Jewish estates, Nordenfjeldske Department Trondheim, The National Archives.</Trans></strong>
					</p>
					<h2><Trans>Transcript</Trans>:</h2>
					<div className="transcript">
						<p>
							Der Kommandeur<br />
							der Sicherheitspolizei und des SD<br />
							- Drontheim -
						</p>
						<p>
							Beschlagnahmeverfügung
						</p>
						<p>
							Auf einen Erlass des Höheren SS- und Polizeiführers Nord wird hiermit das gesamte Vermögen des Josef Grabowski, Drontheim Innherredsv. 9 mit Wirkung vom 6.10.42 beschlagnahmt und eingezogen.
						</p>
						<p>
							Diese Verfügung kann durch Rechtsmittel nicht angefechten werden.
						</p>
						<p>
							Verstösse gegen diese Beschlagnahmeverfügung werden sicherheitspolixeilich geahndet.
						</p>
						<p>
							Drontheim, den 19. Oktober 1942.
						</p>
						<p>
							SS Sturmbannführer [Flesch]
						</p>
					</div>
					<h2><Trans>Translation</Trans>:</h2>
					<div className="transcript">
						<p>
							<Trans ns={ translation_namespace }>Commandor of</Trans><br />
							<Trans ns={ translation_namespace }>the Security Police and the Security Service [KdS]</Trans><br />
							- Trondheim -
						</p>
						<p>
							<Trans ns={ translation_namespace }>Order of Confiscation</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>By order of SS and Police Commander Nord, the entire property of Josef Grabowski, Innherredsv. 9, Trondheim, is hereby confiscated and seized taking effect on October 6th, 1942.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>This decision cannot be challenged by legal remedies.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Infringements of this order of confiscation will be punished by the Security Police.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Trondheim, October 19th, 1942.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>SS Storm Troop Commander [Flesch]</Trans>
						</p>
					</div>
				</SheetOfPaper>
			</Page>
		</Chapter>
	);
};

export default C05P20;
import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Container, Col, Row, Button } from "reactstrap";
import i18n from '../../i18n';
import { redirect, useNavigate } from "react-router-dom";
import Header from '../../components/Header/Header';
import ChapterButton from '../../components/ChapterButton/ChapterButton';
import './home.scss';

import FlagUK from '../../images/flag_united_kingdom.jpg';
import FlagNorway from '../../images/flag_norway.jpg';

const Home = (props) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const handleLanguageChange = (language) => {
		i18n.changeLanguage(language);

		navigate("/chapters");
	}

	return (
		<div className="Home">
			<Header />
			<main className="main-content">
				<Container>
					<Row className="justify-content-center">
						<Col xs="12" sm="6" lg="6">
							<h2>Velg språk/Choose language</h2>
							<div className="languageButtons">
								<ChapterButton label="Norsk" onClick={() => handleLanguageChange('nb-NO')}>
									<img src={FlagNorway} alt={ t('Norwegian Flag') } />
								</ChapterButton>
								<ChapterButton label="English" onClick={() => handleLanguageChange('en')}>
									<img src={FlagUK} alt={ t('Flag of the United Kingdom') } />
								</ChapterButton>
							</div>
						</Col>
					</Row>
				</Container>
			</main>
		</div>
	);
};

export default Home;
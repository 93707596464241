import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../../i18n';
import { Trans } from "react-i18next";
import { Page, TransparentPage, SheetOfPaper } from "../../../components/Page/Page";
import Chapter from "../../../components/Chapter/Chapter";
import SourceHeader from "../../../components/SourceHeader/SourceHeader";

import routes from './c02_routes.js';
import ENTranscriptPDF from './Transcripts/en/en_c2_p12.pdf';
import NOTranscriptPDF from './Transcripts/no/no_c2_p12.pdf';
import DocumentIMG from './Media/c2_p12.jpg';

const C02P12 = (props) => {
	const { t } = useTranslation();
	const translation_namespace = 'c02_p12';

	return (
		<Chapter pages={routes} chapterName={props.chapterName} currentPageYear={props.year}>
			<Page className="C02P12">
				<TransparentPage>
					<p><img src={ DocumentIMG } alt={ t('Image of the original document transcribed next') } /></p>
					<ul className="download-links">
						<li><a href={ i18n.language === 'nb-NO' ? NOTranscriptPDF : ENTranscriptPDF } target="_blank"><Trans>Download</Trans> pdf <Trans>file</Trans></a></li>
						<li><a href={DocumentIMG} target="_blank"><Trans>Download</Trans> <Trans>image file</Trans></a></li>
					</ul>
				</TransparentPage>
				<SheetOfPaper>
					<SourceHeader name={ props.name } chapterName={ props.chapterName } />
					<p>
						<strong><Trans ns={ translation_namespace }>Minutes from a board meeting in the Jewish Community in Trondheim, dated January 9th, 1939. The Jewish Museum in Trondheim.</Trans></strong>
					</p>
					<h2><Trans>Transcript</Trans>:</h2>
					<div className="transcript">
						<p>
							(...)<br />
							<Trans ns={ translation_namespace }>5) Entry permit for cantor Grabowski. A letter from Trondheim Police Station dated December 28th, 1938 was given an account of, stating that the Central Passport Office has rejected the application for an entry and residence permit for Josef Grabowski.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>The chairman then gave a summary of talks with the immigration department in Trondheim regarding this matter. The immigration department recommended submitting a new application. This was done on December 29th, 1938.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Further, letters to and from cantor Leo Grabowski, Copenhagen, were given an account of. After speaking to the police station tonight, there may be a chance for him to obtain a residence permit if we guarantee for the man during his entire stay in Norway.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>The chairman thought that the board members should provide this guarantee, while Guttman was of the opinion that the congregation should provide it. Mr. H. Abrahamsen thinks we should apply again using the form provided for that purpose and then see which guarantees the Central Passport Office demands if a permit is granted. In the event of a guarantee from Trondheim, the chairman thinks we should demand a similar guarantee from Leo Grabowski and merchant Herz.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Trondheim January 9th, 1939. Heimar Abrahamsen</Trans>
						</p>
						<p>
							Isidor Isaksen, Abel L. Bernstein, M. Guttmann, A. Mendelsohn Oskar Mendelsohn.
						</p>
					</div>
				</SheetOfPaper>
			</Page>
		</Chapter>
	);
};

export default C02P12;
import React, { useEffect, useState } from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { Container, Col, Row, Button } from "reactstrap";
import PropTypes from 'prop-types';
import { isNotNullUndefinedOrEmpty, isNullUndefinedOrEmpty } from '../../utilities';
import './TestView.scss';

const TestView = (props) => {
	const { t } = useTranslation();

	useEffect(() => {
		const headerHeight = document.querySelector('.TestHeader').clientHeight;
		const footerHeight = document.querySelector('.TestFooter').clientHeight;
		document.documentElement.style.setProperty('--header-height', `${headerHeight}px`);
		document.documentElement.style.setProperty('--footer-height', `${footerHeight}px`);
	}, []);

	return (
		<div className="TestView">
			<header className="TestHeader">
				<h1><Trans>Header</Trans></h1>
			</header>
			<main className="TestChapter">
				<Container>
					<Row>
						<Col xs="12" md="10" className="TestPage">
							<div className="scrollable-content">
								<div id="lipsum">
									<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam tincidunt elit in libero blandit, non eleifend tellus mattis. Mauris at sodales lacus. Vestibulum eu pulvinar ex. Ut quis diam posuere, convallis massa sit amet, gravida sem. Aliquam aliquet ultricies augue, id euismod tellus rhoncus nec. Vivamus eget tellus convallis, fermentum diam nec, placerat nisi. Phasellus sit amet ipsum eget velit interdum congue. Nam sed arcu volutpat, ultricies odio eget, elementum dolor. Nunc laoreet, odio eu egestas suscipit, lectus justo rutrum velit, ac ultrices felis erat quis metus. Maecenas ut sem a dolor ornare mattis vel ut felis. Suspendisse porttitor ligula urna, in suscipit est ornare et. Etiam maximus hendrerit eros nec dapibus. Praesent turpis diam, fringilla non neque quis, sagittis placerat dui. Maecenas iaculis sagittis dolor, a semper eros tincidunt vitae. Mauris sit amet malesuada tellus. Fusce in dui porta, vestibulum ipsum eu, varius nisl.
									</p>
									<p>
									Curabitur sed venenatis dui. Pellentesque vitae elit aliquam, faucibus lacus eu, ultricies dui. Maecenas in urna a risus dapibus lobortis in ut mauris. Vivamus scelerisque nunc ac ante gravida vehicula. Donec viverra purus ac orci interdum, nec ultrices lacus venenatis. Phasellus ac condimentum nibh, quis fermentum nulla. Nunc venenatis urna et mauris consectetur posuere. Nunc dui nisi, pharetra sagittis consequat ut, sodales id justo.
									</p>
									<p>
									Nam vel purus malesuada, tristique mi vitae, consequat arcu. Proin consequat sit amet nulla id pellentesque. Interdum et malesuada fames ac ante ipsum primis in faucibus. Maecenas gravida sodales sollicitudin. Sed ut turpis ultricies, lobortis diam et, tempus neque. Aliquam in leo id metus efficitur vestibulum sit amet eget massa. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi nulla mauris, elementum nec dictum et, ultrices ut elit.
									</p>
									<p>
									Nullam commodo mi at enim varius, et iaculis arcu consequat. Phasellus congue sed nunc a convallis. Proin non purus quis nisl faucibus malesuada sed in quam. Donec quis ipsum est. Proin consectetur facilisis sodales. Nulla tincidunt nunc a sapien sagittis euismod. Vivamus feugiat lorem ac orci pretium scelerisque. Nunc quis leo aliquet, sollicitudin ante eu, finibus tellus. Proin tempus molestie ante eu accumsan. Mauris eget pretium sem. Curabitur magna dolor, lobortis in erat at, placerat vehicula nisl.
									</p>
									<p>
									Integer molestie neque molestie ligula commodo, quis venenatis elit varius. Curabitur sit amet dui magna. Phasellus aliquet aliquam varius. Vestibulum elementum pharetra magna eget tempor. Nam a sapien est. Etiam ultricies libero in porta vulputate. Cras eget vestibulum diam. Proin consequat, lorem vel ultrices tincidunt, sapien nisl sollicitudin elit, sit amet hendrerit metus felis ut nisi. Etiam vel augue mauris. Nulla sit amet metus orci. Fusce rhoncus pretium nisl, dapibus molestie nulla placerat volutpat. Morbi eget neque vitae turpis viverra luctus. Sed lorem metus, molestie vel velit vel, lacinia posuere lectus. Morbi consectetur nunc a quam sodales venenatis. Aenean maximus eu ligula ut cursus.
									</p>
									<p>
									Duis libero erat, mattis ac arcu sit amet, interdum eleifend ex. Maecenas tempus molestie lorem. Proin ac nunc iaculis, malesuada leo euismod, scelerisque leo. Sed vel justo non dolor blandit lobortis tristique accumsan diam. Integer eleifend lorem vel erat sagittis sodales. Fusce in tempus ante. Nullam orci magna, imperdiet vitae feugiat id, malesuada ac mi. Maecenas malesuada neque et facilisis consectetur. In imperdiet sapien risus, euismod vehicula purus interdum quis. Nam tristique, ex ut tempus suscipit, quam metus semper neque, sed ullamcorper urna orci ut sem. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Suspendisse id sem sit amet erat gravida ornare. Suspendisse sagittis lobortis scelerisque.
									</p>
									<p>
									Mauris pharetra interdum diam, sed venenatis eros lobortis ac. Nulla pretium nunc id gravida congue. In lacinia urna efficitur venenatis dapibus. Praesent ex eros, porta ut blandit in, consectetur at ante. Maecenas rutrum justo quis aliquet efficitur. Sed tincidunt mi nec accumsan suscipit. Sed cursus turpis eget nisl iaculis mollis.
									</p>
									<p>
									Donec eget nulla et felis scelerisque consectetur. Donec vitae orci eu magna auctor bibendum sit amet a magna. Nam scelerisque ut neque in iaculis. Nulla facilisi. Donec at lacus sodales, facilisis purus a, sagittis augue. Duis vestibulum pharetra quam at imperdiet. Fusce sit amet arcu ex. Etiam libero enim, sollicitudin vel lacus ac, volutpat dapibus urna. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus est erat, facilisis iaculis tempor at, accumsan id massa. Phasellus accumsan odio ac erat tristique, nec dapibus nulla euismod. Quisque placerat iaculis lorem, quis vulputate est egestas sit amet. Nam in ante purus.
									</p>
									<p>
									Etiam vel consequat orci. Sed ac varius arcu. Vivamus tempus leo vel tincidunt consequat. Praesent pulvinar mi ut sagittis pharetra. Suspendisse potenti. Aenean at malesuada justo. Fusce dapibus felis nisi, vitae elementum nisi egestas mollis. Morbi viverra ligula enim, sed sagittis turpis sodales et. Praesent a tristique libero, ut ornare ex. Quisque imperdiet lectus in lacus laoreet pulvinar. Duis tellus ipsum, tempor id convallis id, scelerisque eget lectus. Suspendisse tincidunt lorem et justo viverra, nec sagittis mauris sodales. Morbi ex odio, maximus vitae velit quis, suscipit facilisis metus.
									</p>
									<p>
									Vestibulum eget tempus turpis. Vestibulum at nisi sagittis, interdum nulla sed, posuere lorem. In placerat dictum sapien a dignissim. Suspendisse nec velit dignissim, dapibus neque et, feugiat magna. Mauris eu lobortis mauris, eu iaculis lectus. Donec lacus mi, porta sed neque eu, elementum mollis nulla. Aliquam sollicitudin nisl ac nunc mattis, in lacinia nisi pretium. In hac habitasse platea dictumst.
									</p>
									<p>
									Mauris augue ex, scelerisque vitae rutrum in, vulputate quis est. Phasellus blandit enim neque, et venenatis nibh tempus non. Duis nec turpis massa. Nunc venenatis, lorem non sodales placerat, felis magna dictum dui, a hendrerit nulla quam sit amet nisi. Vestibulum dapibus justo ut mollis sollicitudin. Etiam venenatis magna eu velit convallis tincidunt. Etiam tincidunt mi nec lacinia tincidunt. Pellentesque blandit ultrices lacus a molestie. Etiam leo augue, facilisis eget malesuada volutpat, dictum non magna. Aliquam tristique porttitor neque eget tempus. Mauris id sodales dolor, quis sagittis est. Nam dolor lectus, porta at convallis ac, feugiat id felis. Quisque lacinia tempus diam, in posuere sem suscipit ut.
									</p>
									<p>
									Vivamus gravida, urna non dictum tincidunt, leo felis lacinia lacus, ut scelerisque felis nisl ac neque. Mauris sodales mi sit amet urna feugiat facilisis. Praesent sagittis, nunc et aliquam scelerisque, ante ligula finibus ante, vitae vulputate quam tellus eu lectus. Praesent scelerisque justo malesuada augue hendrerit ullamcorper. Vestibulum eu tincidunt eros. Morbi rutrum nunc et iaculis vestibulum. Quisque accumsan cursus dolor, nec blandit eros finibus et. Etiam at quam nec lorem congue tincidunt eget ut ante. Pellentesque at vulputate ex. Sed nec blandit massa, sit amet pellentesque tortor.
									</p>
									<p>
									Praesent eget ex ut elit feugiat feugiat vitae quis dolor. Vestibulum laoreet lacus vitae ex scelerisque, vitae pretium orci imperdiet. Ut blandit pretium sem, quis molestie massa sodales ac. Donec vehicula libero sagittis, dignissim lectus sit amet, semper leo. Pellentesque orci magna, efficitur at ipsum at, convallis molestie arcu. Suspendisse posuere risus ac tincidunt consectetur. Nam velit orci, egestas vitae neque aliquet, tincidunt congue diam. In a tempus sapien, vitae tempus dolor. Mauris nec sagittis mi. Nam at condimentum nisi. Proin suscipit, nisi quis maximus convallis, risus mi dignissim nunc, nec porta sem ante ac felis.
									</p>
									<p>
									Praesent eget nisi eu purus bibendum porta vitae ultricies purus. Sed a est volutpat nibh vulputate facilisis non eu velit. Nam finibus metus vitae porttitor condimentum. Nulla dictum, eros nec lacinia gravida, velit leo vehicula felis, quis dictum odio ante at elit. Ut feugiat velit at mi laoreet, nec consectetur nisl ullamcorper. Vestibulum cursus turpis ut libero eleifend suscipit. Cras in ultrices orci, eget scelerisque enim. Etiam efficitur enim non accumsan molestie. Donec vitae semper libero. Praesent quis orci at nunc consectetur eleifend ac eget eros. Vestibulum bibendum tellus in erat ultricies rutrum.
									</p>
									<p>
									Ut malesuada, tellus vitae pulvinar sodales, dui risus dignissim sem, vel hendrerit justo eros eget nibh. Vivamus semper rutrum felis, et mattis diam sodales eget. Sed sed lectus aliquet, rhoncus odio sit amet, tempus lorem. Vivamus sed orci ac turpis dapibus condimentum. Integer dapibus, massa eu consectetur pellentesque, ex nisl ultricies diam, in tristique tortor leo gravida sapien. Praesent aliquam auctor dolor, id vulputate est vestibulum ut. Cras porta, odio ac varius mattis, nisl dui ultrices elit, non vulputate arcu quam euismod nisi. Praesent imperdiet, lacus in pulvinar fermentum, tellus lacus molestie ante, nec condimentum nulla ipsum vitae dui. Integer vel luctus tortor. In sodales odio vitae elit varius fermentum. Etiam sit amet varius augue. Maecenas sit amet congue purus. Donec vel urna pharetra, tristique velit eget, eleifend odio. Quisque tristique neque at convallis pretium. Suspendisse in semper libero.
									</p>
									<p>
									Quisque dolor purus, euismod non tempor ac, facilisis ac libero. Praesent semper fermentum enim, eu tincidunt dolor iaculis id. Pellentesque mollis sit amet nisl elementum ullamcorper. Ut imperdiet cursus tortor semper gravida. Cras eu porttitor mauris. Nam consequat rutrum accumsan. Donec elementum ex vitae lobortis tincidunt. Donec vitae elit vel arcu pharetra mattis. Pellentesque tincidunt libero diam, suscipit rutrum sem maximus at. Ut nec libero ac risus dignissim iaculis eget id metus. Nunc ut eros non massa ornare iaculis. Donec vel mollis ipsum.
									</p>
									<p>
									Cras vel nulla et diam bibendum aliquam eget id arcu. Morbi sodales sollicitudin arcu, a venenatis dui volutpat ac. Etiam ac tristique ante. Phasellus et ipsum posuere, molestie diam nec, vehicula nunc. Praesent feugiat sem non est facilisis, in pellentesque elit dictum. Nulla consequat neque nulla, sed ultricies ante viverra vel. Sed vestibulum et ex efficitur varius.
									</p>
									<p>
									Quisque vel leo porttitor, blandit metus cursus, volutpat turpis. Mauris quis dui sed turpis malesuada dictum. Suspendisse potenti. Vestibulum gravida nisi sed sem luctus imperdiet. Sed felis odio, imperdiet in est vel, vulputate vehicula risus. Quisque lobortis metus tellus, sit amet dictum arcu ullamcorper bibendum. Nulla feugiat justo sed sodales fermentum.
									</p>
									<p>
									Morbi dictum nisi non consectetur rutrum. Sed aliquet sapien eu ex condimentum, non fringilla tortor congue. Proin varius mattis mauris a egestas. Nunc hendrerit ante vel faucibus cursus. In vulputate purus et ex congue, nec vulputate ipsum sollicitudin. Fusce sed sodales elit, et iaculis urna. Vestibulum a libero eget augue tincidunt consequat.
									</p>
									<p>
									Nullam vel dignissim neque, quis pharetra nisi. Maecenas feugiat, sapien sit amet luctus varius, velit mauris rhoncus nunc, vitae volutpat tortor urna sollicitudin lacus. In eu consequat erat. Sed urna arcu, eleifend a pharetra sed, porta vitae quam. Nullam pellentesque dolor quis tellus condimentum iaculis. Maecenas posuere enim et lacus finibus, at lacinia nunc iaculis. Duis ac nunc id urna tincidunt lobortis a eget metus. Sed vulputate venenatis orci. Vivamus vitae accumsan tellus. Ut finibus odio sem, quis scelerisque magna sollicitudin et. Duis consectetur ultrices ultrices.
									</p>
								</div>
							</div>
						</Col>
						<Col xs="12" md="2" className="TestPageList">
							<div className="scrollable-content">
								<ul>
									<li><a href="#">Test</a></li>
									<li><a href="#">Test</a></li>
									<li><a href="#">Test</a></li>
									<li><a href="#">Test</a></li>
								</ul>
							</div>
						</Col>
					</Row>
				</Container>
			</main>
			<footer className="TestFooter">
				<h2>Footer</h2>
			</footer>
		</div>
	);
};

TestView.defaultProps = {
}

TestView.propTypes = {
}

export default TestView;
import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../../i18n';
import { Trans } from "react-i18next";
import { Page, TransparentPage, SheetOfPaper } from "../../../components/Page/Page";
import Chapter from "../../../components/Chapter/Chapter";
import SourceHeader from "../../../components/SourceHeader/SourceHeader";

import routes from './c07_routes.js';
import ENTranscriptPDF from './Transcripts/en/en_c7_p2.pdf';
import NOTranscriptPDF from './Transcripts/no/no_c7_p2.pdf';

const C07P02 = (props) => {
	const { t } = useTranslation();
	const translation_namespace = 'c07_p02';

	return (
		<Chapter pages={routes} chapterName={props.chapterName} currentPageYear={props.year}>
			<Page className="C07P02">
				<TransparentPage>
					<ul className="download-links">
						<li><a href={ i18n.language === 'nb-NO' ? NOTranscriptPDF : ENTranscriptPDF } target="_blank"><Trans>Download</Trans> pdf <Trans>file</Trans></a></li>
					</ul>
				</TransparentPage>
				<SheetOfPaper>
					<SourceHeader name={ props.name } chapterName={ props.chapterName } />
					<p>
						<strong><Trans ns={ translation_namespace }>E-mail from the Office for Information on Former Prisoners at the Auschwitz Memorial</Trans></strong>
					</p>
					<h2><Trans>Transcript</Trans>:</h2>
					<div className="transcript">
						<p>
							In Beantwortung Ihrer Anfrage teilt das Staatliche Museum Auschwitz- Birkenau in Oswiecim mit, dass in der nur teilweise erhaltenen KL- Dokumenten folgende Informationen gefunden wurden:
						</p>
						<p>
							GRABOWSKI Josef geb. 11.5.1911 r. Posen, wohn. Drontheim, Museumsplass 3, sein Name ist nur auf die Transportliste vom 2.3.1943, 32. Osttransport verzeichnet. Über sein weiteres Schicksal haben wir keine Informationen.
						</p>
						<p>
							mit freundlichen Grüßen
						</p>
						<p>
							Büro für die Informationen über ehemalige Häftlinge
						</p>
					</div>
					<h2><Trans>Translation</Trans>:</h2>
					<div className="transcript">
						<p>
							<Trans ns={ translation_namespace }>As a reply to your question, the State Museum Auschwitz-Birkenau in Oswiecim informs that we have found the following information in the partly preserved camp documents:</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Grabowski, Josef born May 11th, 1911 in Posen, place of residence Trondheim, Museumsplass 3. His name is only listed in the transport list from March 2nd, 1943, Osttransport no. 32. We have no information about his further destiny.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Sincerely yours,</Trans><br />
							<Trans ns={ translation_namespace }>The Office for Information on Former Prisoners</Trans>
						</p>
					</div>
				</SheetOfPaper>
			</Page>
		</Chapter>
	);
};

export default C07P02;
import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../../i18n';
import { Trans } from "react-i18next";
import { Page, TransparentPage, SheetOfPaper } from "../../../components/Page/Page";
import Chapter from "../../../components/Chapter/Chapter";
import SourceHeader from "../../../components/SourceHeader/SourceHeader";

import routes from './c02_routes.js';
import ENTranscriptPDF from './Transcripts/en/en_c2_p10.pdf';
import NOTranscriptPDF from './Transcripts/no/no_c2_p10.pdf';
import DocumentIMG from './Media/c2_p10.jpg';

const C02P10 = (props) => {
	const { t } = useTranslation();
	const translation_namespace = 'c02_p10';

	return (
		<Chapter pages={routes} chapterName={props.chapterName} currentPageYear={props.year}>
			<Page className="C02P10">
				<TransparentPage>
					<p><img src={ DocumentIMG } alt={ t('Image of the original document transcribed next') } /></p>
					<ul className="download-links">
						<li><a href={ i18n.language === 'nb-NO' ? NOTranscriptPDF : ENTranscriptPDF } target="_blank"><Trans>Download</Trans> pdf <Trans>file</Trans></a></li>
						<li><a href={DocumentIMG} target="_blank"><Trans>Download</Trans> <Trans>image file</Trans></a></li>
					</ul>
				</TransparentPage>
				<SheetOfPaper>
					<SourceHeader name={ props.name } chapterName={ props.chapterName } />
					<p>
						<strong><Trans ns={ translation_namespace }>Extract of the minutes from an extraordinary congregational meeting in the Jewish Community, dated August 27th, 1939. The Jewish Museum in Trondheim.</Trans></strong>
					</p>
					<h2><Trans>Transcript</Trans>:</h2>
					<div className="transcript">
						<p>
							<Trans ns={ translation_namespace }>An extraordinary congregational meeting was arranged in the congregation’s premises on Sunday Aug. 27th, 1939 at 8 pm. The following matter was on the agenda:</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>The employment of cantor Grabowski by the congregation.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>The Chairman presented the issue. Cantor Grabowski had asked the congregation to arrange for his wife to come here and obtain a residence permit, as it was very difficult for his wife to remain in Germany. In that case, the congregation had to give the Central Passport Office a guarantee that his wife would not be a burden to the public. It would also be necessary to promise cantor Grabowski minimum NOK 200 per month from the congregation. (...)</Trans>
						</p>
					</div>
				</SheetOfPaper>
			</Page>
		</Chapter>
	);
};

export default C02P10;
import React from 'react';
import { i18n } from "../../../i18n.js";

import C01P01 from './C01P01';
import C01P02 from './C01P02';
import C01P03 from './C01P03';
import C01P04 from './C01P04';
import C01P05 from './C01P05';
import C01P06 from './C01P06';
import C01P07 from './C01P07';

const chapterName = "The Falstad Centres archive";

const c01_routes = [
	{
		path: '/chapter/1/page/1',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 1',
		name: 'Source 1',
		element: <C01P01 chapterName={chapterName} name="Source 1" language="en" year="" />
	},
	{
		path: '/chapter/1/page/2',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 2',
		name: 'Source 2',
		element: <C01P02 chapterName={chapterName} name="Source 2" language="en" year="" />
	},
	{
		path: '/chapter/1/page/3',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 3',
		name: 'Source 3',
		element: <C01P03 chapterName={chapterName} name="Source 3" language="en" year="" />
	},
	{
		path: '/chapter/1/page/4',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 4',
		name: 'Source 4',
		element: <C01P04 chapterName={chapterName} name="Source 4" language="en" year="1945" />
	},
	{
		path: '/chapter/1/page/5',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 5',
		name: 'Source 5',
		element: <C01P05 chapterName={chapterName} name="Source 5" language="en" year="" />
	},
	{
		path: '/chapter/1/page/6',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 6',
		name: 'Source 6',
		element: <C01P06 chapterName={chapterName} name="Source 6" language="en" year="1942" />
	},
	{
		path: '/chapter/1/page/7',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 7',
		name: 'Source 7',
		element: <C01P07 chapterName={chapterName} name="Source 7" language="en" year="1942" />
	},
	{
		path: '/chapter/1/',
		title: 'Fange nr: 424 - Josef Grabowski - ' + chapterName +' - Source 1',
		name: 'Source 1',
		ignoreInPagelist: true,
		element: <C01P01 chapterName={chapterName} name="Source 1" language="en" year="" />
	},
];

export default c01_routes;
import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../../i18n';
import { Trans } from "react-i18next";
import { Page, TransparentPage, SheetOfPaper } from "../../../components/Page/Page";
import Chapter from "../../../components/Chapter/Chapter";
import SourceHeader from "../../../components/SourceHeader/SourceHeader";

import routes from './c05_routes.js';
import ENTranscriptPDF from './Transcripts/en/en_c5_p7.pdf';
import NOTranscriptPDF from './Transcripts/no/no_c5_p7.pdf';
import DocumentIMG from './Media/c5_p7.jpg';

const C05P07 = (props) => {
	const { t } = useTranslation();
	const translation_namespace = 'c05_p07';

	return (
		<Chapter pages={routes} chapterName={props.chapterName} currentPageYear={props.year}>
			<Page className="C05P07">
				<TransparentPage>
					<p><img src={ DocumentIMG } alt={ t('Image of the original document transcribed next') } /></p>
					<ul className="download-links">
						<li><a href={ i18n.language === 'nb-NO' ? NOTranscriptPDF : ENTranscriptPDF } target="_blank"><Trans>Download</Trans> pdf <Trans>file</Trans></a></li>
						<li><a href={DocumentIMG} target="_blank"><Trans>Download</Trans> <Trans>image file</Trans></a></li>
					</ul>
				</TransparentPage>
				<SheetOfPaper>
					<SourceHeader name={ props.name } chapterName={ props.chapterName } />
					<p>
						<strong><Trans ns={ translation_namespace }>Letter from the Jewish Community in Trondheim to the Central Passport Office January 31st, 1939. Journal no. 1941/254 in the Central Passport Office’s archives. The National Archives in Oslo.</Trans></strong>
					</p>
					<h2><Trans>Transcript</Trans>:</h2>
					<div className="transcript">
						<p>
							<Trans ns={ translation_namespace }>The Jewish Community</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Trondheim January 31st, 1939</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>To the Central Passport Office, Oslo.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Based on communication from the Immigration Department at Trondheim Police Station to the Jewish Community, requesting a guarantee from the Community that cantor Josef Grabowski will not be a burden to the public during his stay in Norway, the Jewish Community in Trondheim hereby declares to Norwegian authorities that the Community guarantees that cantor Josef Grabowski will not be a burden to the public during his stay in Norway.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Yours faithfully</Trans><br />
							<Trans ns={ translation_namespace }>For the Jewish Community</Trans><br />
							Isidor Isaksen<br />
							<Trans ns={ translation_namespace }>Chairman.</Trans>
						</p>
						<p>
							Abel L. Bernstein. M. Guttman. A. Mendelsohn<br />
							<Trans ns={ translation_namespace }>Members of the Board for the Jewish Community</Trans>
						</p>
						<p>
							Oskar Mendelsohn.
						</p>
					</div>
				</SheetOfPaper>
			</Page>
		</Chapter>
	);
};

export default C05P07;
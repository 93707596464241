import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../../i18n';
import { Trans } from "react-i18next";
import { Page, TransparentPage, SheetOfPaper } from "../../../components/Page/Page";
import Chapter from "../../../components/Chapter/Chapter";
import SourceHeader from "../../../components/SourceHeader/SourceHeader";

import routes from './c05_routes.js';
import ENTranscriptPDF from './Transcripts/en/en_c5_p16.pdf';
import NOTranscriptPDF from './Transcripts/no/no_c5_p16.pdf';
import DocumentIMG from './Media/c5_p16.jpg';

const C05P16 = (props) => {
	const { t } = useTranslation();
	const translation_namespace = 'c05_p16';

	return (
		<Chapter pages={routes} chapterName={props.chapterName} currentPageYear={props.year}>
			<Page className="C05P16">
				<TransparentPage>
					<p><img src={ DocumentIMG } alt={ t('Image of the original document transcribed next') } /></p>
					<ul className="download-links">
						<li><a href={ i18n.language === 'nb-NO' ? NOTranscriptPDF : ENTranscriptPDF } target="_blank"><Trans>Download</Trans> pdf <Trans>file</Trans></a></li>
						<li><a href={DocumentIMG} target="_blank"><Trans>Download</Trans> <Trans>image file</Trans></a></li>
					</ul>
				</TransparentPage>
				<SheetOfPaper>
					<SourceHeader name={ props.name } chapterName={ props.chapterName } />
					<p>
						<strong><Trans ns={ translation_namespace }>Letter from the Central Passport Office to the Chief Constable in Trondheim January 25th, 1941. Journal no. 1941/254 in the Central Passport Office’s archives. The National Archives in Oslo.</Trans></strong>
					</p>
					<h2><Trans>Transcript</Trans>:</h2>
					<div className="transcript">
						<p>
							<Trans ns={ translation_namespace }>THE CENTRAL PASSPORT OFFICE</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>J.no. 254-41.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Mr. Chief Constable in Trondheim.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>The Central Passport Office has received directly from the Jewish Community an application for continued residence and work permit for the German citizen cantor Josef Grabowski.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>For the sake of order, we inform – on referral to the correspondence from the Ministry of Police of December 16th, l.y. [= last year] comp. correspondence from us of the 18th t.m. [= this month] – that the matter will not be dealt with.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>Oslo, on January 25th, 1941.</Trans>
						</p>
					</div>
				</SheetOfPaper>
			</Page>
		</Chapter>
	);
};

export default C05P16;
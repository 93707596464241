import React, { useEffect, useState } from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { Col, Row, Button, Container } from "reactstrap";
import { Page, TransparentPage, SheetOfPaper } from "../Page/Page";
import Pagelist from "../Pagelist/Pagelist";
import Header from '../Header/Header';
import LanguageSwitch from '../LanguageSwitch/LanguageSwitch';
import Footer from '../Footer/Footer';
import PropTypes from 'prop-types';
import { isNotNullUndefinedOrEmpty, isNullUndefinedOrEmpty } from '../../utilities';
import './chapter.scss';

const Chapter = (props) => {
	const { t } = useTranslation();

	const [pages, setPages] = useState([]);

	useEffect(() => {
		setPages(props.pages.filter((page) => {
			console.log(page.name, page.ignoreInPagelist);
			return !page.ignoreInPagelist;
		}).map((page) => {
			return {
				name: page.name,
				path: page.path,
				chapterName: page.chapterName,
			}
		}))
	}, [props.pages]);

	useEffect(() => {
		console.log('props.currentPageYear', props.currentPageYear);
	}, [props.currentPageYear]);

	return (
		<div className="Chapter">
			<Header />
			<main className="ChapterContent">
				<Container>
					<Row>
						<Col className="Pages" xs="12" md="10">
							<div className="scrollable-content">
							{ props.children }
							</div>
						</Col>
						<Col className="Sidebar" xs="12" md="2">
							<div className="scrollable-content">
								<Link to="/chapters" className="backlink"><Trans>Back</Trans> &#9447;</Link>
								<Pagelist pages={pages} />
								<LanguageSwitch />
							</div>
						</Col>
					</Row>
				</Container>
			</main>
			<Footer year={props.currentPageYear} />
		</div>
	);
};

Chapter.defaultProps = {
	pages: [],
}

Chapter.propTypes = {
	pages: PropTypes.arrayOf(PropTypes.object),
}

export default Chapter;
import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../../i18n';
import { Trans } from "react-i18next";
import { Page, TransparentPage, SheetOfPaper } from "../../../components/Page/Page";
import Chapter from "../../../components/Chapter/Chapter";
import SourceHeader from "../../../components/SourceHeader/SourceHeader";

import routes from './c02_routes.js';
import ENTranscriptPDF from './Transcripts/en/en_c2_p6.pdf';
import NOTranscriptPDF from './Transcripts/no/no_c2_p6.pdf';
import DocumentIMG from './Media/c2_p6.jpg';

const C02P06 = (props) => {
	const { t } = useTranslation();
	const translation_namespace = 'c02_p06';

	return (
		<Chapter pages={routes} chapterName={props.chapterName} currentPageYear={props.year}>
			<Page className="C02P06">
				<TransparentPage>
					<img src={ DocumentIMG } alt={ t('Image of the original document transcribed next') } />
					<ul className="download-links">
						<li><a href={ i18n.language === 'nb-NO' ? NOTranscriptPDF : ENTranscriptPDF } target="_blank"><Trans>Download</Trans> pdf <Trans>file</Trans></a></li>
						<li><a href={DocumentIMG} target="_blank"><Trans>Download</Trans> <Trans>image file</Trans></a></li>
					</ul>
				</TransparentPage>
				<SheetOfPaper>
					<SourceHeader name={ props.name } chapterName={ props.chapterName } />
					<p>
						<strong><Trans ns={ translation_namespace }>Letter to the Jewish Aid Society from the Aid Society’s committee in Trondheim, dated August 25th, 1941. The Jewish Museum in Trondheim.</Trans></strong>
					</p>
					<h2><Trans>Transcript</Trans>:</h2>
					<div className="transcript">
						<p>
							<Trans>The Jewish Aid Society</Trans><br />
							<Trans ns={ translation_namespace }>Trondheim August 25th, 1941</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>The cooperating Jewish aid organisations can look back at 1 year’s work under a common financial administration. In these times which have been so hard for us all, it has been an encouragement that the appeal we directed last year to all Jews to show solidarity and discipline has been followed in a body. With a very few exceptions, the fixed annual contribution has been paid in time.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>With the means at our disposal, the head organisation in Oslo and the committee here in Trondheim have worked according to their ability to keep the Jewish social functions up and running and otherwise prevent distress and needs. However, it became clear that after putting aside the necessary means for the local aid up here, the money unfortunately did not suffice for the head organisation, not for helping everyone in need nor to give the persons supported fairly human conditions. With the increased expenses and the rising cost of living, a considerable increase in contributions is absolutely necessary if the organisations are to make it through the coming year.</Trans>
						</p>
						<p>
							<Trans ns={ translation_namespace }>In the near future, a representative of the local committee accompanied by a representative of the head organisation in Oslo will pay you a visit to decide upon your contribution for the period Sept. 1st this year to August 31st, 1942. This contribution is the only demand we make upon your effort. By making this contribution, you will show the solidarity we require from you as a Jew in times like these. And not least: You voluntarily take part in giving the aid organisations the opportunity to fulfil the obligations they have assumed towards society. The subscription for contributions for the coming year is drawing to a close in Oslo, where the contributors have found it necessary to agree to the required rise in contributions to ensure the work of the organisations. We are confident that the contributors in Trondheim will show understanding as before. We hope that you too will be imbued with the spirit filling several of our contributors when they uttered the following when paying the contributions in the past year: We should be glad that we are able to help our unfortunate fellow beings.</Trans>
						</p>
						<p>
							<Trans>The Jewish Aid Society</Trans><br />
							<Trans>The Trondheim Committee</Trans>
						</p>
						<p>
							Oskar Mendelsohn. Mille Klein. Lea Levin. Joseph Grabowski
						</p>
					</div>
				</SheetOfPaper>
			</Page>
		</Chapter>
	);
};

export default C02P06;
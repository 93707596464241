import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../i18n';
import { isNotNullUndefinedOrEmpty, isNullUndefinedOrEmpty } from '../../utilities';

import './header.scss';
import PortraitJosefGrabowski from '../../images/portrait_josef_grabowski.jpg';

const Header = (props) => {
	const { t } = useTranslation();

	return (
		<header className="Header">
			<h1>{ t('Prisoner no: 424 - Josef Grabowski') }</h1>
			{ isNotNullUndefinedOrEmpty(props.subheader) && (
				<h2>{ props.subheader }</h2>
			) }
			<img id="portrait-josef-grabowski" src={ PortraitJosefGrabowski } alt="Portrait of Josef Grabowski" height="100" />
		</header>
	);
};

Header.defaultProps = {
	subheader: '',
}

Header.propTypes = {
	subheader: PropTypes.string,
}

export default Header;